import { CSSProperties } from 'react';

export const CreateUserFormStyles = {
	container: {
		position: 'relative',
		borderRadius: '6px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: 20,
		gap: 30,
	} as CSSProperties,

	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	} as CSSProperties,

	button: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
	} as CSSProperties,
} as const;