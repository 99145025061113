import React, { Component, ReactElement } from 'react';
import { ClientDetailsProps, ClientDetailsState } from './ClientDetails.interface';
import { ClientDetailsService } from './ClientDetails.service';
import { dateFormat } from '@/Utils/DateUtils';
import { ClientDetailsStyles } from '@/Modules/Client/Components/Blocks/ClientDetails/ClientDetails.styles';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Editable from '@/Modules/App/Components/Library/Editable/Editable';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import { handleChangeInput } from '@/Utils/InputFormUtils';

class ClientDetails extends Component<ClientDetailsProps, ClientDetailsState>
{
	private clientDetailsService = new ClientDetailsService();

	constructor(props: ClientDetailsProps)
	{
		super(props);

		// Config service
		this.clientDetailsService.setProps(this.props);
		this.clientDetailsService.subscribeState(this);

		// State
		this.state = this.clientDetailsService.getState();
	}

	async componentDidMount(): Promise<void>
	{
		await this.clientDetailsService.init();
	}

	componentWillUnmount(): void
	{
		this.clientDetailsService.unsubscribe();
	}

	render(): ReactElement
	{
		const { formData, isLoading } = this.state;

		if (isLoading) {
			return <LoaderComponent />;
		}

		const onChange = (event: any) => handleChangeInput(event, this.setState.bind(this));

		return (
			<Editable onEdit={() => this.clientDetailsService.handleEdit(this.state)}>
				{({ isEditable }) => (
					<div style={ ClientDetailsStyles.container }>
						<div style={ ClientDetailsStyles.column }>
							<div style={ ClientDetailsStyles.title }>Détails</div>
							<LineDetailsRender info={'Donneur d\'ordre depuis'} formData={dateFormat(formData.createdAt)} isEditable={ isEditable } />
							<LineDetailsRender info={'Nom'} formData={ formData.name } inputEdit={{ type: 'text', name: 'name', onChange }} isEditable={ isEditable } />
							<LineDetailsRender info={'Siren'} formData={ formData.siren } isEditable={ isEditable } />
							<LineDetailsRender info={'Ape Code'} formData={ formData.apeCode } inputEdit={{ type: 'text', name: 'apeCode', onChange }} isEditable={ isEditable } />
							<LineDetailsRender info={'Statut'} formData={ formData.status.label } isEditable={ isEditable } />
						</div>

						<div style={ ClientDetailsStyles.column }>
							<div style={ ClientDetailsStyles.title }>Adresse</div>
							<LineDetailsRender info={'Numéro'} formData={ formData?.address?.number! } inputEdit={{ type: 'number', name: 'address.number', allowedCharacters: 'digits',  onChange }} isEditable={ isEditable } />
							<LineDetailsRender info={'Rue'} formData={ formData?.address?.street! } inputEdit={{ type: 'text', name: 'address.street',  onChange }} isEditable={ isEditable } />
							<LineDetailsRender info={'Complément'} formData={ formData?.address?.additionalData! } inputEdit={{ type: 'text', name: 'address.additionalData', onChange }} isEditable={ isEditable } />
							<LineDetailsRender info={'Code Postal'} formData={ formData?.address?.zipCode! } inputEdit={{ type: 'text', name: 'address.zipCode', onChange }} isEditable={ isEditable } />
							<LineDetailsRender info={'Ville'} formData={ formData?.address?.city! } inputEdit={{ type: 'text', name: 'address.city', onChange }} isEditable={ isEditable } />
							<LineDetailsRender info={'Pays'} formData={ formData?.address?.country! } inputEdit={{ type: 'text', name: 'address.country', onChange }} isEditable={ isEditable } />
						</div>
					</div>
				)}
			</Editable>
		);
	}
}

export default ClientDetails;
