import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { UserAdminProps, UserAdminState } from '@/Modules/User/Components/Show/UserAdmin.interface';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';

const initState: UserAdminState = {
  user: {} as UserInterface
};

export default class UserAdminService extends BaseComponentService<UserAdminProps, UserAdminState>
{
  constructor()
  {
    super({} as UserAdminProps, initState);
  }

  init(): void
  {
    this.setState({ user: this.props.user });
  }
}