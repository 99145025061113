import { CSSProperties } from 'react';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const ContentStyles = {
	wrapper: {
		position: 'relative',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		width: 'calc(100% - 240px)',
		maxWidth: 1640,
		height: '100%',
		margin: 'auto',
		backgroundColor: themeColors.background,
		overflow: 'hidden'
	} as CSSProperties,

	container :{
		position: 'relative',
		zIndex: 2,
		width: '100%',
		top: 0,
		height: '100%',
		margin: 'auto',
		padding: '0 90px',
		overflow: 'scroll',
		paddingBottom: 40
	} as CSSProperties,

	sidebar: {
		display: 'grid',
		gridTemplateColumns: 'auto 350px',
		columnGap: 40,
		height: 'calc(100% - 100px)',
		overflow: 'scroll',
		paddingTop: 20,
	} as CSSProperties,

	sidebar_block: {
		display: 'flex',
		flexDirection: 'column',
		gap: 70
	} as CSSProperties,

	sidebar_block_title: {
		...FontStyles.h3,
		fontWeight: 700,
		paddingBottom: 10,
		marginBottom: 15,
		borderBottom: borderStyles.border,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	} as CSSProperties,

	sidebar_block_content: {
		padding: '5px 0px',
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	} as CSSProperties,

	sidebar_block_content_title: {
		fontWeight: 700,
	} as CSSProperties,

	sidebar_block_content_element: (isClickable: boolean) => ({
	...(isClickable) ? ({
		textDecoration: 'underline',
		textDecorationStyle: 'dashed'
	}) : ({})
	}) as CSSProperties,


} as const;