import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SearchProps, SearchState } from './Search.interface';
import { handleChangeInput } from '@/Utils/InputFormUtils';

const initState: SearchState = {
	isLoading: false,
	formData: {
		searchTerm: '',
		results: null,
	}
};

export class SearchService extends BaseComponentService<SearchProps, SearchState>
{
	private debounceTimeout: NodeJS.Timeout | null = null;

	constructor()
	{
		super({} as SearchProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	willUnMountInit(): void
	{
		this.unsubscribe();
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
	}

	/**
	 * Handle Input Change with debounce
	 * @param event
	 */
	handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
	{
		handleChangeInput(event, (newState: any) =>
		{
			this.setState(newState, () =>
			{
				if (event.target.name === 'searchTerm' && event.target.value.length > 0) {
					if (this.debounceTimeout) {
						clearTimeout(this.debounceTimeout);
					}
					this.debounceTimeout = setTimeout(() =>
					{
						this.search(event.target.value);
					}, 500);
				}
			});
		});
	};

	/**
	 *
	 * @param searchTerm
	 * @param role
	 */
	async search(searchTerm: string): Promise<void>
	{
		this.setState({ isLoading: true });
		try {
			const results = await this.props.searchService(searchTerm, this.props.roleFilterList?.value);
			this.setState((prevState: SearchState) => ({
				formData: {
					...prevState.formData,
					results,
				},
				isLoading: false
			}));
		} catch (error) {
			console.error('Error during search:', error);
			this.setState({ isLoading: false });
		}
	}

}