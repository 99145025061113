import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { TableControlsProps, TableControlsState } from './TableControls.interface';

const initState: TableControlsState = {
	selectedFilters: {} as Record<string, { value: string; displayValue: string } | null>
};

export class TableControlsService extends BaseComponentService<TableControlsProps, TableControlsState>
{
	private debounceTimeoutId: NodeJS.Timeout | null = null;
	constructor()
	{
		super({} as TableControlsProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{}

	async onChangeTerm(term: string): Promise<void>
	{
		// Clear the previous timeout if the user keeps typing
		if (this.debounceTimeoutId) {
			clearTimeout(this.debounceTimeoutId);
		}

		this.debounceTimeoutId = setTimeout(async () => {
			this.props.onChangeTerm(term);
		}, 300);
	}

	async filterSearch(filterName: string, term: string): Promise<void>
	{
		// Set state
		this.setState((prevState) => ({
			selectedFilters: {
				...prevState.selectedFilters,
				[filterName]: { value: term, displayValue: term },
			},
		}));

		this.props.onApplyFilter(filterName, term);
	}

	onSelectFilterOption(filterName: string, item: any): void
	{
		// Set state
		this.setState((prevState) => ({
			selectedFilters: {
				...prevState.selectedFilters,
				[filterName]: { value: item.tag, displayValue: item.label },
			},
		}));

		this.props.onApplyFilter(filterName, item.tag);
	}

	onClearFilters(): void
	{
		this.setState({ selectedFilters: {} }, () => {
			this.props.onClearFilters();
		});
	}
}