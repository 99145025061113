import { ReactElement } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';

export class ClientTypeEnum
{
	value: string;
	label: string;
	css: {
		backgroundColor: string,
		color: string,
		border?: string,
		icon?: ReactElement | null,
	};

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, border?: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static ORDER_GIVER = new ClientTypeEnum(
		'ORDER_GIVER',
		'Donneur d\'ordre',
		{
			backgroundColor: colors.orange50,
			color: colors.orange500,
			border: `1px solid ${colors.orange200}`,
		}
	);
	static JUDICIAL_FORUM = new ClientTypeEnum(
		'JUDICIAL_FORUM',
		'Tribunal judiciaire',
		{
			backgroundColor: colors.blueRibbon50,
			color: colors.blueRibbon700,
			border: `1px solid ${colors.blueRibbon200}`,
		}
	);
	static COMMERCIAL_COURT_REGISTRY = new ClientTypeEnum(
		'COMMERCIAL_COURT_REGISTRY',
		'Greffe',
		{
			backgroundColor: colors.gray50,
			color: colors.gray700,
			border: `1px solid ${colors.gray200}`,
		}
	);
	static COLLEAGUE = new ClientTypeEnum(
		'COLLEAGUE',
		'Confrère',
		{
			backgroundColor: colors.success50,
			color: colors.success700,
			border: `1px solid ${colors.success200}`,
		}
	);
	static LAWYER = new ClientTypeEnum(
		'LAWYER',
		'Avocat',
		{
			backgroundColor: colors.purple50,
			color: colors.purple500,
			border: `1px solid ${colors.purple200}`,
		}
	);
	static CERTIFIED_ACCOUNTANT = new ClientTypeEnum(
		'CERTIFIED_ACCOUNTANT',
		'Expert Comptable',
		{
			backgroundColor: colors.blueRibbon50,
			color: colors.blueRibbon500,
			border: `1px solid ${colors.blueRibbon200}`,
		}
	);
	static NOTARY = new ClientTypeEnum(
		'NOTARY',
		'Notaire',
		{
			backgroundColor: colors.gray100,
			color: colors.gray600,
			border: `1px solid ${colors.gray300}`,
		}
	);
	static MULTIDISCIPLINARY = new ClientTypeEnum(
		'MULTIDISCIPLINARY',
		'Pluridisciplinaire',
		{
			backgroundColor: colors.orange100,
			color: colors.orange600,
			border: `1px solid ${colors.orange300}`,
		}
	);
	static CITY_HALL = new ClientTypeEnum(
		'CITY_HALL',
		'Mairie',
		{
			backgroundColor: colors.success100,
			color: colors.success600,
			border: `1px solid ${colors.success300}`,
		}
	);
	static PRIVATE_COMPANY = new ClientTypeEnum(
		'PRIVATE_COMPANY',
		'Entreprise',
		{
			backgroundColor: colors.pink50,
			color: colors.pink500,
			border: `1px solid ${colors.pink200}`,
		}
	);

	static options: ClientTypeEnum[] = [
		ClientTypeEnum.ORDER_GIVER,
		ClientTypeEnum.JUDICIAL_FORUM,
		ClientTypeEnum.COMMERCIAL_COURT_REGISTRY,
		ClientTypeEnum.COLLEAGUE,
		ClientTypeEnum.LAWYER,
		ClientTypeEnum.CERTIFIED_ACCOUNTANT,
		ClientTypeEnum.NOTARY,
		ClientTypeEnum.MULTIDISCIPLINARY,
		ClientTypeEnum.CITY_HALL,
		ClientTypeEnum.PRIVATE_COMPANY,
	];

	static optionsFilter: OptionFilterInterface[] = [
		{ label: ClientTypeEnum.ORDER_GIVER.label, tag: ClientTypeEnum.ORDER_GIVER.value },
		{ label: ClientTypeEnum.JUDICIAL_FORUM.label, tag: ClientTypeEnum.JUDICIAL_FORUM.value },
		{ label: ClientTypeEnum.COMMERCIAL_COURT_REGISTRY.label, tag: ClientTypeEnum.COMMERCIAL_COURT_REGISTRY.value },
		{ label: ClientTypeEnum.COLLEAGUE.label, tag: ClientTypeEnum.COLLEAGUE.value },
		{ label: ClientTypeEnum.LAWYER.label, tag: ClientTypeEnum.LAWYER.value },
		{ label: ClientTypeEnum.CERTIFIED_ACCOUNTANT.label, tag: ClientTypeEnum.CERTIFIED_ACCOUNTANT.value },
		{ label: ClientTypeEnum.NOTARY.label, tag: ClientTypeEnum.NOTARY.value },
		{ label: ClientTypeEnum.MULTIDISCIPLINARY.label, tag: ClientTypeEnum.MULTIDISCIPLINARY.value },
		{ label: ClientTypeEnum.CITY_HALL.label, tag: ClientTypeEnum.CITY_HALL.value },
		{ label: ClientTypeEnum.PRIVATE_COMPANY.label, tag: ClientTypeEnum.PRIVATE_COMPANY.value },
	];

	static findByValue(selectedOption: ClientTypeEnum | string): any
	{
		return ClientTypeEnum.options.find(
			option => option.value === selectedOption
		);
	}
}
