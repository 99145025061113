// EnumMap.interface.ts
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { PaymentSellsyStatusEnum } from '@/Modules/Payment/Enum/PaymentSellsyStatusEnum';
import { ClientTypeEnum } from '@/Modules/Client/Enum/ClientTypeEnum';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import PaymentMethodEnum from '@/Modules/Payment/Enum/PaymentMethodEnum';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import InviteStateEnum from '@/Enum/InviteStateEnum';

export const EnumMap: Record<string, any> = {
	ActiveStatusEnum,
	CollaboratorRoleEnum,
	UserRoleEnum,
	PublishStateEnum,
	QuoteStatusEnum,
	PaymentStateEnum,
	PaymentSellsyStatusEnum,
	ClientTypeEnum,
	BilledToTypeEnum,
	NewspaperTypeEnum,
	PaymentMethodEnum,
	ClientDiscountPreferenceEnum,
	InviteStateEnum,
} as const ;

export type EnumMapKeys = keyof typeof EnumMap;