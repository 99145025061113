import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { DataTableManagerProps, DataTableManagerState } from './ListTableManager.interface';
import { OrderByInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';

const initState: DataTableManagerState = {
	filterParams: {
		page: 1,
		itemsPerPage: 10,
		search: null,
		filters: null,
		orderBy: null,
		sort: null,
	},
	list: {} as PaginateInterface,
	isLoading: false,
	isLoadingFullPage: true,
	refreshKey: 0,
};

export class ListTableManagerService extends BaseComponentService<DataTableManagerProps, DataTableManagerState>
{
	constructor()
	{
		super({} as DataTableManagerProps, initState);

		// Bind methods
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleChangeTerm = this.handleChangeTerm.bind(this);
		this.handleClearFilters = this.handleClearFilters.bind(this);
		this.handleOrderBy = this.handleOrderBy.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		// Load itemsPerPage from LocalStorage
		const savedItemsPerPage = localStorage.getItem(`elementsPerPage_${this.props.tag}`);
		const itemsPerPage = savedItemsPerPage ? parseInt(savedItemsPerPage) : this.state.filterParams.itemsPerPage;

		this.setState({
			filterParams: {
				...this.state.filterParams,
				itemsPerPage: itemsPerPage,
			},
			isLoadingFullPage: true,
		});
		await this.fetchData();
		this.setState({ isLoadingFullPage: false });
	}

	handlePageChange(newPage: number): void
	{
		this.setState(
			(prevState) => ({
				filterParams: {
					...prevState.filterParams,
					page: newPage,
				},
			}),
			this.fetchData
		);
	}

	handleItemsPerPageChange(newItemsPerPage: number): void {
		this.setState(
			(prevState) => ({
				filterParams: {
					...prevState.filterParams,
					itemsPerPage: newItemsPerPage,
					page: 1,
				},
			}),
			() => this.fetchData()
		);
	}

	handleChangeTerm(term: string): void
	{
		this.setState(
			(prevState) => ({
				filterParams: {
					...prevState.filterParams,
					search: term,
					page: 1,
				},
			}),
			() => this.fetchData()
		);
	}

	handleFilterChange(filterKey: string, value: string | null): void
	{
		this.setState(
			(prevState) => ({
				filterParams: {
					...prevState.filterParams,
					[filterKey]: value,
				},
			}),
			this.fetchData
		);
	}

	handleApplyFilter(filterKey: string, value: string | null): void
	{
		this.setState(
			(prevState) => {
				const updatedFilters = [
					...(prevState.filterParams.filters || []).filter(
						(filter: any) => !filter.hasOwnProperty(filterKey)
					),
					{ [filterKey]: value },
				];

				return {
					filterParams: {
						...prevState.filterParams,
						filters: updatedFilters,
						page: 1,
					},
				};
			},
			this.fetchData
		);
	}

	handleClearFilters(): void
	{
		this.setState(
			(prevState) => ({
				filterParams: {
					...prevState.filterParams,
					filters: [],
					page: 1,
					search: null,
					orderBy: null,
					sort: null,
				},
			}),
			this.fetchData
		);
	}

	handleOrderBy(orderBy: OrderByInterface): void
	{
		this.setState(
			(prevState) => {
				const isSameColumn = prevState.filterParams.orderBy === orderBy.tag;

				const newDirection: 'asc' | 'desc' = isSameColumn && prevState.filterParams.sort === 'asc'
					? 'desc'
					: 'asc'
				;

				return {
					filterParams: {
						...prevState.filterParams,
						orderBy: orderBy.tag,
						sort: newDirection,
						page: 1,
					}
				};
			},
			() => this.fetchData()
		);
	}

	async fetchData(): Promise<void>
	{
		this.setState({ isLoading: true });
		try {
			const params = paramsToString({
				...this.state.filterParams,
				orderBy: this.state.filterParams.orderBy,
				sort: this.state.filterParams.sort,
			});

			const updatedList = await this.props.serviceList(params);

			this.setState(
				{ list: updatedList },
				() => this.setState({
					refreshKey: this.state.refreshKey + 1,
					isLoading: false,
				})
			);

		} catch (error) {
			console.log('Failed to fetch data: ', error);
			this.setState({ isLoading: false });
		}
	}
}