import React, { PureComponent, ReactElement } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { SimpleBarProps } from '@/Modules/App/Components/Library/Chart/SimpleBar/SimpleBar.interface';

class VerticalBarChart extends PureComponent<SimpleBarProps>
{
  render(): ReactElement {
    const { data, chartTitle, area1, area2, isLoading } = this.props;

    if (isLoading) {
      return <LoaderComponent />
    }

    return (
      <div style={{ width: '100%', height: 400 }}>
        <Title type="h6">{ chartTitle }</Title>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={ data }
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey={ area1.key }
              fill={ area1.color }
              name={ area1.name }
              animationDuration={ 800 }
              animationEasing="ease-in-out"
            />
            <Bar
              dataKey={ area2.key }
              fill={ area2.color }
              name={ area2.name }
              animationDuration={ 800 }
              animationEasing="ease-in-out"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default VerticalBarChart;
