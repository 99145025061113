import { ReactElement } from 'react';
import { border, CssVariableEnum } from '@/Enum/CssVariableEnum';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export default class PaymentStateEnum
{
	value: string;
	label: string;
	css: {
		backgroundColor: string,
		border: string,
		color: string,
		icon?: ReactElement | null
	};

	constructor(value: string, label: string, css: { backgroundColor: string, border: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static PENDING = new PaymentStateEnum(
		'PENDING',
		'En attente',
		{
			backgroundColor: colors.blueRibbon100,
			color: colors.blueRibbon700,
			border: border(colors.blueRibbon300),
		}
	);
	static UNPAID = new PaymentStateEnum(
		'UNPAID',
		'Impayé',
		{
			backgroundColor: CssVariableEnum['--color-yellow-200'],
			color: CssVariableEnum['--color-yellow-500'],
			border: border(CssVariableEnum['--color-purple-200']),
		}
	);
	static VALIDATE = new PaymentStateEnum(
		'VALIDATE',
		'Validé',
		{
			backgroundColor: CssVariableEnum['--color-yellow-200'],
			color: CssVariableEnum['--color-yellow-500'],
			border: border(CssVariableEnum['--color-purple-200']),
		}
	);
	static PARTIAL = new PaymentStateEnum(
		'PARTIAL',
		'Partiel',
		{
			backgroundColor: CssVariableEnum['--color-yellow-200'],
			color: CssVariableEnum['--color-yellow-500'],
			border: border(CssVariableEnum['--color-purple-200']),
		}
	);
	static PAID = new PaymentStateEnum(
		'PAID',
		'Payé',
		{
			backgroundColor: colors.success50,
			color: colors.success600,
			border: border(colors.success100),
		}
	);

	static CANCEL = new PaymentStateEnum(
		'CANCEL',
		'Annuler',
		{
			backgroundColor: colors.error50,
			color: colors.error500,
			border: border(colors.error100)
		}
	);

	static options: PaymentStateEnum[] = [
		PaymentStateEnum.PENDING,
		PaymentStateEnum.UNPAID,
		PaymentStateEnum.VALIDATE,
		PaymentStateEnum.PARTIAL,
		PaymentStateEnum.PAID,
		PaymentStateEnum.CANCEL
	];

	static optionsFilter: OptionFilterInterface[] = [
		{ label: PaymentStateEnum.PENDING.label, tag: PaymentStateEnum.PENDING.value },
		{ label: PaymentStateEnum.UNPAID.label, tag: PaymentStateEnum.UNPAID.value },
		{ label: PaymentStateEnum.VALIDATE.label, tag: PaymentStateEnum.VALIDATE.value },
		{ label: PaymentStateEnum.PARTIAL.label, tag: PaymentStateEnum.PARTIAL.value },
		{ label: PaymentStateEnum.PAID.label, tag: PaymentStateEnum.PAID.value },
		{ label: PaymentStateEnum.CANCEL.label, tag: PaymentStateEnum.CANCEL.value },
	];

	static findByValue(value: string): PaymentStateEnum | undefined
	{
		return this.options.find(option => option.value === value);
	}
}