import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { ButtonType } from '@/Modules/App/Components/Atom/Button/Type/ButtonType';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export default class ButtonOld extends React.Component
	<
		{
			label?: string,
			children?: ReactNode,
			type?: ButtonType,
			isTableButton?: boolean,
			cardAction?: ReactElement,
			disabled?: boolean,
			onClick?: ((event: any) => Promise<void>) | ((event?: any) => void),
			iconLeft?: React.ReactNode,
			iconRight?: React.ReactNode,
			style?: CSSProperties,
			onMouseDown?: (event: any) => void
			containerStyle?: CSSProperties
		},
		{
			isHovered: boolean
		}
	>
{

	state = {
		isHovered: false,
	};

	render(): ReactElement
	{
		//<editor-fold desc="// Const Styles" defaultstate="collapsed"

		const { type } = this.props;

		const isInlineButton =
			this.props.type === 'inline-default'
			|| this.props.type === 'inline-danger'
			|| this.props.type === 'inline-default-blue';

		const buttonStyle: React.CSSProperties = {
			position: 'relative',
			display: 'inline-flex',
			flexShrink: 0,
			borderRadius: '8px',
			overflow: 'hidden',
			height: (this.props.isTableButton) ? '20px' : '32px',
			fontSize: '14px',
			marginLeft: '6px',
			cursor: 'pointer',
			alignItems: (type === 'pagination' || type === 'pagination-current') ? 'center' : '',
			...this.props.style
		};

		const buttonStyleContainer: React.CSSProperties = {
			userSelect: 'none',
			transition: 'background 20ms ease-in 0s',
			cursor: this.props.disabled ? 'not-allowed' : 'pointer',
			display: 'flex',
			width: (type === 'pagination' || type === 'pagination-current') ? '25px' : '',
			height: (type === 'pagination' || type === 'pagination-current') ? '25px' : '',
			alignItems: 'center',
			justifyContent: 'center',
			whiteSpace: 'nowrap',
			borderTopLeftRadius: '8px',
			borderRadius: '8px',
			borderBottomLeftRadius: '8px',
			padding: (type === 'pagination' || type === 'pagination-current') ? '6px' : '0px 8px',
			fontWeight: (isInlineButton) ? '400' : '500',
			border: 'none',
			boxShadow: (type === 'default') ? `#D0D5DD 0px 0px 0px 1px inset` : 'none',
			backgroundColor: this.props.disabled
				? this.getButtonDisabledStyle().backgroundColor
				: (!this.state.isHovered
					? this.getButtonColorStyle(this.props.type || 'default').backgroundColor
					: this.getButtonColorStyle(this.props.type || 'default').hoveredBackgroundColor),
			color: this.props.disabled
				? this.getButtonDisabledStyle().color
				: (!this.state.isHovered
					? this.getButtonColorStyle(this.props.type || 'default').color
					: this.getButtonColorStyle(this.props.type || 'default').hoveredColor),
			...this.props.containerStyle
		};

		//</editor-fold>

		return (
			<>
				<div style={ buttonStyle }>
					<button
						style={ buttonStyleContainer }
						onMouseEnter={ () => this.setState({ isHovered: true }) }
						onMouseLeave={ () => this.setState({ isHovered: false }) }
						onMouseDown={ this.props.onMouseDown }
						disabled={ this.props.disabled }
						onClick={ this.props.onClick }>
						{ this.props.iconLeft &&
              <div style={ { marginRight: (this.props.children || this.props.label) ? '5px' : 0, display: 'flex' } }>
								{ this.props.iconLeft }
              </div>
						}
						{ (this.props.children) ? this.props.children : this.props.label }
						{ this.props.iconRight &&
              <div style={ { marginLeft: (this.props.children) ? '5px' : 0, display: 'flex' } }>
								{ this.props.iconRight }
              </div>
						}
					</button>
				</div>
				{ this.props.cardAction && this.props.cardAction }
			</>
		);
	}

	private getButtonColorStyle(buttonType: ButtonType): {
		backgroundColor: string,
		hoveredBackgroundColor: string,
		color: string,
		hoveredColor: string
	}
	{

		//<editor-fold desc="// Const " defaultstate="collapsed">

		const btnInlineDefault = {
			backgroundColor: 'transparent',
			hoveredBackgroundColor: 'rgba(55, 53, 47, 0.08)',
			color: 'rgba(55, 53, 47, 0.65)',
			hoveredColor: 'rgba(55, 53, 47, 0.65)'
		};
		const btnWarning = {
			backgroundColor: CssVariableEnum['--color-yellow-300'],
			hoveredBackgroundColor: CssVariableEnum['--color-yellow-400'],
			color: CssVariableEnum['--color-white'],
			hoveredColor: CssVariableEnum['--color-white'],
		};
		const btnInlineDefaultBlue = {
			backgroundColor: 'transparent',
			hoveredBackgroundColor: 'rgba(35, 131, 226, 0.07)',
			color: 'rgb(35, 131, 226)',
			hoveredColor: 'rgb(35, 131, 226)'
		};
		const btnDanger = {
			backgroundColor: 'rgba(248, 81, 73, 0.07)',
			hoveredBackgroundColor: 'rgb(248, 81, 73)',
			color: 'rgb(248, 81, 73)',
			hoveredColor: 'white'
		};
		const btnSuccess = {
			backgroundColor: CssVariableEnum['--color-green-600'],
			hoveredBackgroundColor: CssVariableEnum['--color-green-700'],
			color: CssVariableEnum['--color-white'],
			hoveredColor: CssVariableEnum['--color-white']
		};
		const btnDefault = {
			backgroundColor: 'white',
			hoveredBackgroundColor: CssVariableEnum['--color-grey-200'],
			color: CssVariableEnum['--color-grey-900'],
			hoveredColor: CssVariableEnum['--color-grey-900']
		};
		const btnDefaultDark = {
			backgroundColor: CssVariableEnum['--color-grey-900'],
			hoveredBackgroundColor: CssVariableEnum['--color-grey-800'],
			color: CssVariableEnum['--color-grey-25'],
			hoveredColor: CssVariableEnum['--color-grey-25']
		};
		const btnDefaultBlue = {
			backgroundColor: colors.blueRibbon600,
			hoveredBackgroundColor: colors.blueRibbon700,
			color: colors.white,
			hoveredColor: colors.white
		};
		const btnDefaultPurple = {
			backgroundColor: CssVariableEnum['--color-purple-500'],
			hoveredBackgroundColor: CssVariableEnum['--color-purple-600'],
			color: 'white',
			hoveredColor: 'white'
		};
		const btnClosed = {
			backgroundColor: CssVariableEnum['--color-grey-50'],
			hoveredBackgroundColor: CssVariableEnum['--color-grey-100'],
			color: CssVariableEnum['--color-grey-900'],
			hoveredColor: CssVariableEnum['--color-grey-900']
		};
		const btnClosedDark = {
			backgroundColor: CssVariableEnum['--color-grey-900'],
			hoveredBackgroundColor: CssVariableEnum['--color-grey-800'],
			color: CssVariableEnum['--color-grey-25'],
			hoveredColor: CssVariableEnum['--color-grey-25']
		};
		const btnClosedMessage = {
			backgroundColor: 'transparent',
			hoveredBackgroundColor: CssVariableEnum['--color-error-200'],
			color: CssVariableEnum['--color-error-600'],
			hoveredColor: CssVariableEnum['--color-error-600']
		};
		const btnPagination = {
			backgroundColor: 'transparent',
			hoveredBackgroundColor: CssVariableEnum['--color-grey-100'],
			color: CssVariableEnum['--color-grey-400'],
			hoveredColor: CssVariableEnum['--color-grey-600']
		};
		const btnPaginationCurrent = {
			backgroundColor: CssVariableEnum['--color-grey-100'],
			hoveredBackgroundColor: CssVariableEnum['--color-grey-200'],
			color: CssVariableEnum['--color-grey-600'],
			hoveredColor: CssVariableEnum['--color-grey-600']
		};
		const btnTagBlue = {
			backgroundColor: CssVariableEnum['--color-blue-100'],
			hoveredBackgroundColor: CssVariableEnum['--color-blue-100'],
			color: CssVariableEnum['--color-blue-600'],
			hoveredColor: CssVariableEnum['--color-blue-600']
		};

		// </editor-fold>

		switch (buttonType) {
			case 'danger':
				return btnDanger;
			case 'warning':
				return btnWarning;
			case 'success':
				return btnSuccess;
			case 'default-blue':
				return btnDefaultBlue;
			case 'default-purple':
				return btnDefaultPurple;
			case 'default':
				return btnDefault;
			case 'default-dark':
				return btnDefaultDark;
			case 'inline-default':
				return btnInlineDefault;
			case 'inline-default-blue':
				return btnInlineDefaultBlue;
			case 'closed':
				return btnClosed;
			case 'closed-dark':
				return btnClosedDark;
			case 'closed-message':
				return btnClosedMessage;
			case 'pagination':
				return btnPagination;
			case 'pagination-current':
				return btnPaginationCurrent;
			case 'tag-blue':
				return btnTagBlue;
			case 'inline-danger':
				return {
					backgroundColor: 'transparent',
					hoveredBackgroundColor: 'rgb(0, 119, 212)',
					color: 'white',
					hoveredColor: 'red'
				};
			default:
				return { backgroundColor: '#DDDDDD', hoveredBackgroundColor: '#DDDDDD', color: 'white', hoveredColor: 'red' };
		}
	}

	private getButtonDisabledStyle(): { backgroundColor: string, color: string }
	{
		return {
			backgroundColor: CssVariableEnum['--color-grey-100'],
			color: CssVariableEnum['--color-grey-400'],
		};
	}
}