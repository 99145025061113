import React from 'react';

export class BaseComponentService<P, S>
{
	protected props: P;
	protected state: S;
	private listeners: Function[] = [];
	private isMounted: boolean = false;

	constructor(initialProps: P, initialState: S)
	{
		this.props = initialProps;
		this.state = initialState;

		// Bind
		this.getState = this.getState.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	subscribeState(component: React.Component<any, any>): void
	{
		this.listeners.push((partialState: Partial<S>) =>
		{
			component.setState((prevState: S) => ({
				...prevState,
				...partialState
			}));
		});
	}

	unsubscribe(): void
	{
		this.isMounted = false;
	}

	getState(): S
	{
		return this.state;
	}

	getProps(): P
	{
		return this.props;
	}

	setProps(newProps: P): void
	{
		this.props = { ...this.props, ...newProps };
		this.notify();
	}

	/**
	 *
	 * @param partialState
	 * @param callback
	 * @protected
	 */
	protected setState(partialState: Partial<S> | ((prevState: S) => Partial<S>), callback?: () => void): void
	{
		if (typeof partialState === 'function') {
			const nextState: Partial<S> = partialState(this.state);
			this.state = { ...this.state, ...nextState };
		} else {
			this.state = { ...this.state, ...partialState };
		}

		this.notify();

		if (callback) {
			callback();
		}
	}

	protected setPartialState(partialState: Partial<S>): void
	{
		this.setState((prevState: S) => ({
			...prevState,
			...partialState
		}));
	}

	/**
	 * Méthode générique pour mettre à jour une propriété de l'état en fonction de l'événement d'un champ de formulaire.
	 * Elle fonctionne avec n'importe quel nom de propriété basé sur le `name` du champ de formulaire.
	 * @param event - L'événement de changement dans le formulaire
	 */
	protected handleInputChange<K extends keyof S>(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void
	{
		const { name, value } = event.target;

		this.setState((prevState: S) => ({
			...prevState,
			[name]: value as S[K],
		}));
	}

	protected notify(): void
	{
		this.listeners.forEach(listener => listener(this.state));
	}
}