export function handleChangeInput(event: any, setState: Function): void
{
  const { name, value } = event.target;
  const subObject: string[] = name.split('.');

  setState((prevState: any) => {
    if (!prevState || !prevState.formData) {
      return prevState;
    }

    let updatedFormData = { ...prevState.formData };

    if (subObject.length > 1) {
      let subObjectRef = updatedFormData;
      for (let i = 0; i < subObject.length - 1; i++) {
        if (!subObjectRef[subObject[i]]) {
          subObjectRef[subObject[i]] = {};
        }
        subObjectRef = subObjectRef[subObject[i]];
      }

      const lastKey = subObject[subObject.length - 1];

      // Conversion et vérification du type number
      const parsedValue = Number(value);

      if (typeof subObjectRef[lastKey] === 'number') {
        subObjectRef[lastKey] = !isNaN(parsedValue) ? parsedValue : 0;
      } else {
        subObjectRef[lastKey] = value;
      }
    } else {
      const parsedValue = Number(value);

      if (typeof updatedFormData[name] === 'number') {
        updatedFormData[name] = !isNaN(parsedValue) ? parsedValue : 0;
      } else {
        updatedFormData[name] = value;
      }
    }

    return { formData: updatedFormData };
  });
}
