import LocalStorageService from '@/Service/Common/LocalStorageService';
import { AuthResponseInterface } from '@/Modules/Auth/Interface/AuthResponseInterface';
import { ErrorResponseInterface } from '@/Modules/App/Interface/ErrorResponseInterface';
import { ApiAuthService } from '@/Service/Api/ApiAuthService';
import { ApiUserService } from '@/Service/Api/ApiUserService';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';

class AuthService
{
	private authService: ApiAuthService;
	private userService: ApiUserService;
	private userAdminService: ApiAdminUserService;

	constructor()
	{
		this.authService = new ApiAuthService();
		this.userService = new ApiUserService();
		this.userAdminService = new ApiAdminUserService();
	}

	public async storedUser(): Promise<any>
	{
		const storedUser = LocalStorageService.getUser();
		if (!storedUser) {
			return null;
		}
		return this.fetchUserByRole(storedUser.userRole, storedUser.userId);
	}

	public async login(email: string, password: string): Promise<any>
	{
		try {
			const responseAuth: AuthResponseInterface | ErrorResponseInterface = await this.authService.auth(email, password);

			if ('user' in responseAuth) {
				const user = await this.fetchUserByRole(responseAuth.user.userRole, responseAuth.user.userId);
				LocalStorageService.setUser(responseAuth.user);
				return user;
			} else if ('errorMessage' in responseAuth) {
				throw new Error(responseAuth.errorMessage);
			}
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	public async fetchUserByRole(role: string, userId: number): Promise<any>
	{
		switch (role) {
			case 'ROLE_USER':
				return this.userService.show(userId);
			case 'ROLE_ADMIN':
			case 'ROLE_SUPER_ADMIN':
			case 'ROLE_DEVELOPER':
				return this.userAdminService.show(userId);
			default:
				throw new Error(`Unsupported role: ${role}`);
		}
	}

	public logout(): void
	{
		LocalStorageService.clearLocalStorage();
	}
}

export default AuthService;
