import React, { Component, ReactElement } from 'react';
import { LegalNoticeHeadOfInvoiceProps, LegalNoticeHeadOfInvoiceState } from './LegalNoticeHeadOfInvoice.interface';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import PaymentMethodEnum from '@/Modules/Payment/Enum/PaymentMethodEnum';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import {
	LegalNoticeHeadOfInvoiceStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeHeadOfInvoice/LegalNoticeHeadOfInvoice.styles';
import { LegalNoticeFileInterface } from '@/Modules/LegalNotice/Common/LegalNoticeFile.interface';

class LegalNoticeHeadOfInvoice extends Component<LegalNoticeHeadOfInvoiceProps, LegalNoticeHeadOfInvoiceState>
{
	render(): ReactElement
	{
		// Props
		const { legalNotice } = this.props;
		const styles = LegalNoticeHeadOfInvoiceStyles;

		return (
			<>
				<div style={ styles.grid }>
					<div style={ styles.column }>
						<div>
							<div style={ styles.title }>Facturé à</div>
							<div
								style={ { cursor: 'pointer' } }
								onClick={ () => this.props.navigation(`/admin/clients/${ legalNotice.client.id }?from=${ window.location.pathname }`)
								}>
									<span style={{
										color: colors.blueRibbon600,
										textDecoration: 'underline',
										textDecorationStyle: 'dashed'
									}}>
										{ legalNotice.billingAddress.name }
									</span>
							</div>
						</div>
						<div>
							<div style={ styles.title }>Adresse de facturation</div>
							<div style={ { cursor: 'pointer' } }>
								<div>{ legalNotice.billingAddress.number } { legalNotice.billingAddress.street }</div>
								<div>{ legalNotice.billingAddress.additionalData }</div>
								<div>{ legalNotice.billingAddress.zipCode } { legalNotice.billingAddress.city }</div>
								<div>{ legalNotice.billingAddress.country }</div>
							</div>
						</div>
					</div>
					<div>
						<div style={ styles.column }>
							<div>
								<div style={ styles.title }>Numéro de facture</div>
								<div>
									<span>
										{ legalNotice.legalNoticeFiles &&
											legalNotice.legalNoticeFiles.find(
												(file: LegalNoticeFileInterface) => file.type === 'INVOICE'
											)?.name
										}
									</span>
								</div>
							</div>
							<div>
								<div style={ styles.title }>Méthode de paiement par défaut</div>
								<div>{ PaymentMethodEnum.findByValue(String(legalNotice.client.options.paymentMethod))?.label }</div>
							</div>
							<div>
								<div style={ styles.title }>Statut du paiement</div>
								<Tag value={ legalNotice.paymentStatus } enumName={ 'PaymentStateEnum' }/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticeHeadOfInvoice);