import { Component, ReactElement } from 'react';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import {
	NavigationProps,
	NavigationState
} from '@/Modules/App/Components/Menu/Navigation/Navigation.interface';
import { NavigationService } from '@/Modules/App/Components/Menu/Navigation/Navigation.service';
import MenuItem from '@/Modules/App/Components/Menu/MenuItem';
import { MenuItemInterface } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.interface';
import { NavigationMenuStyles } from '@/Modules/App/Components/Menu/Navigation/NavigationMenu.styles';

class NavigationMenu extends Component<NavigationProps, NavigationState>
{
	navigationService = new NavigationService();

	constructor(props: NavigationProps)
	{
		super(props);

		// Config
		this.navigationService.setProps(this.props);
		this.navigationService.subscribeState(this);

		// State
		this.state = this.navigationService.getState();
	}

	componentDidMount(): void
	{
		this.navigationService.init();
	}

	componentWillUnmount(): void
	{
		this.navigationService.unsubscribe();
	}

	render(): ReactElement
	{
		return (
			<div style={ NavigationMenuStyles.container }>
				{ this.state.navigationMenu.map((menuItem: MenuItemInterface, index) => (
					<MenuItem
						key={ menuItem.label + index }
						label={ menuItem.label }
						type={ menuItem.type }
						urlPath={ menuItem.urlPath }
						iconName={ menuItem.iconName }
						authorization={ menuItem.authorization }
					/>
				)) }
			</div>
		);
	}
}

export default withGlobalContext(NavigationMenu);