import React, { ReactElement } from 'react';
import { AuthProviderState } from '@/Provider/Interface/Auth/AuthProviderState';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import AuthService from '@/Provider/Auth/Auth.service';

export const AuthContext = React.createContext<AuthContextType | null>(null);

export class AuthProvider extends React.Component<any, AuthProviderState>
{
	private authService: AuthService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = {
			user: null,
			isUserFetch: false,
			isLoading: true,
		};

		// Service
		this.authService = new AuthService();

		// Bind
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
	}

	render(): ReactElement
	{
		return (
			<AuthContext.Provider value={ {
				user: this.state.user,
				login: this.login,
				logout: this.logout,
				isLoading: this.state.isLoading,
			} }>
				{ this.props.children }
			</AuthContext.Provider>
		);
	}

	//<editor-fold desc=" Methods / Handle States" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.loadStoredUser();
	}

	// Methods
	private async loadStoredUser(): Promise<void>
	{
		try {
			const user = await this.authService.storedUser();
			if (user) {
				this.setState({ user, isUserFetch: true, isLoading: false });
			} else {
				this.setState({ isLoading: false });
			}
		} catch (error) {
			console.error('[AuthWrapper] - Erreur lors du chargement de l\'utilisateur :', error);
			this.setState({ isLoading: false });
		}
	}

	private async login(email: string, password: string): Promise<void>
	{
		try {
			const user = await this.authService.login(email, password);
			this.setState({ user, isUserFetch: true, isLoading: false });
		} catch (error) {
			console.error('[AuthWrapper] - Erreur lors de la connexion :', error);
		}
	}

	private logout(): void
	{
		this.authService.logout();
		this.setState({ user: null, isUserFetch: false, isLoading: false });
	}

	//</editor-fold>
}
