import { CSSProperties } from 'react';
import { borderStyles, boxShadows, _inputStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const SelectStyles = {
	base: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	} as CSSProperties,

	container: {
		width: '100%',
		position: 'relative',
	} as CSSProperties,

	button: (isHovered: boolean) => ({
		...(isHovered ? { ..._inputStyles.main, borderColor: colors.gray400 } : _inputStyles.main),
		flexDirection: 'row',
		cursor: 'pointer',
	}) as CSSProperties,

	content: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		gap: 10
	} as CSSProperties,

	text: {
		display: 'flex',
		flexGrow: 1,
		fontWeight: 500,
		WebkitFontSmoothing: 'antialiased'
	} as CSSProperties,

	icon: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		flexShrink: 1,
	} as CSSProperties,

	openList: (isOpen: boolean) => ({
		position: 'absolute',
		maxHeight: 200,
		flexWrap: 'nowrap',
		overflowY: 'scroll',
		zIndex: 100,
		width: '100%',
		display: (isOpen) ? 'block' : 'none',
		backgroundColor: colors.white,
		borderRadius: 6,
		padding: 8,
		...borderStyles,
		...boxShadows.short
	}) as CSSProperties,

	listItem: {
		whiteSpace: 'nowrap',
		padding: '4px 8px',
	} as CSSProperties,

	hoveredItem: {
		backgroundColor: colors.gray50,
		cursor: 'pointer'
	}
} as const;