import React from 'react';
import { useGlobalContext } from '@/Provider/Globals/Global.provider';
import { GlobalContextInterface } from '@/Context/Global/Global.interface';

export interface WithGlobalContextProps
{
	globalContext: GlobalContextInterface
}

export function withGlobalContext<T extends object>(
	WrappedComponent: React.ComponentType<T>
)
{
	const displayName: string = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	const ComponentWithGlobalContext = (props: Omit<T, keyof GlobalContextInterface>) => {
		const globalContext = useGlobalContext();

		if (!globalContext) {
			console.warn('globalContext est vide ou non initialisé.');
			return null;
		}

		return <WrappedComponent {...(props as T)} {...globalContext} />;
	};

	ComponentWithGlobalContext.displayName = `withGlobalContext(${displayName})`;

	return ComponentWithGlobalContext;
}
