import { CSSProperties } from 'react';

export const BreadcrumbStyles = {
	container: {
		marginBottom: -2,
	} as CSSProperties,

	item: {
		fontSize: 12,
		fontWeight: 400,
		margin: 0,
		cursor: 'pointer'
	} as CSSProperties
} as const;