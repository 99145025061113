export default class PaymentMethodEnum
{
	value: string;
	label: string;
	sellsyId: number;

	constructor(value: string, label: string, sellyId: number)
	{
		this.value = value;
		this.label = label;
		this.sellsyId = sellyId;
	}

	static DIRECT_DEBIT = new PaymentMethodEnum(
		'DIRECT_DEBIT',
		'Prélèvement',
		2879061
	);
	static BANK_CHECK = new PaymentMethodEnum(
		'BANK_CHECK',
		'Chèque',
		2879056
	);
	static BANK_TRANSFER = new PaymentMethodEnum(
		'BANK_TRANSFER',
		'Virement bancaire',
		2879058
	);
	static CUSTOMER_GIFT = new PaymentMethodEnum(
		'CUSTOMER_GIFT',
		'Cadeau client',
		2879057
	);
	static CASH = new PaymentMethodEnum(
		'CASH',
		'Espèces',
		2879059
	);
	static CREDIT_CARD = new PaymentMethodEnum(
		'CREDIT_CARD',
		'Carte de crédit (TPE)',
		2879060
	);
	static ADJUSTMENT = new PaymentMethodEnum(
		'ADJUSTMENT',
		'Régularisation',
		3909049
	);

	static options: PaymentMethodEnum[] = [
		PaymentMethodEnum.DIRECT_DEBIT,
		PaymentMethodEnum.CREDIT_CARD,
		PaymentMethodEnum.BANK_CHECK,
		PaymentMethodEnum.BANK_TRANSFER,
		PaymentMethodEnum.CUSTOMER_GIFT,
		PaymentMethodEnum.CASH,
		PaymentMethodEnum.ADJUSTMENT,
	];

	static optionsForm: PaymentMethodEnum[] = [
		PaymentMethodEnum.CREDIT_CARD,
		PaymentMethodEnum.CASH,
	];

	static findByValue(value: string): PaymentMethodEnum | undefined
	{
		return PaymentMethodEnum.options.find(option => option.value === value);
	}

}