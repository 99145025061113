import React, { Component, ReactElement, ReactNode } from 'react';
import { ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles'; // Spinner from react-spinners

const overlayStyles: (overlayColor: string) => React.CSSProperties = (overlayColor: string) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backdropFilter: 'blur(1px)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: 1000
});

interface IsLoadingProps
{
	isLoading: boolean;
	refreshKey?: number;
	children: ReactNode;
	overlayColor?: string;
}

interface IsLoadingState
{
	localLoading: boolean;
}

class IsLoading extends Component<IsLoadingProps, IsLoadingState>
{
	private timerId: ReturnType<typeof setTimeout> | null = null;

	constructor(props: IsLoadingProps)
	{
		super(props);
		this.state = {
			localLoading: true
		};
	}

	componentDidMount(): void
	{
		this.setState({
			localLoading: this.props.isLoading
		});
	}

	componentDidUpdate(prevProps: IsLoadingProps): void
	{
		// When refreshKey or isLoading changes, handle loading state
		if(this.props.refreshKey) {
			if (prevProps.refreshKey !== this.props.refreshKey || prevProps.isLoading !== this.props.isLoading) {

				this.setState({ localLoading: true });
				// Clear any existing timeout before setting a new one
				if (this.timerId) {
					clearTimeout(this.timerId);
				}

				// Set a timeout to stop loading after 500ms
				this.timerId = setTimeout(() =>
				{
					this.setState({ localLoading: false });
				}, 500);
			}
		} else if (prevProps.isLoading !== this.props.isLoading) {
			this.setState({ localLoading: this.props.isLoading });
		}
	}

	componentWillUnmount(): void
	{
		// Cleanup timeout when the component unmounts
		if (this.timerId) {
			clearTimeout(this.timerId);
		}
	}

	render(): ReactElement
	{
		const { children } = this.props;
		const { localLoading } = this.state;

		return (
			<div style={ { position: 'relative' } }>
				{ children }
				{ localLoading && (
					<div style={ overlayStyles(this.props.overlayColor!) }>
						<ClipLoader
							color={ colors.gray400 }
							loading={ localLoading }
							size={ 35 }
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				) }
			</div>
		);
	}
}

export default IsLoading;
