import React, { ReactElement } from 'react';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import ConsumerAddressFormComponent
  from '@/Modules/LegalNotice/Components/Form/Admin/Consumer/ConsumerAddressFormComponent';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ComponentProps
{
  isDisplayBlock: boolean,
  client: ClientInterface,
  consumer: ConsumerDataInterface,
  consumerSellsyAddress: AddressInterface,
  onNewAddress: (address: AddressInterface) => void
}

interface ComponentState
{
  formData: { address: AddressInterface },
  isFormComplete: boolean,
  isLoading: boolean
}

export default class BlockNewConsumerAddressComponent extends React.Component<ComponentProps, ComponentState>
{
  sellsyService : ApiAdminSellsyService;

  constructor(props: any)
  {
    super(props);

    // Init state
    this.state = {
      formData: { address: {} as AddressInterface },
      isFormComplete: false,
      isLoading: false
    };

    // Service
    this.sellsyService = new ApiAdminSellsyService();
  }

  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Nouveau Siège social"}
              children={
                <>
                  <>
                    <ConsumerAddressFormComponent
                      isTitleNeeded={ false }
                      formData={ this.state.formData }
                      onChange={ (event: any) => this.handleInputChange(event) }
                      isConsumerFormComplete={ true }
                    />
                    <div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end' } }>
                      {this.state.isLoading && (
                        <div style={{ marginLeft: '10px', marginTop: '6px' }}>
                          <LoaderComponent variant={'primary'} />
                        </div>
                      )}

                      <ButtonOld
                        type={ 'default-blue' }
                        onClick={ () => this.onFormSubmit() }
                        disabled={ !this.state.isFormComplete }
                      >
                        Enregistrer la nouvelle adresse
                      </ButtonOld>
                    </div>
                  </>
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private async onFormSubmit(): Promise<void>
  {
    this.setState({ isLoading: true });

    await this.setState({ formData: { address : this.state.formData.address }});

    try {
      const response = await this.sellsyService.updateCompanyAddress(
        this.props.client.company.id,
        parseInt(this.props.client.extSellsyId),
        this.props.consumerSellsyAddress.extSellsyId as number,
        this.state.formData
      );

      this.props.onNewAddress(this.prepareAddressState(response));

    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleInputChange(event: any): void
  {
    handleChangeInput(event, (newState: any) => {
      this.setState(newState, this.isFormComplete);
    });
  }

  private isFormComplete(): void
  {
    const { address } = this.state.formData;

    const isFormComplete =
      !!address.street &&
      !!address.city &&
      !!address.zipCode
    ;

    this.setState({ isFormComplete });
  }

  private prepareAddressState(sellsyAddress: any): AddressInterface
  {
    const address = sellsyAddress.address_line_1;
    const addressParts = address.match(/^(\d+)\s+(.*)$/);

    return {
      name: sellsyAddress.name,
      number: addressParts ? parseInt(addressParts[1]) : null,
      street: addressParts ? addressParts[2].trimStart() : address,
      additionalData: sellsyAddress.address_line_2,
      zipCode: sellsyAddress.postal_code,
      city: sellsyAddress.city,
      country: sellsyAddress.country,
      phone: sellsyAddress.phone,
      extSellsyId: sellsyAddress.id,
      isBillingAddress: false
    };
  }

  //</editor-fold>
}