import { GlobalsStyles } from '@/Modules/App/Style/Globals/Globals.styles';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';

export const AuthCompanyStyles  = {
	container: {
		textAlign: 'center',
		marginBottom: 30
	},
	cardCompaniesContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '10px',
		gap: 6
	},
	...GlobalsStyles,
	...CardStyles
} as const;