export function paramsToString(filterParamsState: any): string
{
	const queryParams = new URLSearchParams();

	for (const [key, value] of Object.entries(filterParamsState)) {
		if (key !== 'filters' && value !== null && value !== undefined) {
			queryParams.append(key, value.toString());
		}
	}

	if (Array.isArray(filterParamsState.filters) && filterParamsState.filters.length > 0) {
		queryParams.append('filters', JSON.stringify(filterParamsState.filters));
	}

	return queryParams.toString();
}
