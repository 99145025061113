import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	CreateClientFormProps,
	CreateClientFormState,
	createDefaultClientForm,
	resetForm
} from './CreateClientForm.interface';
import React from 'react';
import AddressModel from '@/Modules/Client/Model/AddressModel';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { AssociateUserState } from '@/Modules/User/Components/AssociateUser/AssociateUser.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';

const initState: CreateClientFormState = {
	isLoading: false,
	formData: createDefaultClientForm(),
	errorMessage: '',
	showSiretInput: false
};

export class CreateClientFormService extends BaseComponentService<CreateClientFormProps, CreateClientFormState>
{
	appService: ApiAppService;
	apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as CreateClientFormProps, initState);

		// Services
		this.appService = new ApiAppService();

		// Bind
		this.handleSirenEventField = this.handleSirenEventField.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
		this.setUserData = this.setUserData.bind(this);
		this.setIsLoading = this.setIsLoading.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{}

	/**
	 * Handle Siren Filed to autoComplete Form
	 * @param event
	 */
	async handleSirenEventField(event: React.ChangeEvent<HTMLInputElement>): Promise<any>
	{
		const cleanSirenValue = (value: string) => value.replace(/\s+/g, '');

		let inputSirenValue: string = event.target.value;

		inputSirenValue = cleanSirenValue(inputSirenValue);

		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				...resetForm(),
				siren: inputSirenValue,
			},
			errorMessage: ''
		}));

		if (inputSirenValue.length === 9) {
			this.setState({ isLoading: true });
			await this.callApiSiren(inputSirenValue);
		}
	}

	/**
	 * Set UserId from Associate User
	 * @param associateUserState
	 * @param currentState
	 */
	setUserData(associateUserState: AssociateUserState, currentState: CreateClientFormState): void
	{
		const userId: number | undefined = associateUserState.selectedUser?.id;

		if (userId) {
			this.setState(prevState => ({
				formData: {
					...currentState.formData,
					userId,
				}
			}));
		}
	}

	/**
	 * Set External Sellsy ID
	 * @param companySellsyState
	 */
	setSellsyData(companySellsyState: AssociateSellsyCompanyState): void
	{
		const extSellsyId: string = (!companySellsyState.formData.isSellsyCreated)
			? (companySellsyState.selectedCompanySellsy?.object.id.toString()) ? companySellsyState.selectedCompanySellsy?.object.id.toString() : ''
			: ''
		;

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				extSellsyId,
				isSellsyCreated: companySellsyState.formData.isSellsyCreated
			}
		}));
	}

	/**
	 * Handle create client form submission
	 * @param event
	 * @param state
	 */
	async handleCreate(event: React.FormEvent<HTMLFormElement>, state: CreateClientFormState): Promise<any>
	{
		event.stopPropagation();

		try {
			if (this.validateForm(state)) {
				this.setIsLoading(true);
				const response = await this.apiAdminClientService.create(state.formData);

				if (response.errorMessage) {
					console.error(response.errorMessage);
					if (response.errorMessage && response.errorMessage.includes('Le SIREN existe déjà dans cette entreprise')) {
						this.setState({ showSiretInput: true });
					}
					this.setState({
						isLoading: false,
						errorMessage: response.errorMessage
					});
				}

				this.setIsLoading(false);
			}
		} catch (error) {
			console.error('Something went wrong with creating client CREATECLIENTFORM', error);
			this.setIsLoading(false);
		}
	}

	/**
	 * Validate the form before submission
	 * @return boolean - Returns true if the form is valid
	 * @private
	 */
	private validateForm(state: CreateClientFormState): boolean
	{
		const client = state.formData;

		// Array of validation checks with error messages
		let validations = [
			{ isValid: !!client.siren, errorMessage: 'Merci de remplir le Siren' },
			{ isValid: !!client.name, errorMessage: 'Le nom de la société est requis.' },
			{ isValid: !!client.phone, errorMessage: 'Le téléphone est requis' },
			{ isValid: !!client.address, errorMessage: 'Tous les champs obligatoires dans l\'adresse ne sont pas remplis' },
			{ isValid: !!client.userId, errorMessage: 'Merci d\'associer un utilisateur' },
		];

		if (!state.formData.isSellsyCreated) {
			validations.push({ isValid: !!client.extSellsyId, errorMessage: 'Merci d\'associer un compte Sellsy' });
		}

		// Iterate over the validation checks
		for (const validation of validations) {
			if (!validation.isValid) {
				this.setState({
					formData: state.formData,
					errorMessage: validation.errorMessage
				});
				return false;
			}
		}

		// If all validations pass, clear errorMessage
		this.setState({
			formData: state.formData,
			errorMessage: ''
		});

		return true;
	}

	/**
	 * Call Siren From API
	 * @param inputSirenValue
	 * @private
	 */
	private async callApiSiren(inputSirenValue: string): Promise<void> {
		if (inputSirenValue.length === 9) {
			try {
				const responseSiren = await this.appService.sirenInsee(inputSirenValue);

				// Error message handling
				if (responseSiren.errorMessage) {
					if (responseSiren.errorMessage.includes('Le SIREN existe déjà dans cette entreprise')) {
						console.log('HERE');
						this.setState({
							errorMessage: responseSiren.errorMessage,
							showSiretInput: true,
							isLoading: false,
						});
					} else {
						this.setState({
							errorMessage: responseSiren.errorMessage,
							isLoading: false,
						});
					}
					return;
				}

				// Fill form field
				this.autoCompleteForm(responseSiren, inputSirenValue);

			} catch (error: any) {
				console.error('An unexpected error occurred:', error);
				this.setState({
					errorMessage: 'Une erreur inattendue est survenue lors de la récupération des informations.',
					isLoading: false,
				});
			}
		}
	}


	/**
	 * AutoComplete for with Api Siren
	 * @param responseSiren
	 * @param inputSirenValue
	 * @private
	 */
	private autoCompleteForm(responseSiren: any, inputSirenValue: string): void
	{
		this.setState({
			formData: {
				...this.state.formData,
				siren: inputSirenValue,
				name: responseSiren.name || '',
				apeCode: responseSiren.apeCode || '',
				address: AddressModel.prepare(responseSiren.addressDto || {})
			},
		}, () => this.setState({ isLoading: false }));
	}

	/**
	 * Handle IsLoading with CallBack
	 * @param isLoading
	 */
	setIsLoading(isLoading: boolean): void
	{
		this.setState({ isLoading }, () => this.props.getComponentState(this.state));
	}
}