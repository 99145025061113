import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { ReactElement } from 'react';

export default class CollaboratorRoleEnum
{
	value: keyof typeof CollaboratorRoleEnum;
	label: string;
	css: { backgroundColor: string, color: string, icon?: ReactElement|null };

	constructor(value: keyof typeof CollaboratorRoleEnum, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement|null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static ROLE_OWNER = new CollaboratorRoleEnum(
		'ROLE_OWNER',
		'Propriétaire',
		{
			backgroundColor: CssVariableEnum['--color-orange-100'],
			color: CssVariableEnum['--color-orange-600'],
		},
	);
	static ROLE_LEGAL_NOTICE = new CollaboratorRoleEnum(
		'ROLE_LEGAL_NOTICE', 'Annoncier',
		{
			backgroundColor: CssVariableEnum['--color-purple-200'],
			color: CssVariableEnum['--color-purple-700'],
		},
	);
	static ROLE_ACCOUNTANT = new CollaboratorRoleEnum(
		'ROLE_ACCOUNTANT',
		'Comptable',
		{
			backgroundColor: CssVariableEnum['--color-purple-200'],
			color: CssVariableEnum['--color-purple-700'],
		},
	);
	static ROLE_BUSINESS = new CollaboratorRoleEnum(
		'ROLE_BUSINESS',
		'Commercial',
		{
			backgroundColor: CssVariableEnum['--color-purple-100'],
			color: CssVariableEnum['--color-purple-600'],
		},
	);

	static roleOptions: CollaboratorRoleEnum[] = [
		CollaboratorRoleEnum.ROLE_OWNER,
		CollaboratorRoleEnum.ROLE_LEGAL_NOTICE,
		CollaboratorRoleEnum.ROLE_ACCOUNTANT,
		CollaboratorRoleEnum.ROLE_BUSINESS,
	];

	static roleOptionsWithoutOwner: CollaboratorRoleEnum[] = [
		CollaboratorRoleEnum.ROLE_LEGAL_NOTICE,
		CollaboratorRoleEnum.ROLE_ACCOUNTANT,
		CollaboratorRoleEnum.ROLE_BUSINESS,
	];

	static findByValue(value: string): CollaboratorRoleEnum | undefined
	{
		return this.roleOptions.find(option => option.value === value);
	}
}
