import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const TogglableMenuStyles = {
	container: {
		position: 'relative',
		zIndex: 2000,
	} as CSSProperties,

	menuContainer: (isOpen: boolean, menuPosition: CSSProperties) => ({
		position: 'absolute',
		...menuPosition,
		visibility: (isOpen) ? 'visible' : 'hidden',
		right: 0,
		backgroundColor: '#fff',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#ccc',
		padding: 10,
		zIndex: 1,
		borderRadius: 10,
		opacity: isOpen ? 1 : 0,
		transform: isOpen ? 'scale(1)' : 'scale(0.95)',
		transition: 'opacity 270ms ease, transform 270ms ease',
	}) as CSSProperties,

	li: {
		listStyle: 'none',
		padding: '5px 10px',
		cursor: 'pointer',
	} as CSSProperties,

	hoveredItem: {
		color: colors.blueRibbon500,
		backgroundHoveredColor: colors.blueRibbon100,
	} as CSSProperties,
} as const;
