import { CSSProperties } from 'react';

export const LabelStyles = {
	container: {

	} as CSSProperties,

	text: {
		fontWeight: 600,
		lineHeight: '20px',
		WebkitFontSmoothing: 'antialiased'
	} as CSSProperties,
} as const;