import React, { ReactElement } from 'react';
import { ViewLegalNoticeState } from '@/Modules/LegalNotice/Admin/View/CreateLegalNoticeView';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { LegalNoticeRenderStyle } from '@/Modules/LegalNotice/Style/LegalNoticeRenderStyle';
import FormattedContent from '@/Modules/App/Components/FormattedContentHtmlComposent';

interface ComponentProps
{
	legalNotice: ViewLegalNoticeState | null,
	consumer?: ConsumerDataInterface | null,
	isShow: boolean
}

interface ComponentState
{
	asideWidth: number
}

export default class LegalNoticeRender extends React.Component<ComponentProps, ComponentState>
{
	state = {
		asideWidth: 500
	};

	render(): ReactElement
	{
		const legalNotice = this.props.legalNotice;

		return (
			<>
				<aside style={ LegalNoticeRenderStyle.mainContainerStyle() }>
					<div style={ LegalNoticeRenderStyle.contentContainerStyle() }>

						{ legalNotice?.option.isLogo &&
              <>
                <div style={ { display: 'flex', justifyContent: 'center' } }>
                  <img style={ { width: '250px' } } src={ legalNotice.logo } alt={ legalNotice?.id + '-logo' }/>
                </div>
                <div style={ { display: 'flex', justifyContent: 'center', marginBottom: '10px' } }>
                  <hr style={ LegalNoticeRenderStyle.borderStyle() }/>
                </div>
              </>
						}
						{ legalNotice?.option.isHeader && this.headerContent(this.props.consumer) }
						<div style={ { textAlign: 'center', fontWeight: 'bold', overflowWrap: 'break-word' } }>
							{ legalNotice?.title }
						</div>
						{
							(legalNotice?.title !== '' && legalNotice?.title !== undefined) &&
              <div style={ { display: 'flex', justifyContent: 'center', marginBottom: '10px' } }>
                <hr style={ LegalNoticeRenderStyle.borderStyle() }/>
              </div>
						}
						<div lang={ 'fr' } style={ {
							textAlign: 'justify',
							hyphens: 'auto',
							msHyphens: 'auto',
							WebkitHyphens: 'auto',
							overflowWrap: 'break-word',
							wordSpacing: '-1px'
						} }>
							<FormattedContent content={ legalNotice?.content }/>
						</div>
						<div style={ { textAlign: 'end', overflowWrap: 'break-word' } }>
							{ legalNotice?.signature }
						</div>
					</div>
					<div style={ LegalNoticeRenderStyle.renderStaticMessage() }>
						Cet aperçu est donné à titre purement indicatif.
						Il ne reflète pas forcément la composition de l’annonce telle qu’elle sera publiée.
					</div>
				</aside>
			</>
		);
	}

	private headerContent(consumerData: any): ReactElement
	{
		return (
			<>
				<div style={ { textAlign: 'center' } }>
					<div><b>{ consumerData.name }</b></div>
					<div>{ consumerData.legalStatus }</div>
					<div>au capital de { consumerData.capital } €</div>
					<div>
						siège social: { consumerData.address.number ? `${ consumerData.address.number }, ` : '' }
						{ consumerData.address.street }
					</div>
					{ (consumerData.address.additionalData) &&
            <div>{ consumerData.address.additionalData }</div>
					}
					<div>{ consumerData.address.zipCode } { consumerData.address.city }</div>
					{ (consumerData.siren && consumerData.rcs) &&
            <div>{ consumerData.siren } RCS { consumerData.rcs }</div>
					}
				</div>
				<div style={ { display: 'flex', justifyContent: 'center', marginBottom: '10px' } }>
					<hr
						style={ { borderWidth: '2px', borderColor: '#000', width: '150px', margin: '10px 0 0 0', opacity: '1' } }
					/>
				</div>
			</>
		);
	}
}