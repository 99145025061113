import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';

export class ApiClientService extends BaseService
{
  clientUrl: string = `${ BaseService.API_URL }/v1/clients`;

  async list(): Promise<any>
  {
    try {
      const response = await fetch(
        this.clientUrl,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async show(clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(clientModel: ClientModel): Promise<any>
  {
    try {
      const response = await fetch(
        this.clientUrl,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(clientModel),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}