import React, { ReactElement, ChangeEvent } from 'react';
import Input from '@/Modules/App/Components/Library/Input/Input';
import SelectComponentOld from '@/Modules/App/Components/Atom/Form/Select/SelectComponentOld';
import PaymentMethodEnum from '@/Modules/Payment/Enum/PaymentMethodEnum';
import { LuEuro } from 'react-icons/lu';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { ApiPaymentService } from '@/Service/Api/ApiPaymentService';
import TagEnumOld from '@/Modules/App/Components/Atom/Tags/TagEnumOld';
import { PaymentSellsyStatusEnum } from '@/Modules/Payment/Enum/PaymentSellsyStatusEnum';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { Alert } from 'react-bootstrap';
import { ModalContextType } from '@/Provider/ModalProvider';

interface ComponentProps
{
  modalContext: ModalContextType,
  legalNotice: LegalNoticeInterface,
  onCreate: (responsePaiement: any) => any,
  billing: any
}

interface ComponentState
{
  formData: {
    amount: string|null,
    totalRemaining: number|null,
    paymentMethod: string|null,
    note: string|null,
    invoiceId: number
  },
  billingAmount: {
    totalExclVat: number|null,
    totalInclVat: number|null,
    totalAfterDiscount: number|null,
    status: string|null
  },
  isLoading: boolean,
  errorMessage: string|null
}

export default class CreatePaymentFormComponent extends React.Component<ComponentProps, ComponentState>
{
  adminSellsyService: ApiAdminSellsyService;
  apiPaymentService: ApiPaymentService;

  constructor(props: any)
  {
    super(props);

    // Service
    this.adminSellsyService = new ApiAdminSellsyService();
    this.apiPaymentService = new ApiPaymentService();

    // Bind
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    // State
    this.state = this.initState();
  }

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return (
        <>
          <div style={{ height: 250, display: 'flex', justifyContent: 'center' }}>
            <LoaderComponent/>
          </div>
        </>
      );
    }

    return (
      <>
        <div style={ { padding: '0 20px', width: '100%' } }>
          <div>
            <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: 10 }}>Détails de la facture: </div>
            <div>
              { this.lineDetails('Prix HT', `${this.state.billingAmount.totalExclVat} €`) }
              { this.lineDetails('Prix TTC', `${this.state.billingAmount.totalInclVat} €`) }
              { this.lineDetails('Total restant', `${this.state.billingAmount.totalAfterDiscount} €`) }
              { this.lineDetails('Statut', this.state.billingAmount.status) }
              <hr/>
            </div>
          </div>

          { this.state.billingAmount.status?.toUpperCase() === PaymentStateEnum.PAID.value &&
            <>
              <Alert>Facture payée</Alert>
            </>
          }

          { this.state.billingAmount.status?.toUpperCase() !== PaymentStateEnum.PAID.value &&
            <>
              <div style={ { display: 'flex', gap: '10px', marginTop: '10px', marginBottom: '15px' } }>
                <SelectComponentOld
                  label={ 'Choisir un moyen de paiement' }
                  key={ 'client-type' }
                  buttonWidth={ 180 }
                  selectMenuWidth={ 200 }
                  listOptions={ PaymentMethodEnum.optionsForm }
                  onSelectedOption={ (event: any) => this.onChangePaymentMethod(event) }
                  renderOptionLabel={ (option) => `${ option.label }` }
                />
                <Input
                  type="number"
                  style={ { display: 'flex' } }
                  label="Montant du réglement"
                  name="amount-payment"
                  onChange={ this.onChangeAmount }
                />
              </div>
              { this.state.errorMessage && (
                <div style={ { color: 'red', marginBottom: '15px' } }>
                  { this.state.errorMessage }
                </div>
              ) }

              <div style={ { marginTop: '10px', marginBottom: '15px' } }>
                <Input
                  type="text"
                  style={ { display: 'flex' } }
                  label="Note"
                  name="note-payment"
                  onChange={ this.onChangeNote }
                />
              </div>

              <div style={ { display: 'flex', justifyContent: 'end' } }>
                <ButtonOld
                  iconLeft={ <LuEuro/> }
                  type={ 'success' }
                  style={ { marginBottom: 10 } }
                  onClick={ this.onFormSubmit }
                  disabled={ this.state.errorMessage !== null }
                >
                  Créer un paiement
                </ButtonOld>
              </div>
            </>
          }
        </div>
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  async componentDidMount(): Promise<any>
  {
    // Loading
    this.setState({ isLoading: true });

    const getBilling = await this.adminSellsyService.getInvoice(
      this.props.legalNotice?.company?.id as number,
      this.props.billing.extSellsyId
    );

    if (getBilling) {
      this.setState({
        billingAmount: {
          totalExclVat: getBilling.amounts.total_excl_tax,
          totalInclVat: getBilling.amounts.total_incl_tax,
          totalAfterDiscount: getBilling.amounts.total_remaining_due_incl_tax,
          status: getBilling.status
        },
        formData: {
          ...this.state.formData,
          totalRemaining: getBilling.amounts.total_remaining_due_incl_tax
        },
        isLoading: false
      });
    }

    this.props.modalContext.isLoadingFull(false);
  }

  private onChangeAmount(event: ChangeEvent<HTMLInputElement>): void
  {
    const amount = event.target.value;
    let errorMessage: string | null = null;

    if (parseInt(amount) > (this.state.billingAmount.totalAfterDiscount || 0)) {
      errorMessage = 'Le montant ne peut pas dépasser le total restant.';
    }

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        amount
      },
      errorMessage
    }));
  }

  private onChangePaymentMethod(event: any): void
  {
    const paymentMethod = event.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        paymentMethod
      }
    }));
  }

  private onChangeNote(event: ChangeEvent<HTMLInputElement>): void
  {
    const note = event.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        note
      }
    }));
  }

  private async onFormSubmit(): Promise<any>
  {
    this.props.modalContext.isLoadingFull(true);

    const responsePayment = await this.apiPaymentService.createPayment(
      this.props.legalNotice?.id as number,
      this.props.legalNotice?.company?.id as number,
      this.state.formData
    );

    this.props.onCreate(responsePayment);
  }

  private initState(): ComponentState
  {
    return {
      formData: {
        amount: null,
        totalRemaining: null,
        paymentMethod: null,
        note: null,
        invoiceId: this.props.billing.extSellsyId
      },
      billingAmount: {
        totalExclVat: null,
        totalInclVat: null,
        totalAfterDiscount: null,
        status: null
      },
      isLoading: false,
      errorMessage: null
    }
  }

  private lineDetails(label: string, data: string | number | null, splitColumn: boolean = true): ReactElement
  {
    return (
      <>
        <div style={ { display: 'grid', gridTemplateColumns: '150px auto', gap: 40 } }>
          <div style={ {
            width: '100%',
            ...FontStyle.littleGrey()
          } }>
            { label }
          </div>
          <div style={ {
            width: '100%',
            ...FontStyle.normalMedium()
          } }>
            { (label === 'Statut') ? this.invoiceStatusRender() : data }
          </div>
        </div>
      </>
    );
  }

  private invoiceStatusRender(): ReactElement
  {
    const normalizedValue = this.state.billingAmount?.status?.toUpperCase();
    const statusEnum = PaymentSellsyStatusEnum.findByValue(normalizedValue as string);

    if (!statusEnum) {
      return <>{ this.state.billingAmount?.status }</>;
    }

    return (
      <>
        <TagEnumOld
          value={ statusEnum.value }
          enumName={'PaymentSellsyStatusEnum'}
        >
          { statusEnum.label }
        </TagEnumOld>
      </>
    )
  }

  //</editor-fold>
}
