import React, { Component, ReactElement } from 'react';
import { TableHeadColumnsInterface, TableProps, TableState } from './Table.interface';
import { TableService } from './Table.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { TableStyles } from '@/Modules/App/Components/Library/Table/Table.styles';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { formatDateToFR } from '@/Utils/DateUtils';
import { LuArrowDown, LuArrowUp } from 'react-icons/lu';

class Table extends Component<TableProps, TableState>
{
	private tableService = new TableService();

	constructor(props: TableProps)
	{
		super(props);

		// Config service
		this.tableService.setProps(this.props);
		this.tableService.subscribeState(this);

		// State
		this.state = this.tableService.getState();
	}

	async componentDidMount(): Promise<void>
	{
		await this.tableService.init();
	}

	componentWillUnmount(): void
	{
		this.tableService.unsubscribe();
	}

	/**
	 * Function to access a nested property
	 * @param object - The object in which to search
	 * @param path - The key string, separated by dots (e.g., 'address.city')
	 * @returns The found value or undefined if not found
	 */
	getNestedValue = (object: any, path: string): any =>
	{
		return path.split('.').reduce((acc, part) => acc && acc[part], object);
	}

	/**
	 * Handle row click and provide row data.
	 * @param item - The data for the row that was clicked
	 */
	onRowClick = (item: any, event?: any) =>
	{
		this.props.onRowClick(item, event);
	}

	renderCellContent = (item: any, column: TableHeadColumnsInterface): React.ReactNode =>
	{
		const cellValue = Array.isArray(column.keyTag)
			? column.keyTag.map((key: any) => this.getNestedValue(item, key)).join(' ')
			: this.getNestedValue(item, column.keyTag)
		;

		if (column.keyType === 'date') {
			return formatDateToFR(cellValue);
		}

		if (column.keyType === 'extSellsyId') {
			return (item.extSellsyId) ? <img src="/img/logo-sellsy.png" alt="logo Sellsy" width={ 25 }/> : '-';
		}

		if (column.keyType === 'boolean') {
			return (cellValue === true) ? 'Oui' : 'Non';
		}

		if (column.keyType === 'url') {
			return <img width="50" src={ cellValue } alt={ 'logo' }/>;
		}

		return column.enumClass ? (
			<Tag value={ cellValue } enumName={ column.enumClass }/>
		) : (
			cellValue
		);
	}

	render(): ReactElement
	{
		const { columns, list } = this.props;

		return (
			<table style={ TableStyles.table }>
				<thead style={ TableStyles.thead }>
				<tr style={ TableStyles.thead_tr }>
					{ columns.map((column) => (
						<th
							key={ column.title }
							style={ TableStyles.thead_th(column) }
							onClick={ (event: any) => this.tableService.onOrderBy(column.tag) }
						>
							<div style={ {
								...TableStyles.thead_th_div,
								justifyContent: (column.textAlign) ?? 'flex-start',
							} }>
								{ column.title }
								{ this.props.filterParams && this.props.filterParams.orderBy === column.tag && (
									<span style={ { marginLeft: 20 } }>
										{ this.props.filterParams.sort === 'asc' ? <LuArrowUp/> : <LuArrowDown/> }
									</span>
								) }
							</div>
						</th>
					)) }
				</tr>
				</thead>
				<tbody style={ TableStyles.tbody }>
				{ (list === undefined) || (list?.items && list?.items.length === 0) ? (
					<tr style={ {} }>
						<td style={ { padding: '10px 0' } }>
							Aucune donnée disponible
						</td>
					</tr>
				) : (
					list.items.map((item, rowIndex) => (
						<Hovered key={ rowIndex }>
							{ ({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
								<tr
									key={ rowIndex }
									ref={ ref }
									style={ TableStyles.tbody_tr(isHovered) }
									onMouseEnter={ onMouseEnter }
									onMouseLeave={ onMouseLeave }
									onClick={ (event: any) => this.onRowClick(item, event) }
								>
									{ columns.map((column) => (
										<td key={ column.title } style={ TableStyles.tbody_td }>
											<div style={ {
												...TableStyles.tbody_td_div,
												fontWeight: (column.fontWeight) ?? 400,
												textAlign: (column.textAlign) ?? 'left',
												...(column.enumClass) ? { padding: 0 } : {},
											} }>
												{ this.renderCellContent(item, column) }
											</div>
										</td>
									)) }
								</tr>
							) }
						</Hovered>
					))
				) }
				</tbody>
			</table>
		);
	}
}

export default withGlobalContext(Table);
