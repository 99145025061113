import { CSSProperties } from 'react';

export const _GridStyles = {

	_2col: (widthFirstCol: string = 'auto', widthSecondCol: string = 'auto') => ({
		display: 'grid',
		gridTemplateColumns: `${widthFirstCol} ${widthSecondCol}`,
		gap: 20,
		alignItems: 'start',
	}) as CSSProperties,

	_3col: (widthFirstCol: string = 'auto', widthSecondCol: string = 'auto', widthThirdCol: string = 'auto') => ({
		display: 'grid',
		gridTemplateColumns: `${widthFirstCol} ${widthSecondCol} ${widthThirdCol}`,
		gap: 20,
		alignItems: 'start',
	}) as CSSProperties,

} as const;