import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { CSSProperties } from 'react';

export const FontStyles = {
	h1: {
		fontSize: 28,
		fontWeight: 700,
		lineHeight: '36px',
		letterSpacing: 'normal',
	} as CSSProperties,

	h2: {
		fontSize: 21,
		fontWeight: 600,
		lineHeight: '30px',
		letterSpacing: 'normal',
	} as CSSProperties,

	h3: {
		fontSize: 18,
		fontWeight: 600,
		lineHeight: '26px',
		letterSpacing: 'normal',
	} as CSSProperties,

	h4: {
		fontSize: 15,
		fontWeight: 600,
		lineHeight: '22px',
		letterSpacing: 'normal',
	} as CSSProperties,

	h5: {
		fontSize: 13,
		fontWeight: 500,  // un peu plus léger que h4
		lineHeight: '20px',
		letterSpacing: 'normal',
	} as CSSProperties,

	h6: {
		fontSize: 11,
		fontWeight: 500,
		lineHeight: '18px',
		letterSpacing: 'normal',
	} as CSSProperties,

	text: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 1.3,
		WebkitFontSmoothing: 'antialiased'
	} as CSSProperties,

	textContrast: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 1.3,
		WebkitFontSmoothing: 'antialiased',
	} as const,

	textSmall: {
		fontSize: 12,
		fontWeight: 400,
		lineHeight: 1.2,
		color: colors.gray400
	} as CSSProperties,

	textHelp: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 1.2,
		color: colors.gray500
	} as CSSProperties,
} as const;