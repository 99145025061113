import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Library/Input/Input';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { LuGrip, LuDiff, LuBold, LuArrowUpWideNarrow, LuAlignLeft, LuAlignRight, LuAlignCenter } from 'react-icons/lu';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Textarea from '@/Modules/App/Components/Atom/Form/Textarea';
import {
	TextContentLogoInterface
} from '@/Modules/Client/Components/ClientLogos/Create/Form/ClientLogoFormCreate.interface';

interface ComponentProps
{
	textContent: TextContentLogoInterface
	onChange: (event: any) => void
	onChangeTextContentCss: (event: any, name: string, action: string) => void
}

interface ComponentState
{
	isFontSizeOpen: { [key: string]: boolean };
	isLineHeightOpen: { [key: string]: boolean };
	isMenuOpen: { [key: string]: boolean };
}


export default class CustomLogoFormComponent extends React.Component<ComponentProps, ComponentState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			isFontSizeOpen: {},
			isLineHeightOpen: {},
			isMenuOpen: {},
		};
	}

	render(): ReactElement
	{
		const { textContent, onChange } = this.props;

		return (
			<div style={ { display: 'flex', flexWrap: 'wrap', width: '100%', gap: 15 } }>
				<div style={ { display: 'flex', alignItems: 'flex-end', position: 'relative', width: '100%' } }>
					<Textarea
						label={ 'Nom' }
						name={ 'name' }
						value={ textContent.name.label || '' }
						onChange={ onChange }
						width={ '100%' }
					/>
					{ this.buttonOptionRender('name') }
				</div>
				<div style={ { display: 'flex', gap: 10, width: '100%' } }>
					<div style={ { display: 'flex', alignItems: 'flex-end', position: 'relative', width: '100%' } }>
						<Input
							label={ 'Infos' }
							name={ 'info' }
							type={ 'text' }
							value={ textContent.info.label || '' }
							onChange={ onChange }
							width={ '100%' }
						/>
						{ this.buttonOptionRender('info') }
					</div>
					<div style={ { display: 'flex', alignItems: 'flex-end', position: 'relative', width: '100%' } }>
						<Input
							label={ 'Fonction' }
							name={ 'status' }
							type={ 'text' }
							value={ textContent.status.label || '' }
							onChange={ onChange }
							width={ '100%' }
						/>
						{ this.buttonOptionRender('status') }
					</div>
				</div>
				<div style={ { display: 'flex', alignItems: 'flex-end', position: 'relative', width: '100%' } }>
					<Textarea
						label={ 'Adresse' }
						name={ 'address' }
						value={ textContent.address.label || '' }
						onChange={ onChange }
						width={ '100%' }
					/>
					{ this.buttonOptionRender('address') }
				</div>
				<div style={ { display: 'flex', alignItems: 'flex-end', position: 'relative', width: '100%' } }>
					<Input
						label={ 'Mail' }
						name={ 'email' }
						type={ 'text' }
						value={ textContent.email.label || '' }
						onChange={ onChange }
						width={ '100%' }
					/>
					{ this.buttonOptionRender('email') }
				</div>
				<div style={ { display: 'flex', gap: 10, width: '100%' } }>
					<div style={ { display: 'flex', alignItems: 'flex-end', position: 'relative', width: '100%' } }>
						<Input
							label={ 'Téléphone' }
							name={ 'phone' }
							type={ 'text' }
							value={ textContent.phone.label || '' }
							onChange={ onChange }
						/>
						{ this.buttonOptionRender('phone') }
					</div>
					<div style={ { display: 'flex', alignItems: 'flex-end', position: 'relative', width: '100%' } }>
						<Input
							label={ 'Site internet' }
							name={ 'website' }
							type={ 'text' }
							value={ textContent.website.label || '' }
							onChange={ onChange }
							width={ '100%' }
						/>
						{ this.buttonOptionRender('website') }
					</div>
				</div>
			</div>
		);
	}

	private handleToggleOptionMenu(name: string): void {
		this.setState((prevState) => ({
			isMenuOpen: {
				[name]: !prevState.isMenuOpen[name]
			},
			isFontSizeOpen: {},
		}));
	}


	private buttonOptionRender(name: string): ReactElement
	{
		return (
			<>
				<div style={ { position: 'relative' } }>
					<ButtonOld
						style={ { display: 'inline-flex' } }
						iconLeft={ <LuGrip/> }
						type={ 'default' }
						onClick={ (event: any) =>
						{
							event.stopPropagation();
							event.preventDefault();
							this.handleToggleOptionMenu(name);
						} }
					/>

					{ this.optionMenuRender(name) }
				</div>
			</>
		);
	}

	private optionMenuRender(name: string): ReactElement
	{
		const { textContent, onChangeTextContentCss } = this.props;
		return (
			<div style={ {
				position: 'absolute',
				display: (this.state.isMenuOpen[name]) ? 'block' : 'none',
				bottom: 35,
				right: 0,
				width: 250,
				padding: 5,
				backgroundColor: CssVariableEnum['--color-white'],
				border: `1px solid ${ CssVariableEnum['--color-grey-100'] }`,
				boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
				borderRadius: 8
			} }>
				<div style={ { position: 'relative', display: 'flex', gap: 3 } }>
					<ButtonOld
						iconRight={ <LuBold/> }
						type={ 'default' }
						onClick={ (event: any) =>
						{
							event.preventDefault();
							onChangeTextContentCss(event, name, 'fontWeight');
						} }
					/>
					<div style={ { position: 'relative' } }>
						<ButtonOld
							type={ 'default' }
							iconRight={ <LuDiff/> }
							onClick={ (event: any) =>
							{
								event.preventDefault();
								this.setState((prevState) => ({
									isFontSizeOpen: {
										...prevState.isFontSizeOpen,
										[name]: !prevState.isFontSizeOpen[name],
									},
									isLineHeightOpen: {},
								}));
							} }
						/>
						<div style={ {
							position: 'absolute',
							bottom: 35,
							left: 3,
							display: (this.state.isFontSizeOpen[name]) ? 'block' : 'none'
						} }>
							<Input
								type={ 'number' }
								width={ '60px' }
								name={ 'fontSize' }
								value={ textContent[name as keyof TextContentLogoInterface].css?.fontSize || '' }
								onChange={ (event: any) => onChangeTextContentCss(event, name, 'fontSize') }
							/>
						</div>
					</div>
					<div style={ { position: 'relative' } }>
						<ButtonOld
							type={ 'default' }
							iconRight={ <LuArrowUpWideNarrow/> }
							onClick={ (event: any) =>
							{
								event.preventDefault();
								this.setState((prevState) => ({
									isLineHeightOpen: {
										...prevState.isLineHeightOpen,
										[name]: !prevState.isLineHeightOpen[name],
									},
									isFontSizeOpen: {}
								}));
							} }
						/>
						<div style={ {
							position: 'absolute',
							bottom: 35,
							left: 3,
							display: (this.state.isLineHeightOpen[name]) ? 'block' : 'none'
						} }>
							<Input
								type={ 'number' }
								width={ '60px' }
								name={ 'lineHeight' }
								value={ textContent[name as keyof TextContentLogoInterface].css?.lineHeight || '' }
								onChange={ (event: any) => onChangeTextContentCss(event, name, 'lineHeight') }
							/>
						</div>
					</div>
					<ButtonOld
						iconRight={ <LuAlignLeft/> }
						type={ 'default' }
						onClick={ (event: any) =>
						{
							event.preventDefault();
							onChangeTextContentCss(event, name, 'textAlignLeft');
						} }
					/>
					<ButtonOld
						iconRight={ <LuAlignCenter/> }
						type={ 'default' }
						onClick={ (event: any) =>
						{
							event.preventDefault();
							onChangeTextContentCss(event, name, 'textAlignCenter');
						} }
					/>
					<ButtonOld
						iconRight={ <LuAlignRight/> }
						type={ 'default' }
						onClick={ (event: any) =>
						{
							event.preventDefault();
							onChangeTextContentCss(event, name, 'textAlignRight');
						} }
					/>
				</div>
			</div>
		);
	}

}