import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const AssociateUserStyles = {
	container: {} as CSSProperties,

	searchList: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%'
	} as CSSProperties,

	lastName: {
		fontWeight: 600
	} as CSSProperties,

	label: {
		fontWeight: 600
	} as CSSProperties,

	left: {
		display: 'flex',
		flexGrow: 1,
		gap: 5
	} as CSSProperties,

	right: {
		display: 'flex',
		gap: 5,
		color: colors.gray400
	} as CSSProperties,

	selectedUserContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4
	} as CSSProperties,

	selectedUser: {
		display: 'flex',
		alignItems: 'center',
		padding: 6,
		borderRadius: 8,
		justifyContent: 'space-between',
		...borderStyles,
	} as CSSProperties,

} as const;