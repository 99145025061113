import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutAdmin from '@/Modules/App/Components/Layout/LayoutAdmin';
import LayoutAuth from '@/Modules/App/Components/Layout/LayoutAuth';
import LayoutPublic from '@/Modules/App/Components/Layout/LayoutPublic';
import { LayoutWrapperProps } from '@/Modules/App/Type/LayoutWrapperTypes';
import { AuthContext } from '@/Provider/Auth/Auth.provider';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import LayoutDeveloper from '@/Modules/App/Components/Layout/LayoutDeveloper';

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ layoutType, ...props }) =>
{
	const navigate = useNavigate();
	const authContext = useContext(AuthContext) as AuthContextType;
	const flashMessageContext = useContext(FlashMessageContext) as FlashMessageContextType;

	switch (layoutType) {
		case 'developer':
			return (
				<LayoutDeveloper
					authContext={ authContext }
					flashMessageContext={ flashMessageContext }
					navigation={ navigate }
					{ ...props }
				/>
			);
		case 'admin':
			return (
				<LayoutAdmin { ...props } />
			);
		case 'auth':
			return <LayoutAuth navigation={ navigate } { ...props } />;
		case 'public':
			return <LayoutPublic navigation={ navigate } { ...props } />;
		default:
			return <LayoutAdmin { ...props } />;
	}
};

export default LayoutWrapper;
