import { CSSProperties } from 'react';

export const CollaboratorAdminFormCreateStyles = {
	container: {} as CSSProperties,
	modalContainer: {
		width: 600,
		padding: 20,
	} as CSSProperties,

	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20
	} as CSSProperties,
} as const;