import React, { ReactElement } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ApiPaymentService } from '@/Service/Api/ApiPaymentService';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { LuCheckCheck } from 'react-icons/lu';

interface PaymentCallbackProps
{
	paymentStatus: string;
	navigation: NavigateFunction;
}

export default class PaymentCallback extends React.Component
	<PaymentCallbackProps, any>
{
	paymentService: ApiPaymentService;

	constructor(props: any)
	{
		super(props);

		// Service
		this.paymentService = new ApiPaymentService();

		// Document title
		document.title = 'Publisur - Merci pour votre paiement';
	}

	async componentDidMount(): Promise<void>
	{
		const urlParams = new URLSearchParams(window.location.search);
		const requiredParams = [
			'mid', 'PayID', 'XID', 'TransID', 'refnr', 'Type', 'pt', 'CardHolder', 'card', 'PCNr', 'CCExpiry', 'CCBrand', 'Status', 'Code', 'Description', 'MAC'
		];
		// Check if all required params are present
		const allParamsPresent = requiredParams.every((param: string) => urlParams.has(param));

		if (!allParamsPresent) {
			this.props.navigation('/not-found'); // Redirect to not found page if parameters are missing
			return;
		}

		// Get the TransID from URL params
		const transId = urlParams.get('TransID');

		if (transId) {
			// Call payment route on API
			const response = await this.paymentService.paymentSuccess(parseInt(transId as string), urlParams, this.props.paymentStatus);
			console.log(response);
			// TODO : Handle the response !!
		}
	}

	render(): ReactElement
	{
		return (
			<div style={ { width: '100%', display: 'flex', top: 100, position: 'relative', justifyContent: 'center' } }>
				<div style={{ textAlign: 'center'}}>
					<div style={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
						<div style={ {
							width: 50,
							height: 50,
							borderRadius: '50%',
							backgroundColor: CssVariableEnum['--color-green-500'],
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						} }>
							<LuCheckCheck color={ CssVariableEnum['--color-white'] } size={ 25 }/>
						</div>
					</div>
					<div style={ { fontSize: 24, fontWeight: 600, color: CssVariableEnum['--color-green-500'] } }>Transaction
						confirmée
					</div>
					<div style={ { ...FontStyle.littleGrey(), marginBottom: 20 } }>
						Votre paiment a bien été effectué <br/>
						D'ici quelques minutes vous recevrez les documents associés sur l'email renseigné.
					</div>
					<ButtonOld
						label={ 'Quitter et fermer cette fenêtre' }
						type={ 'default-dark' }
						onClick={() => window.close() }
					/>
				</div>
			</div>
		);
	}
}
