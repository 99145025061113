import React, { ReactElement } from 'react';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import NotificationView from '@/Modules/Notifications/View/NotificationView';
import SidebarMenu from '@/Modules/App/Components/Menu/SidebarMenu/SidebarMenu';
import { LayoutProps, LayoutState } from '@/Modules/App/Components/Layout/Layout.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { LayoutStyles } from '@/Modules/App/Components/Layout/Layout.styles';
import TopBar from '@/Modules/App/Components/Menu/TopBarMenu/TopBar';
import AppService from '@/Service/Common/AppService';
import Sandbox from '@/Modules/App/Components/Sandbox/Sandbox';
import Content from '@/Modules/App/Components/Content/Content';

class LayoutAdmin extends React.Component<LayoutProps, LayoutState>
{

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();
	}

	render(): ReactElement
	{
		if (!this.props.authContext?.user) {
			return <LoaderComponent height={ '100vh' }/>;
		}

		return (
			<>
				<div style={ LayoutStyles.container }>
					{ this.state.isSandBoxActive &&
            <Sandbox
              title={ 'Mode develop' }
              content={ 'Vous êtes actuellement sur un environnement local' }
              action={ {
								label: 'Allez sur Preprod',
								path: 'https://www.publisur.dev'
							} }
              handleActive={ this.handleActiveSandBox.bind(this) }
            />
					}
					<div style={ LayoutStyles.topBar }>
						<TopBar/>
					</div>
					<div style={ {
						...LayoutStyles.mainContainer,
						height: `calc(100% - 50px - ${ this.state.isSandBoxActive ? 44 : 0 }px)`
					} }>
						<SidebarMenu/>

						{/* Version */}
						<div style={{ position: 'absolute', bottom: 2, left: 250, zIndex: 4, fontSize: 11 }}>V1.5</div>

						{/* CONTENT OUTLET */ }
						<Content>
							{ this.props.children }
						</Content>
					</div>
				</div>
				{/* Notification View */ }
				<NotificationView
					isOpen={ this.state.isOpenNotificationView }
					handleOpen={ this.handleOpenNotificationView.bind(this) }
					notificationContext={ this.props.notificationContext! }
					navigation={ this.props.navigation }
				/>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">
	componentDidMount(): void
	{
		const { notificationContext } = this.props;
		const notifications = this.props.notificationContext?.getNotifications();

		if (notifications && notifications.length > 0) {
			const hasUnreadNotifications: boolean = notificationContext?.getNotifications()?.some(notification => !notification.isRead) ?? false;
			if (hasUnreadNotifications !== this.state.isNotification) {
				this.setState({ isNotification: hasUnreadNotifications });
			}
		}
	}

	componentDidUpdate(prevProps: LayoutProps): void
	{
		const notifications = this.props.notificationContext?.getNotifications();

		if ((notifications && notifications.length > 0)
			&& (prevProps.notificationContext?.getNotifications() !== notifications)) {
			const hasUnreadNotifications: boolean = notifications?.some(notification => !notification.isRead) ?? false;

			if (hasUnreadNotifications !== this.state.isNotification) {
				this.setState({ isNotification: hasUnreadNotifications });
			}
		}
	}

	private initState(): LayoutState
	{
		return {
			isAvatarCardOpen: false,
			isModalOpen: false,
			isThemeDark: true,
			isOpenNotificationView: false,
			isNotification: false,
			isSandBoxActive: AppService.getEnv('dev')
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private Method" defaultstate="collapsed">

	private handleOpenNotificationView(): void
	{
		this.setState(prevState => ({
			isOpenNotificationView: !prevState.isOpenNotificationView,
		}));
	}

	private handleActiveSandBox(isOpen: Boolean): void
	{
		this.setState({ isSandBoxActive: false });
	}

	//</editor-fold>
}

export default withGlobalContext(LayoutAdmin);