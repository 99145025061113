import React, { ReactElement } from 'react';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';
import { ApiAdminFormBuilderSectionService } from '@/Service/Admin/ApiAdminFormBuilderSectionService';
import { ApiAdminFormBuilderInputService } from '@/Service/Admin/ApiAdminFormBuilderInputService';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import TagEnumOld from '@/Modules/App/Components/Atom/Tags/TagEnumOld';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { LuTrash2, LuLock, LuUnlock } from 'react-icons/lu';
import { ModalContextType } from '@/Provider/ModalProvider';
import FormBuilderStyle from '@/Modules/App/Style/Components/FormBuilderStyle';
import FormBuilderEditSectionComponent from '@/Modules/FormBuilder/Components/FormBuilderEditSectionComponent';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import { FormBuilderOptionsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderOptionsInterface';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import Swal from 'sweetalert2';

interface ComponentProps
{
	section: FormBuilderSectionInterface,
	isLoading: boolean,
	refreshSections: (sectionId?: number) => Promise<FormBuilderSectionInterface[] | FormBuilderSectionInterface | undefined>,
	refreshInputs: (sectionId: number) => Promise<any>,
	isOpen: boolean,
	flashMessageContext: FlashMessageContextType,
	modalContext: ModalContextType,
	onCreateSection: () => Promise<void>,
	onUpdateSection: (section: FormBuilderSectionInterface) => void,
	onDeleteSection: (sectionId: number) => Promise<void>
	onCreateSubSection: (parentSectionId: number, formBuilderSection: Partial<FormBuilderSectionInterface>) => Promise<any>
	onCreateInput: (inputData: Partial<FormBuilderInputsInterface>, sectionId: number) => Promise<any>
	onUpdateInput: (editInputData: Partial<FormBuilderInputsInterface>, inputId: number) => Promise<any>
	onDeleteInput: (inputId: number, sectionId: number) => Promise<any>
	onCreateInputOption: (optionLabel: {
		label: string
	}, inputId: number) => Promise<FormBuilderOptionsInterface | null | undefined>
	onEditInputOption: (editInputOptionData: any, formBuilderInputId: number, formBuilderInputOptionId: number) => Promise<any>
	onDeleteInputOption: (inputId: number, optionId: number) => Promise<any>
}

interface ComponentState
{
	section: FormBuilderSectionInterface,
	isLoading: boolean,
}

export default class FormBuilderSectionComponent extends React.Component<ComponentProps, ComponentState>
{
	formBuilderSectionService: ApiAdminFormBuilderSectionService;
	formBuilderInputService: ApiAdminFormBuilderInputService;
	editSectionModal: ModalContextType;
	styles = FormBuilderStyle.section();

	constructor(props: ComponentProps)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.formBuilderSectionService = new ApiAdminFormBuilderSectionService();
		this.formBuilderInputService = new ApiAdminFormBuilderInputService();

		// Modal
		this.editSectionModal = this.props.modalContext;
	}

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderFullPageComponent/>;
		}

		const { section } = this.state;

		return (
			<div style={ FormBuilderStyle.section().container }>
				<div key={ section.id.toString() } style={ this.styles.cardGrid }>
					<div style={ this.styles.cardTitle }>{ section.title }</div>
					<TagEnumOld enumName={ 'PublishStateEnum' } value={ section.status }>
						{ PublishStateEnum.findByValue(section.status)?.label }
					</TagEnumOld>
					<div style={ this.styles.cardButtonAction }>
						<ButtonOld
							label={ 'Modifier' }
							type={ 'inline-default-blue' }
							onClick={ (event: any) => this.editSectionRender(event) }
							disabled={ this.state.section.isLocked }
						/>
						<ButtonOld
							type={ 'danger' }
							iconLeft={ <LuTrash2/> }
							onClick={ async () => await this.props.onDeleteSection(section.id) }
							disabled={ this.state.section.isLocked }
						/>
						<ButtonOld
							iconLeft={ (this.state.section.isLocked) ? <LuLock size={ 12 }/> : <LuUnlock size={ 12 }/> }
							type={ (this.state.section.isLocked) ? 'default-dark' : 'default' }
							onClick={ this.handleLockSection.bind(this) }
						/>
					</div>
				</div>
			</div>
		);
	}

	// <editor-fold desc="State / Component Mount" defaultstate="collapsed">

	componentDidUpdate(prevProps: ComponentProps): void
	{
		if (prevProps.section !== this.props.section) {
			this.setState({ section: this.props.section });
		}
	}

	componentDidMount(): void
	{
		this.setState({ section: this.props.section }, () => this.setState({ isLoading: false }));
	}

	private initState(): ComponentState
	{
		return {
			section: {} as FormBuilderSectionInterface,
			isLoading: true,
		};
	}

	// </editor-fold>

	// <editor-fold desc="Content Methods" defaultstate="collapsed">

	private editSectionRender(event: any): void
	{
		event.stopPropagation();

		this.editSectionModal.content(
			'Éditer la section',
			<FormBuilderEditSectionComponent
				key={ this.props.section.toString() }
				section={ this.state.section }
				refreshSections={ this.props.refreshSections }
				refreshInputs={ this.props.refreshInputs }
				isLoading={ this.props.isLoading }
				modalContext={ this.props.modalContext }
				onUpdateSection={ this.props.onUpdateSection }
				onDeleteSection={ this.props.onDeleteSection }
				onCreateSubSection={ this.props.onCreateSubSection }
				onCreateInput={ this.props.onCreateInput }
				onUpdateInput={ this.props.onUpdateInput }
				onDeleteInput={ this.props.onDeleteInput }
				onCreateInputOption={ this.props.onCreateInputOption }
				onEditInputOption={ this.props.onEditInputOption }
				onDeleteInputOption={ this.props.onDeleteInputOption }
				isOpenModal={ this.handleOpenModal.bind(this) }
			/>
		);
	}

	private handleOpenModal(isOpen: boolean): void
	{
		this.editSectionModal.isOpen(isOpen);
	}

	private handleLockSection(): void
	{
		if (this.state.section.isLocked) {
			// Affiche une confirmation avant de débloquer
			Swal.fire({
				title: 'Êtes-vous sûr ?',
				text: "Vous êtes sur le point de débloquer cette section. Tout évolution ou changement apporté pourront entrainer des problèmes de configuration",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Oui, débloquer !',
				cancelButtonText: 'Annuler'
			}).then((result: any) => {
				if (result.isConfirmed) {
					// Si confirmé, débloque la section
					this.setState((prevState: ComponentState) => ({
						section: {
							...prevState.section,
							isLocked: false
						}
					}), () => this.props.onUpdateSection(this.state.section));
				}
			});
		} else {
			// Si la section n'est pas verrouillée, elle sera verrouillée directement
			this.setState((prevState: ComponentState) => ({
				section: {
					...prevState.section,
					isLocked: true
				}
			}), () => this.props.onUpdateSection(this.state.section));
		}
	}


	// </editor-fold>
}
