import React, { ReactElement } from 'react';
import '../../../Assets/sass/Components/_dashboard-developer.scss';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import Button from '@/Modules/App/Components/Library/Button/Button.';

export default class DashboardDeveloperView extends React.Component
  <any, { responseCallTemp: any|null }>
{
  apiLegalNoticeFileService: ApiAdminLegalNoticeFileService;

  constructor(props: any)
  {
    super(props);

    this.state = {
      responseCallTemp: null
    };

    // Service
    this.apiLegalNoticeFileService = new ApiAdminLegalNoticeFileService();

    // Document title
    document.title = 'Publisur - DEVELOPER';
  }

  render(): ReactElement
  {
    return (
      <>
        <div className="dashboard-container">
          <h1 className="h1">
            <div className="pacman">
              <div className="pacman-body">
                <div className="pacman-mouth"></div>
              </div>
            </div>
            <div className="dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            👾 WELCOME DEVELOPER 😍
          </h1>
        </div>

        <hr/>

        <Button label={'Test Export XML'} variant={ 'primary' } onClick={ (event: any) => this.exportXmlZip(event) } />
      </>
    );
  }

  private async exportXmlZip(event: any): Promise<void>
  {
    try {
      const response = await fetch('http://publisur.api.local/v1/admin/legal-notice-files/export-xml', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${ localStorage.getItem('token') }`,
          'Accept': 'application/zip',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the ZIP file.');
      }

      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'export-legal-notice.zip';
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('An error occurred during the file download:', error);
    }
  }
}
