import React, { Component, ReactElement } from 'react';
import { TableControlsProps, TableControlsState } from './TableControls.interface';
import { TableControlsService } from './TableControls.service';
import { TableControlsStyles } from '@/Modules/App/Components/Library/TableControls/TableControls.styles';
import Input from '@/Modules/App/Components/Library/Input/Input';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import {
	FiltersInterface,
	OptionFilterInterface
} from '@/Modules/App/Components/Library/Table/Table.interface';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';
import Search from '@/Modules/App/Components/Library/Search/Search';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { BaseStyle } from '@/Style/BaseStyle';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

class TableControls extends Component<TableControlsProps, TableControlsState>
{
	private tableControlsService = new TableControlsService();

	constructor(props: TableControlsProps)
	{
		super(props);

		// Config service
		this.tableControlsService.setProps(this.props);
		this.tableControlsService.subscribeState(this);

		// State
		this.state = this.tableControlsService.getState();

		// Bind
		this.tableControlsService.filterSearch.bind(this);
		this.tableControlsService.onSelectFilterOption.bind(this);
		this.tableControlsService.onClearFilters.bind(this);
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.tableControlsService.init();
	}

	componentWillUnmount(): void
	{
		this.tableControlsService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const hasSelectedFilters = Object.keys(this.state.selectedFilters).length > 0;

		return (
			<div style={ TableControlsStyles.container }>
				<div style={ TableControlsStyles.research }>
					<Input
						type={ 'search' }
						name={ 'table-research' }
						placeholder={ 'Rechercher' }
						onChange={(event: any) => this.tableControlsService.onChangeTerm(event.target.value) }
					/>
				</div>
				<div style={ TableControlsStyles.filters }>
					{ this.props.filters && this.props.filters.map((filter: FiltersInterface, index: number) => (
						<ToggleOpenClosed isClickOutside={ true } key={ index }>
							{ ({ isOpen, menuRef, setIsOpen }) => (
								<>
									<Button
										variant={ 'filter' }
										iconName={ 'LuPlusCircle' }
										label={
											<span>
													{ filter.title }
													{this.state.selectedFilters[filter.tag] && (
														<>
															<span> | </span>
															<span style={{ color: colors.blueRibbon500 }}>
																{ `${ this.state.selectedFilters[filter.tag]?.displayValue }` }
															</span>
														</>
													) }
												</span>
										}
										onClick={ () => setIsOpen(true) }
									/>
									<div ref={ menuRef }>
										{ this.dispatcherTypeFilterRender(filter, isOpen, setIsOpen) }
									</div>
								</>
							)}
						</ToggleOpenClosed>
					)) }

					{ hasSelectedFilters &&
						<Button
							variant={ 'inline' }
							label={ 'Effacer les filtres' }
							onClick={ () => this.tableControlsService.onClearFilters() }
						/>
					}
				</div>
			</div>
		);
	}

	private dispatcherTypeFilterRender(filter: FiltersInterface, isOpen: boolean, setIsOpen: (isOpen: boolean) => void): ReactElement
	{
		return (
			<div style={ { ...BaseStyle.cardContainer(), ...TableControlsStyles.isFilterOpen(isOpen) }}>
				{filter.type === 'search' ? (
					<div>
						<div style={TableControlsStyles.filterTitle}>Rechercher par {filter.title}</div>
						<Search
							inputName={ filter.tag }
							searchService={ (params: any) => this.tableControlsService.filterSearch(filter.tag, params) }
							withResultList={ false }
							onSelectElement={ () => {} }
							renderResult={ () => <></> }
						/>
					</div>
				) : (
					<div>
						<div style={ TableControlsStyles.filterTitle }>Filtrer par { filter.title }</div>

						<Select
							listOptions={
								filter.options?.map((option, index) => ({
									...option,
									key: option.tag ?? index
								})) as OptionFilterInterface[]
							}
							onSelect={ (item: any) => {
								setIsOpen(false);
								this.tableControlsService.onSelectFilterOption(filter.tag, item)
							}}
							text="Choisir un filtre"
							isRequired={ false }
						/>
					</div>
				)}
			</div>
		);
	}
}

export default TableControls;