import React, { ReactElement } from 'react';
import { ApiAdminPricingService } from '@/Service/Admin/ApiAdminPricingService';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import BadgeComponent from '@/Modules/Pricing/Components/BadgeComponent';
import DepartmentListNoAffectComponent from '@/Modules/Pricing/Components/DepartmentListNoAffectComponent';
import { PricingTypeEnum } from '@/Enum/PricingTypeEnum';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Skeleton from 'react-loading-skeleton';

export default class PricingAdminCharacterPriceComponent extends React.Component
	<any, any>
{
	pricingService: ApiAdminPricingService;
	appService: ApiAppService;
	state = {
		departments: [],
		annexList: [],
		formData: [],
		annexDepartments: [],
		isEditMode: null,
		isLoading: true,
		searchTerm: '' as string | null,
	};

	constructor(props: any)
	{
		super(props);

		// Service
		this.pricingService = new ApiAdminPricingService();
		this.appService = new ApiAppService();

		// Bind
		this.renderDroppableContainers = this.renderDroppableContainers.bind(this);
		this.renderDroppableContainer = this.renderDroppableContainer.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.buildFormData = this.buildFormData.bind(this);
		this.editAnnex = this.editAnnex.bind(this);
		this.createAnnex = this.createAnnex.bind(this);
		this.filterDepartments = this.filterDepartments.bind(this);
		this.searchDepartments = this.searchDepartments.bind(this);
	}

	async componentDidMount()
	{
		await this.refreshDepartments();
	}

	async componentDidUpdate(prevPros: any, prevState: any)
	{
		if (prevState.searchTerm !== this.state.searchTerm) {
			await this.filterDepartments();
		}
	}

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				{ this.state.isLoading
					? <Skeleton style={ { marginTop: 25 } } width={ 250 } height={ 22 }/>
					: <Title type={ 'h4' } style={{ marginBottom: 0}}>
						Affectation des départements
					</Title>
				}
				<DragDropContext onDragEnd={ this.onDragEnd }>
					<DepartmentListNoAffectComponent
						departments={ this.state.departments }
						createAnnex={ this.createAnnex }
						searchDepartment={ this.searchDepartments }
						isLoading={ this.state.isLoading }
					/>
					{ this.renderDroppableContainers() }
				</DragDropContext>
			</>
		);
	}

	private searchDepartments(searchTerm: string): void
	{
		this.setState({ searchTerm });
	}

	private async filterDepartments(): Promise<void>
	{

		const freshDepartments = await this.appService.departmentList();
		this.setState({ departments: this.buildListDepartmentNoAffect(freshDepartments) }, () =>
		{
			if (this.state.searchTerm) {
				const lowerCaseSearchTerm: string = this.state.searchTerm.toLowerCase();
				const filteredDepartments: DepartmentInterface[] = this.state.departments.filter((department: DepartmentInterface) =>
					department.name.toLowerCase().includes(lowerCaseSearchTerm)
				);
				this.setState({ departments: filteredDepartments });
			}
		});
	}

	private renderDroppableContainers(): ReactElement
	{
		return (
			<>
				<div className="mt-4">
					<div className="row row-cols-1 row-cols-md-4 g-3">
						{ this.state.annexList.map((annex: any, index: number) => (
							<div className="col" key={ annex.id }>
								{ this.renderDroppableContainer(
									annex,
									this.state.annexDepartments[index],
									index
								) }
							</div>
						)) }
					</div>
				</div>
			</>
		);
	}

	private renderDroppableContainer(annex: any, departments: any[], index: number): ReactElement
	{
		return (
			<>
				<div style={ {
					backgroundColor: CssVariableEnum['--color-white'],
					padding: 10,
					border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
					borderRadius: 15
				} }>
					<div style={ { display: 'flex', alignItems: 'center' } }>
						<div style={ { width: '100%', display: 'flex', height: '32px' } }>
							<div style={ {
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							} }>
								<div style={ { fontSize: 16, fontWeight: 600, margin: '0 0 0 15px' } }>
									Annexe { annex.number }
								</div>
							</div>
						</div>
						<div style={ { display: 'flex', width: '100%', alignItems: 'center' } }>
							{ (this.state.isEditMode && this.state.isEditMode === annex.id)
								? <div>
									<input
										className="form-control form-control-sm"
										type="number"
										name="decimalInput"
										step="0.001"
										min="0"
										max="1"
										value={ this.state.formData[index] || '' }
										onChange={ (event) => this.handleInputEditAnnex(event, index) }
									/>
								</div>
								: <span className="">{ annex.price } €</span>
							}
						</div>
						<div style={ { display: 'flex' } }>
							<ButtonOld
								type="inline-default-blue"
								onClick={
									(this.state.isEditMode && this.state.isEditMode === annex.id)
										? () => this.editAnnex(annex, this.state.formData[index])
										: () => this.setState({ isEditMode: annex.id })
								}
							>
								{ (this.state.isEditMode && this.state.isEditMode === annex.id) ? 'Valider' : 'Modifier' }
							</ButtonOld>
						</div>
					</div>
					<div
						style={ {
							backgroundColor: CssVariableEnum['--color-white'],
							border: 'none',
							padding: 10,
							borderRadius: 15,
							minHeight: 200
						} }>
						<Droppable droppableId={ annex.id.toString() } direction="vertical">
							{ (provided, snapshot) => (
								<div
									ref={ provided.innerRef }
									{ ...provided.droppableProps }
									style={ {
										display: 'flex',
										flexWrap: 'wrap',
										gap: 6,
										backgroundColor: `${ snapshot.isDraggingOver ? CssVariableEnum['--color-grey-100'] : 'white' }`,
										borderRadius: 15,
										padding: 5,
										minHeight: snapshot.isDraggingOver ? '200px' : '',
									} }
								>
									{ departments.map((department: any, index: number) => (
										<Draggable key={ department.id } draggableId={ department.id.toString() } index={ index }>
											{ (provided, snapshot) => (
												<div
													ref={ provided.innerRef }
													{ ...provided.draggableProps }
													{ ...provided.dragHandleProps }
												>
													<BadgeComponent isDragging={ snapshot.isDragging } item={ department }/>
												</div>
											) }
										</Draggable>
									)) }
									{ provided.placeholder }
								</div>
							) }
						</Droppable>
					</div>
					<div
						style={ {
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							height: 40
						} }>
						<ButtonOld type="danger" onClick={ () => this.deleteAnnex(annex.id) }>
							supprimer
						</ButtonOld>
					</div>
				</div>
			</>
		);
	}

	private async onDragEnd(result: any)
	{
		if (!result.destination || !result.destination.droppableId) {
			return;
		}

		const editDepartmentData = {
			type: PricingTypeEnum.CHARACTER_PRICE,
			pricingAnnexId: (result.destination.droppableId === 'no-affect')
				? null
				: parseInt(result.destination.droppableId)
		};

		// Edit on API
		await this.pricingService.editDepartment(
			editDepartmentData,
			parseInt(result.draggableId)
		);

		// Refresh List Departments
		this.refreshDepartments();
	}

	//<editor-fold desc="Departments methods" defaultstate="collapsed">

	private async refreshDepartments()
	{
		this.setState({ isLoading: true });
		try {
			const freshDepartments = await this.appService.departmentList();
			const freshAnnexList = await this.pricingService.listAnnex(PricingTypeEnum.CHARACTER_PRICE);

			this.setState({
				departments: this.buildListDepartmentNoAffect(freshDepartments),
				annexList: freshAnnexList,
				annexDepartments: this.buildListDepartment(freshDepartments, freshAnnexList),
				formData: this.buildFormData(freshAnnexList),
				isLoading: false
			});
		} catch (error) {
			console.error('An error occurred while refreshing departments:', error);
			this.setState({ isLoading: false });
		}
	}

	private buildListDepartment(freshDepartments: [], freshAnnexList: []): any[]
	{
		const annexDepartments: any[] = [];
		freshAnnexList.forEach((annex: any) =>
		{
			const matchingDepartments = freshDepartments.filter(
				(department: any) => department.pricingAnnexCharacterPrice && department.pricingAnnexCharacterPrice.id === annex.id
			);

			if (matchingDepartments) {
				annexDepartments.push(matchingDepartments);
			}
		});

		return annexDepartments;
	}

	private buildListDepartmentNoAffect(freshDepartments: any[]): any[]
	{
		return freshDepartments.filter(department => !department.pricingAnnexCharacterPrice);
	}

	//</editor-fold>

	private buildFormData(freshAnnexList: any []): any[]
	{
		let formData: any[] = [];
		freshAnnexList.forEach((annex: any) =>
		{
			formData.push(annex.price);
		});

		return formData;
	}

	private handleInputEditAnnex(event: React.ChangeEvent<HTMLInputElement>, index: number): void
	{
		const newValue = event.target.value;

		this.setState((prevState: any) =>
		{
			const newFormData = [...prevState.formData];
			newFormData[index] = newValue;
			return { formData: newFormData };
		});
	}

	private createAnnex()
	{
		const newAnnex = {
			id: `new-${ Date.now() }`,
			number: this.state.annexList.length + 1,
			price: 0,
		};

		this.setState((prevState: any) => ({
			annexList: [...prevState.annexList, newAnnex],
			formData: [...prevState.formData, '0'],
			annexDepartments: [...prevState.annexDepartments, []],
			isEditMode: newAnnex.id,
		}));
	}

	private async editAnnex(annex: any, newValue: string)
	{
		if (typeof annex.id === 'string') {
			await this.pricingService.createAnnex(
				{ type: PricingTypeEnum.CHARACTER_PRICE, price: parseFloat(newValue) },
			);
		} else {
			await this.pricingService.editAnnex(
				{ type: PricingTypeEnum.CHARACTER_PRICE, price: parseFloat(newValue) },
				annex.id
			);
		}

		this.setState({ isEditMode: null });
		this.refreshDepartments();
	}

	private async deleteAnnex(annexId: number)
	{
		await this.pricingService.deleteAnnex(PricingTypeEnum.CHARACTER_PRICE, annexId);
		this.refreshDepartments();
	}
}