import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const CollaboratorShowStyles = {
  container: {} as CSSProperties,
  modalContainer: {
    width: 600,
    padding: 20,
  } as CSSProperties,

  sellsyContainer: {
    margin: '20px 0'
  } as CSSProperties,

  sellsyDetailsContainer: {
    width: '100%',
    backgroundColor: colors.purple50,
    padding: '5px 10px 10px 10px',
    marginBottom: 5,
    ...borderStyles,
    borderColor: colors.purple200,
    borderRadius: 8,
  } as CSSProperties,
} as const;