import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';

export default class AppService
{
	// Store the environment variable in the class
	private static ENV = process.env.REACT_APP_ENV;

	// Method to get the current environment
	public static getEnv(environment: 'dev' | 'production'): boolean
	{
		// Check if the current environment matches the provided one
		return this.ENV === environment;
	}

	// Method to check if the user has a specific role
	public static isUserRole(authContext: AuthContextType, role: UserRoleEnum): boolean
	{
		// Check if the user has the specified role
		return authContext.user?.role === role.value;
	}

	/**
	 * Check if user is SUPER ADMIN
	 * @param authContext
	 */
	public static isSuperAdmin(authContext: AuthContextType): Boolean
	{
		return authContext.user.role === UserRoleEnum.ROLE_SUPER_ADMIN.value;
	}

	/**
	 * Check if user is ADMIN
	 * @param authContext
	 */
	public static isAdmin(authContext: AuthContextType): Boolean
	{
		return authContext.user.role === UserRoleEnum.ROLE_ADMIN.value;
	}

}