import { ReactElement } from 'react';
import { border, CssVariableEnum } from '@/Enum/CssVariableEnum';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export default class PublishStateEnum
{
	value: string;
	label: string;
	css: {
		backgroundColor: string,
		border: string,
		color: string,
		icon?: ReactElement | null
	};

	constructor(value: string, label: string, css: { backgroundColor: string, border: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static DRAFT = new PublishStateEnum(
		'DRAFT',
		'Brouillon',
		{
			backgroundColor: CssVariableEnum['--color-yellow-100'],
			border: border(CssVariableEnum['--color-yellow-200']),
			color: CssVariableEnum['--color-yellow-400'],
		}
	);
	static PUBLISH = new PublishStateEnum(
		'PUBLISH',
		'Publié',
		{
			backgroundColor: CssVariableEnum['--color-green-100'],
			border: border(CssVariableEnum['--color-green-200']),
			color: CssVariableEnum['--color-green-500'],
		}
	);
	static PLANNED = new PublishStateEnum(
		'PLANNED',
		'Planifié',
		{
			backgroundColor: colors.purple100,
			border: border(colors.purple300),
			color: colors.purple700,
		}
	);
	static QUOTE = new PublishStateEnum(
		'QUOTE',
		'Devis',
		{
			backgroundColor: CssVariableEnum['--color-grey-100'],
			border: border(CssVariableEnum['--color-grey-200']),
			color: CssVariableEnum['--color-grey-500'],
		}
	);
	static CANCEL = new PublishStateEnum(
		'CANCEL',
		'Annuler',
		{
			backgroundColor: colors.error50,
			color: colors.error500,
			border: border(colors.error100)
		}
	);
	static ASK_USER_VALIDATE = new PublishStateEnum(
		'ASK_USER_VALIDATE',
		'Validation (User)',
		{
			backgroundColor: CssVariableEnum['--color-grey-100'],
			border: border(CssVariableEnum['--color-grey-200']),
			color: CssVariableEnum['--color-grey-800'],
		}
	);

	static ASK_PARTICULAR_VALIDATE = new PublishStateEnum(
		'ASK_PARTICULAR_VALIDATE',
		'Validation (Particulier)',
		{
			backgroundColor: CssVariableEnum['--color-error-100'],
			border: border(CssVariableEnum['--color-error-200']),
			color: CssVariableEnum['--color-error-500'],
		}
	);

	static options: PublishStateEnum[] = [
		PublishStateEnum.DRAFT,
		PublishStateEnum.PUBLISH,
		PublishStateEnum.PLANNED,
		PublishStateEnum.QUOTE,
		PublishStateEnum.CANCEL,
		PublishStateEnum.ASK_USER_VALIDATE,
		PublishStateEnum.ASK_PARTICULAR_VALIDATE,
	];

	static optionsFilter: OptionFilterInterface[] = [
		{ label: PublishStateEnum.DRAFT.label, tag: PublishStateEnum.DRAFT.value },
		{ label: PublishStateEnum.PUBLISH.label, tag: PublishStateEnum.PUBLISH.value },
		{ label: PublishStateEnum.PLANNED.label, tag: PublishStateEnum.PLANNED.value },
		{ label: PublishStateEnum.QUOTE.label, tag: PublishStateEnum.QUOTE.value },
		{ label: PublishStateEnum.CANCEL.label, tag: PublishStateEnum.CANCEL.value },
		{ label: PublishStateEnum.ASK_USER_VALIDATE.label, tag: PublishStateEnum.ASK_USER_VALIDATE.value },
		{ label: PublishStateEnum.ASK_PARTICULAR_VALIDATE.label, tag: PublishStateEnum.ASK_PARTICULAR_VALIDATE.value },
	];

	static findByValue(value: string): PublishStateEnum | undefined
	{
		return this.options.find(option => option.value === value);
	}
}