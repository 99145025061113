import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { DashboardAdminProps, DashboardAdminState } from '@/Modules/Dashboard/Admin/DashboardAdmin.interface';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import UserCivilityEnum from '@/Enum/UserCivilityEnum';

const initState: DashboardAdminState = {
  isLoading: false,
  countUsers: {
    active: 0,
    inactive: 0
  },
  countClients: {
    active: 0,
    inactive: 0
  },
  countLegalNotices: {
    all: 0,
    week: 0
  },
  chartData: [],
  userConnected: null as UserInterface|null
};

export class DashboardAdminService extends BaseComponentService<DashboardAdminProps, DashboardAdminState>
{
  appAdminService: ApiAdminAppService = new ApiAdminAppService();
  constructor()
  {
    super({} as DashboardAdminProps, initState);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    await this.fetchData(true);
    // Delay 1 sec
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }

  async fetchData(isComponentDidMount: boolean): Promise<void>
  {
    const responseCounters = await this.appAdminService.counters();
    const responseChart = await this.appAdminService.chartLegalNoticeExternIntern();

    // Set state
    this.setState({
      countUsers: {
        active: responseCounters.counterUsers,
        inactive: responseCounters.counterInactiveUsers
      },
      countClients: {
        active: responseCounters.counterClients,
        inactive: responseCounters.counterInactiveClients
      },
      countLegalNotices: {
        all: responseCounters.counterLegalNotices,
        week: responseCounters.counterLegalNoticesPublishCurrentWeek
      },
      chartData: responseChart.monthlyLegalNoticeData,
      userConnected: this.props.authContext?.user
    });
  }

  handleUserCivility(civility: string): string
  {
    if (civility !== UserCivilityEnum.M.value) {
      if (civility === UserCivilityEnum.W.value) {
        return 'Mme';
      } else {
        return '';
      }
    }

    return civility;
  }
}