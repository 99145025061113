import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { HoveredProps, HoveredState } from '@/Modules/App/Components/Library/Hovered/Hovered.interface';

const initState: HoveredState = {
	isHovered: false,
};

export class HoveredService extends BaseComponentService<HoveredProps<any>, HoveredState>
{
	constructor()
	{
		super({} as HoveredProps<any>, initState);

		// Bind
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}



	handleMouseEnter(): void
	{
		this.setState({ isHovered: true });
		if (this.props.onMouseEnter) {
			this.props.onMouseEnter();
		}
	}

	handleMouseLeave(): void
	{
		this.setState({ isHovered: false });
		if (this.props.onMouseLeave) {
			this.props.onMouseLeave();
		}
	}
}