import LocalStorageService from '@/Service/Common/LocalStorageService';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';

export class CollaboratorService
{

	static async isRoleOwner(): Promise<boolean>
	{
		const apiClientService: ApiClientService = new ApiClientService();
		const client: ClientInterface = await apiClientService.show(LocalStorageService.getAuthClient());
		const collaborator: CollaboratorInterface = client.collaborators.find((collaborator: CollaboratorInterface) => collaborator.user.id === LocalStorageService.getUser().userId)!;
		return Boolean(collaborator.role === CollaboratorRoleEnum.ROLE_OWNER.value);
	}
}