import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import {
  ClientOptionsProps, ClientOptionsSchema,
  ClientOptionsState
} from '@/Modules/Client/Components/ClientOptions/ClientOptions.interface';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';

const initState: ClientOptionsState = {
  isLoading: false,
  departments: [],
  newspapers: [],
  formData: {
    newspaperType: null,
    department: null,
    newspaper: {
      value: null,
      id: null,
      isDisabled: true
    },
    paymentMethod: null,
    billedTo: null,
    type: null,
    discountGranted: 0,
    discountPreference: null,
    commentary: null,
    numberOfCopies: 0,
    sendTo: null
  }
};

export class ClientOptionsService extends BaseComponentService<ClientOptionsProps, ClientOptionsState>
{
  private apiAppService: ApiAppService = new ApiAppService();
  private apiPublicService: ApiPublicService = new ApiPublicService();
  private apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

  constructor()
  {
    super({} as ClientOptionsProps, initState);

    // Bind
    this.onSelectDepartment = this.onSelectDepartment.bind(this);
  }

  /**
   * Initialize the service by setting the context list and name
   * @return Promise<void>
   */
  async init(): Promise<void>
  {
    this.setState({ isLoading: true });

    // Set the data with the existing data
    this.prepareFormData();

    // Handle departments list
    const departments = await this.getDepartments();
    this.setState({ departments }, () => {
      this.setState({ isLoading: false });
    });

  }

  async getDepartments(): Promise<DepartmentInterface[]>
  {
    return await this.apiAppService.departmentList();
  }

  async onSelectDepartment(department: DepartmentInterface, companyId: number): Promise<void>
  {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        department,
        newspaper: {
          ...prevState.formData.newspaper,
          isDisabled: Boolean(!this.state.formData.newspaperType && !this.state.formData.department)
        }
      }
    }));

    if (this.state.formData.newspaperType) {
      const newspapers = await this.getNewspapersByDepartmentCode(department, companyId);
      this.setState({ newspapers });
    }
  }

  onSelectNewspaper(value: any): void
  {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        newspaper: {
          ...prevState.formData.newspaper,
          value: value.name,
          id: value.id,
          isDisabled: Boolean(!this.state.formData.newspaperType && !this.state.formData.department)
        }
      }
    }));
  }

  async getNewspapersByDepartmentCode(department: DepartmentInterface, companyId: number): Promise<NewspaperInterface[]>
  {
    return await this.apiPublicService.newspaperByDepartmentAndNewspaperType(
      companyId,
      department.id,
      this.state.formData.newspaperType as string
    );
  }

  async refreshNewspapers(): Promise<void>
  {
    const { department, newspaperType } = this.state.formData;

    if (department && newspaperType) {
      const newspapers = await this.getNewspapersByDepartmentCode(department, this.props.client.company.id);
      this.setState({ newspapers });
    } else {
      this.setState({ newspapers: [] });
    }
  }

  async onUpdateOptions(currentState: ClientOptionsState): Promise<void>
  {
    const { newspaperType, department, newspaper, ...restOptions } = currentState.formData;

    // Convert newspaperType to string if it's an object
    const cleanedNewspaperType = (typeof newspaperType === 'object' && newspaperType !== null) ? newspaperType.value : newspaperType;
    // Extract departmentId from department object
    const departmentId = department && typeof department === 'object' ? department.id : department;
    // Extract newspaperId from newspaper object
    const newspaperId = newspaper && typeof newspaper === 'object' ? newspaper?.id : newspaper;

    const filteredOptions = {
      ...restOptions,
      newspaperType: cleanedNewspaperType,
      departmentId: departmentId,
      newspaperId: newspaperId
    };

    // Save with API (Edit client)
    this.apiAdminClientService.edit({ options: filteredOptions }, this.props.client.id);

    // Handle props
    this.props.onUpdateOptions();
  }

  onUpdateSendTo(selections: any): void
  {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        sendTo: selections
      }
    }));
  }

  /**
   * Method to handle state update for each select field.
   * @param key The key in the formData to update
   * @param value The value selected
   */
  handleSelectState(key: keyof ClientOptionsSchema, value: any): void
  {
    let normalizedValue = value;

    if (typeof value === 'object' && value?.label) {
      normalizedValue = value.value;
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [key]: normalizedValue,
        newspaper: {
          ...prevState.formData.newspaper,
          isDisabled: !(this.state.formData.department && this.state.formData.newspaperType),
        },
      },
    }), () => {
      if (key === 'department' || key === 'newspaperType') {
        this.refreshNewspapers();
      }
    });
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private prepareFormData(): void
  {
    const client = this.props.client;
    const clientOptions = client.options || {};
    const hasDepartment = clientOptions.department !== null && clientOptions.department !== undefined;
    const hasNewspaperType = clientOptions.newspaperType !== null && clientOptions.newspaperType !== undefined;

    this.setState({
      formData: {
        newspaperType: hasNewspaperType ? clientOptions.newspaperType : null,
        department: hasDepartment ? clientOptions.department : null,
        newspaper: {
          value: (hasNewspaperType && hasDepartment) ? clientOptions.newspaper?.name : null,
          id: (hasNewspaperType && hasDepartment) ? clientOptions.newspaper?.id : null,
          isDisabled: !(hasNewspaperType && hasDepartment)
        },
        paymentMethod: clientOptions.paymentMethod || null,
        billedTo: clientOptions.billedTo || null,
        type: clientOptions.type || null,
        discountGranted: clientOptions.discountGranted !== undefined ? clientOptions.discountGranted : 0,
        discountPreference: clientOptions.discountPreference || null,
        commentary: clientOptions.commentary || null,
        numberOfCopies: clientOptions.numberOfCopies !== undefined ? clientOptions.numberOfCopies : 0,
        sendTo: clientOptions.sendTo || null,
      }
    }, async () => {
      if (hasDepartment && hasNewspaperType) {
        const newspapers = await this.getNewspapersByDepartmentCode(clientOptions.department, this.props.client.company.id);
        this.setState({ newspapers });
      }
    });
  }

  //</editor-fold>
}
