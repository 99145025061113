import React, { ReactElement } from 'react';

export default class Separator extends React.Component
	<{
		style?: React.CSSProperties,
		height?: number,
		visibility: 'hidden' | 'visible'
	}, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					height: (this.props.height) ? this.props.height : '15px',
					flex: '0 0 auto'

				}}>
					<div style={{
						width: '100%',
						height: '1px',
						visibility: this.props.visibility,
						backgroundColor: 'rgba(55, 53, 47, 0.16)',
						...this.props.style
					}}></div>
				</div>
			</>
		);
	};
}