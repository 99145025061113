import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';

export class ApiAdminUserService extends BaseService
{
  userUrl: string = `${ BaseService.API_URL }/v1/admin/users`;

  async list(params?: string): Promise<any>
  {
    try {
      const url: string = params ? this.userUrl + '?' + params : this.userUrl;
      const response: Response = await fetch(
        url,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch user list');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching user list: ', error);
      throw error;
    }
  }

  async search(term: string, role?: string): Promise<any>
  {
    try {
      const roleList = (role) ? `&role=${role}` : '';
      const url = `${this.userUrl}/search?term=${encodeURIComponent(term)}${roleList}` ;

      const response = await fetch(url, {
        method: HttpVerbEnum.GET,
        headers: this.buildHeaders(),
      });

      return await response.json();
    } catch (error: any) {
      console.error('Error searching users:', error.message);
    }
  }

  async show(userId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.userUrl }/${ userId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  /**
   * Switch current company to change work environment
   * @param userId
   * @param companyId
   */
  async switchCompany(userId: number, companyId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.userUrl }/${ userId }/${ companyId }`,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders()
        }
      );

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(formData: UserInterface): Promise<any>
  {
    try {
      const response: Response = await fetch(
        this.userUrl,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(formData),
        }
      );
      
      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async edit(userUpdateData: any, userId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.userUrl }/${ userId }`,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
          body: JSON.stringify(userUpdateData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async delete(userId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.userUrl }/${ userId }`,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}