import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SectionCollapsedProps, SectionCollapsedState } from './SectionCollapsed.interface';

const initState: SectionCollapsedState = {
	isCollapsed: true,
};

export class SectionCollapsedService extends BaseComponentService<SectionCollapsedProps, SectionCollapsedState>
{
	constructor()
	{
		super({} as SectionCollapsedProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		// Set the state with the value from props if it is provided
		this.setState({ isCollapsed: this.props.isCollapsed !== undefined ? this.props.isCollapsed : true });
	}
}