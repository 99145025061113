import React, { Component, ReactElement } from 'react';
import { SearchSellsyCompanyProps, SearchSellsyCompanyState } from './SearchSellsyCompany.interface';
import { SearchSellsyCompanyService } from './SearchSellsyCompany.service';
import Input from '@/Modules/App/Components/Library/Input/Input';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { _GridStyles } from '@/Modules/App/Style/Components/_Grid.styles';

class SearchSellsyCompany extends Component<SearchSellsyCompanyProps, SearchSellsyCompanyState>
{
	private searchSellsyCompanyService = new SearchSellsyCompanyService();

	constructor(props: SearchSellsyCompanyProps)
	{
		super(props);

		// Config service
		this.searchSellsyCompanyService.setProps(this.props);
		this.searchSellsyCompanyService.subscribeState(this);

		// State
		this.state = this.searchSellsyCompanyService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.searchSellsyCompanyService.init();
	}

	componentWillUnmount(): void
	{
		this.searchSellsyCompanyService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { searchTerm } = this.state;
		const isEmpty = Boolean(this.state.searchTerm?.length! < 1 || !this.state.searchTerm);
		return (
			<>
				<div style={ { ..._GridStyles._3col(), alignItems: 'flex-end' } }>
					<Input
						type={ 'text' }
						name={ 'search-sellsy-company' }
						textHelp={ 'Recherchez un client existant dans Sellsy. Si le client n’est pas trouvé, vous pouvez en créer un nouveau.' }
						value={ searchTerm || '' }
						onChange={ (event) => this.searchSellsyCompanyService.onChangeSearchSellsy(event) }
						required={ true }
					/>

					<Button
						label={ 'Rechercher' }
						variant={ 'sellsy' }
						onClick={ (event: any) => this.searchSellsyCompanyService.handleSearch(event) }
						disabled={ isEmpty }
					/>

					<Button
						label={ 'Créer un client' }
						variant={ 'outlineSellsy' }
						onClick={ (event: any) => this.searchSellsyCompanyService.handleCreate(event) }
						disabled={ isEmpty }
					/>
				</div>
			</>
		);
	}
}

export default SearchSellsyCompany;
