import React, { Component, ReactElement } from 'react';
import { LabelProps, LabelState } from './Label.interface';
import { LabelStyles } from '@/Modules/App/Components/Library/Label/Label.styles';

class Label extends Component<LabelProps, LabelState>
{
	render(): ReactElement
	{
		return (
			<label>
				<span style={ LabelStyles.text }>
					{ this.props.label } { this.props.isRequired && <span style={{ fontStyle: 'italic'}}>(obligatoire)</span>}
				</span>
			</label>
		);
	}
}

export default Label;