import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeDocumentsProps, LegalNoticeDocumentsState } from './LegalNoticeDocuments.interface';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import { HttpStatusCodeEnum } from '@/Enum/HttpStatusCode';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { ModalContextType } from '@/Provider/ModalProvider';

const initState: LegalNoticeDocumentsState = {
	sendBackSendTo: {},
	isSendMailLoading: false,
	progressBar: 0,
	legalNoticeFiles: [],
	isLoading: false
};

export class LegalNoticeDocumentsService extends BaseComponentService<LegalNoticeDocumentsProps, LegalNoticeDocumentsState>
{
	apiAdminLegalNoticeFileService: ApiAdminLegalNoticeFileService = new ApiAdminLegalNoticeFileService();
	private apiLegalNoticeService= new ApiAdminLegalNoticeService();

	constructor()
	{
		super({} as LegalNoticeDocumentsProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setLegalNoticeFiles();
		this.setState({ sendBackSendTo: this.props.legalNotice.sendTo });
	}

	/**
	 * Set LegalNotice Files
	 */
	setLegalNoticeFiles(): void
	{
		this.setState({
			legalNoticeFiles: this.props.legalNotice.legalNoticeFiles
		});
	}

	/**
	 *
	 * @param file
	 * @public
	 */
	async getFile(file: any): Promise<void>
	{
		if (file.extSellsyId || (this.props.legalNotice.extSupplierUid !== null && this.props.legalNotice.extSupplierUid.source === 'REGIEPRO')) {
			window.open(file.file, '_blank');
		} else {
			await this.apiAdminLegalNoticeFileService.viewFilePDF(this.props.legalNotice.id, file.type)
				.then(blob =>
				{
					const url = window.URL.createObjectURL(blob);
					window.open(url, '_blank');
				})
				.catch(error => console.error('Error downloading file:', error))
			;
		}
	}

	/**
	 * Handle Sendback email
	 * @param sendToData
	 */
	onSelectionMail(sendToData: any): void
	{
		this.setState({ sendBackSendTo: sendToData });
	}

	/**
	 * Send Back email from API
	 * @param event
	 * @param data
	 * @param modalContext
	 */
	async sendBackEmailApi(event: any, data: { label: string, value: string }, modalContext: ModalContextType, sendToData: any): Promise<any>
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState({isLoading: true });
		modalContext.isLoadingOverlay(true);

		try {
			this.setState({ isSendMailLoading: true }, async () =>
			{
				let progressProcess: number = 0;
				const progressInterval = setInterval(() =>
				{
					if (progressProcess < 95) {
						progressProcess += 5;
						this.setState({ progressBar: progressProcess });
					}
				}, 1000);

				try {
					const sendBackEmailCallApi = await this.apiLegalNoticeService.sendBackEmail(
						this.props.legalNotice.id,
						{
							type: data.value,
							sendTo: sendToData
						}
					);

					clearInterval(progressInterval);
					this.setState({ progressBar: 100 });
					modalContext.isOpen(false);

					if (sendBackEmailCallApi.statusCode === HttpStatusCodeEnum.HTTP_OK) {
						this.props.flashMessageContext?.flashMessage(sendBackEmailCallApi.message);
					}
				} catch (error) {
					console.error('Error sending email:', error);
				} finally {
					this.setState({ isSendMailLoading: false });
				}
			});
		} catch (error) {
			console.error(`An error occurred on ${this.constructor.name}:`, error);
		}
	}

	/**
	 *
	 * @param responsePayment
	 */
	handleCreatePayment(responsePayment: any): void
	{}
}