import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { UserDetailsProps, UserDetailsState } from '@/Modules/User/Components/Blocks/UserDetails/UserDetails.interface';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';

const initState: UserDetailsState = {
	formData: {} as UserInterface,
	isLoading: true,
};

export class UserDetailsService extends BaseComponentService<UserDetailsProps, UserDetailsState>
{
	private apiAdminUserService = new ApiAdminUserService();
	constructor()
	{
		super({} as UserDetailsProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ formData: this.props.user },
			() => this.setState({ isLoading: false})
		);
	}

	async handleEdit(currentState: UserDetailsState): Promise<void>
	{
		this.setState({ isLoading: true });
		try {

			const updatedUser = await this.apiAdminUserService.edit(currentState.formData, currentState.formData.id);

			if(updatedUser.errorMessage) {
				console.error('Something wrong with update User', updatedUser.errorMessage);
				return;
			}

			this.setState({ formData: currentState.formData });
			this.props.getComponentState!(this.state);

			this.setState({ isLoading: false });

		} catch (error) {
			console.error('Something wrong with update User', error);
			this.setState({ isLoading: false });
		}
	}

	async handleStatus(status: string): Promise<void>
	{
		// Init var
		const newStatus = status === ActiveStatusEnum.ACTIVE.value ? ActiveStatusEnum.INACTIVE.value : ActiveStatusEnum.ACTIVE.value;

		this.setState({ formData: { ...this.state.formData, status: newStatus }}, async () => {
			await this.handleEdit(this.state);
			this.props.getComponentState!(this.state);
		});

		// TODO : See why the flashMessage don't show anymore
		this.props.flashMessageContext?.flashMessage(
			'Le statut de l\'utilisateur a été mis a jour avec succés',
			'success'
		);
	}
}