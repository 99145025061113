import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { OrderByInterface, TableProps, TableState } from '@/Modules/App/Components/Library/Table/Table.interface';

const initState: TableState = {
	list: {} as PaginateInterface,
	orderBy: {} as OrderByInterface
};

export class TableService extends BaseComponentService<TableProps, TableState>
{
	constructor()
	{
		super({} as TableProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({
			list: this.props.list,
			orderBy: {} as OrderByInterface
		});
	}

	onOrderBy(columnTag: string): void
	{
		const { orderBy } = this.state;
		const isSameColumn = orderBy.tag === columnTag;

		// Type `newDirection` as `'asc' | 'desc' | null`
		const newDirection: 'asc' | 'desc' = isSameColumn
			? (orderBy.direction === 'asc' ? 'desc' : 'asc')
			: 'asc'
		;
		const orderByObj: OrderByInterface = { tag: columnTag, direction: newDirection };

		// Update state with the new direction and tag
		this.setState(
			{ orderBy: orderByObj },
			() => this.props.onOrderBy(orderByObj)
		);
	}
}