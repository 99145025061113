import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientLogoProps, ClientLogoState } from './ClientLogo.interface';

const initState: ClientLogoState = {};

export class ClientLogoService extends BaseComponentService<ClientLogoProps, ClientLogoState>
{
	constructor()
	{
		super({} as ClientLogoProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 * Download Logo Image
	 * @param logo
	 */
	downloadImage(logo: any): void
	{
		const link = document.createElement('a');
		link.href = logo.logoUrl;

		link.download = logo.name;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

}