import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import { App } from '@/App';

const root = ReactDOM.createRoot(
  document.getElementById('publisur-app') as HTMLElement
);

document.title = 'Publisur';

const app = new App();

root.render(<RouterProvider router={ app.router() } />);