import React, { Component, ReactElement } from 'react';
import { LegalNoticesService } from './LegalNotices.service';
import { LegalNoticesProps, LegalNoticesState } from '@/Modules/LegalNotice/Common/LegalNotices.interface';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class LegalNotices extends Component<LegalNoticesProps, LegalNoticesState>
{
	private legalNoticesService = new LegalNoticesService();

	constructor(props: LegalNoticesProps)
	{
		super(props);

		// Config service
		this.legalNoticesService.setProps(this.props);
		this.legalNoticesService.subscribeState(this);

		// State
		this.state = this.legalNoticesService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticesService.init('regular');
	}

	componentWillUnmount(): void
	{
		this.legalNoticesService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		// this.props.navigation('/legal-notices/create')

		return (
			<>
				<HeroSection
					title="Annonces légales"
					icon={ null }
					buttonElement={
						<Button
							label={ 'Ajouter une annonce légale' }
							variant={ 'primary' }
							iconName={ 'LuPlus' }
							onClick={ () => this.props.navigation('/legal-notices/create') }
						/>
					}
				/>

				<ListTableManager
					refreshList={ this.state.legalNoticeList }
					tag={ 'legal-notice' }
					tableColumns={ this.legalNoticesService.buildColumnHeader() }
					serviceList={ (params) => this.legalNoticesService.list({ type: 'regular', filterParams: params }) }
					filters={ this.legalNoticesService.buildFilters() }
					onRowClick={ (event: any, item: any) => this.props.navigation('/legal-notices/' + item.id) }
				/>
			</>
		);
	}
}

export default withGlobalContext(LegalNotices);