import React, { CSSProperties, ReactElement } from 'react';
import { Editor, EditorState, RichUtils, Modifier, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';

interface WysiwygProps {
  initialContent: string;
  onContentChange: (content: string) => void;
  style?: CSSProperties;
}

interface WysiwygState {
  editorState: EditorState;
}

export class Wysiwyg extends React.Component<WysiwygProps, WysiwygState> {
  constructor(props: WysiwygProps) {
    super(props);

    // Convert HTML content to ContentState
    const contentState = stateFromHTML(this.props.initialContent);
    const editorState = EditorState.createWithContent(contentState);
    this.state = { editorState };

    // Bind
    this.onChange = this.onChange.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.applyFormatting = this.applyFormatting.bind(this);
  }

  render(): ReactElement {
    return (
      <>
        {this.renderToolbar()}
        <div style={this.editorStyle()}>
          <div style={{ padding: '10px', ...this.props.style }}>
            <Editor
              editorState={this.state.editorState}
              handleKeyCommand={this.handleKeyCommand}
              handleReturn={this.handleReturn}
              onChange={this.onChange}
            />
          </div>
        </div>
      </>
    );
  }

  private renderToolbar(): ReactElement {
    return (
      <div style={{
        backgroundColor: CssVariableEnum['--color-grey-100'],
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        border: `1px solid ${CssVariableEnum['--color-grey-200']}`,
        marginBottom: '-1px',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
      }}>
        <ButtonOld
          type={'inline-default'}
          style={{ fontSize: 12 }}
          onMouseDown={(event) => {
            event.preventDefault();
            this.applyFormatting('BOLD');
          }}
        >
          Bold
        </ButtonOld>
        <ButtonOld
          type={'inline-default'}
          style={{ fontSize: 12 }}
          onMouseDown={(event) => {
            event.preventDefault();
            this.transformText((text: any) => text.toUpperCase());
          }}
        >
          AB
        </ButtonOld>
        <ButtonOld
          type={'inline-default'}
          style={{ fontSize: 12 }}
          onMouseDown={(event) => {
            event.preventDefault();
            this.transformText((text: any) => text.toLowerCase());
          }}
        >
          ab
        </ButtonOld>
      </div>
    );
  }

  private onChange(editorState: EditorState): void {
    this.setState({ editorState }, () => {
      const contentState = editorState.getCurrentContent();
      const rawContent = convertToRaw(contentState);
      const stringifiedContent = JSON.stringify(rawContent);
      this.props.onContentChange(stringifiedContent);
    });
  }

  private handleKeyCommand(command: string): 'handled' | 'not-handled' {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  private handleReturn(e: React.KeyboardEvent): 'handled' | 'not-handled' {
    if (e.shiftKey) {
      const { editorState } = this.state;
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const newContentState = Modifier.insertText(contentState, selectionState, '\n');
      this.onChange(EditorState.push(editorState, newContentState, 'insert-characters'));
      return 'handled';
    }
    return 'not-handled';
  }

  private applyFormatting(style: string): void {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
  }

  private transformText(transform: (text: string) => string): any {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();
      const blockWithTransformedText = Modifier.replaceText(
        contentState,
        selection,
        transform(contentState.getBlockForKey(startKey).getText().slice(startOffset, endOffset))
      );

      this.onChange(EditorState.push(editorState, blockWithTransformedText, 'change-inline-style'));
    }
  }

  private editorStyle(): CSSProperties {
    return {
      border: `1px solid ${CssVariableEnum['--color-grey-200']}`,
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      backgroundColor: CssVariableEnum['--color-grey-25'],
      overflow: 'scroll',
      overflowX: 'hidden',
      height: 'calc(100% - 45px)',
    };
  }
}
