import { CSSProperties } from 'react';

export const LoaderFullPageStyles = {
	container: {
		width: '100%',
		height: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	} as CSSProperties,
} as const;