import React, { ReactElement } from 'react';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import CardComponentProps from '@/Modules/App/Components/Atom/Card/Card.interface';

export class CardComponent extends React.Component<CardComponentProps, any>
{
	constructor(props: any)
	{
		super(props);

		// States
		this.state = {
			isHovered: false
		};
	}
	render(): ReactElement
	{
		const { children, onClick} = this.props;

		return (
			<div
				style={ CardStyles.cardContainer(this.state.isHovered) }
				onClick={ onClick }
				onMouseEnter={ () => this.setState({ isHovered: true }) }
				onMouseLeave={ () => this.setState({ isHovered: false }) }
			>
				{ children }
			</div>
		);
	}

};

export default CardComponent;
