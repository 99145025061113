import React, { Component, createRef, ReactElement } from 'react';
import { AssociateSellsyCompanyProps, AssociateSellsyCompanyState } from './AssociateSellsyCompany.interface';
import { AssociateSellsyCompanyService } from './AssociateSellsyCompany.service';
import { CompanySellsyInterface } from '@/Modules/Client/Interface/CompanySellsyInterface';
import {
	AssociateSellsyCompanyStyles
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.styles';
import SearchSellsyCompany from '@/Modules/App/Components/Sellsy/SearchSellsyCompany/SearchSellsyCompany';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { dateFormat } from '@/Utils/DateUtils';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import IsLoading from '@/Modules/App/Components/Loader/IsLoading/IsLoading';
import { colors, hexToRgba } from '@/Modules/App/Style/Variables/Colors.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class AssociateSellsyCompany extends Component<AssociateSellsyCompanyProps, AssociateSellsyCompanyState>
{
	private associateSellsyCompanyService = new AssociateSellsyCompanyService();
	private containerRef = createRef<HTMLDivElement>();

	constructor(props: AssociateSellsyCompanyProps)
	{
		super(props);

		// Config service
		this.associateSellsyCompanyService.setProps(this.props);
		this.associateSellsyCompanyService.subscribeState(this);

		// State
		this.state = this.associateSellsyCompanyService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.associateSellsyCompanyService.init();
		document.addEventListener(
			'mousedown',
			(event: any) => this.associateSellsyCompanyService.handleClickOutside(event, this.containerRef!)
		);
	}

	componentWillUnmount(): void
	{
		this.associateSellsyCompanyService.unsubscribe();
		document.addEventListener(
			'mousedown',
			(event: any) => this.associateSellsyCompanyService.handleClickOutside(event, this.containerRef!)
		);
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { selectedCompanySellsy, formData } = this.state;

		return (
			<>
				<div style={ AssociateSellsyCompanyStyles.container }>
					<IsLoading
						isLoading={ this.state.isLoadingSellsySearch! } overlayColor={ hexToRgba(colors.purple100, 0.8) }>
						<div style={ AssociateSellsyCompanyStyles.title }>
							Rechercher ce client sur Sellsy
						</div>
						<div style={ { display: 'flex', width: '100%' } }>
							{ ((selectedCompanySellsy && selectedCompanySellsy?.id) || (formData.isSellsyCreated)) ? (
								<div style={ AssociateSellsyCompanyStyles.selectedItemContainer }>
									<div>
										{ this.itemCompanyRender( selectedCompanySellsy!, formData.isSellsyCreated ) }
									</div>
									<Button
										label={ 'Annuler' }
										variant={ 'smallDark' }
										onClick={ this.associateSellsyCompanyService.handleCancelSelectedCompanySellsy }
									/>
								</div>
							) : (
								<div style={ { display: 'flex', alignItems: 'flex-end' } }>
									<SearchSellsyCompany
										createCompany={ this.associateSellsyCompanyService.handleCreateNewCompanySellsy }
										updateSearchResults={ this.associateSellsyCompanyService.searchCompanySellsy }
									/>
								</div>
							) }
						</div>
					</IsLoading>

					<div ref={ this.containerRef! } style={ { ...AssociateSellsyCompanyStyles.openList(this.state.isOpen) } }>
						{ (this.state.companySellsySearchResults?.length === 0)
							? (
								<div style={ FontStyles.h5 }>
									<span>Aucune fiche Sellsy trouvée</span>
								</div>
							) : (
								< div style={ AssociateSellsyCompanyStyles.results }>
									<div style={ AssociateSellsyCompanyStyles.resultTitle }>
										Nombre de résultat: { this.state.companySellsySearchResults?.length }
									</div>
									{ this.state.companySellsySearchResults?.map((companySellsy: CompanySellsyInterface, index: number) => (
										<Hovered key={ index }>
											{ ({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
												<div
													ref={ ref }
													onClick={ (event: any) => this.associateSellsyCompanyService.handleSelectedCompanySellsy(event, companySellsy) }
													style={ AssociateSellsyCompanyStyles.item(isHovered) }
													onMouseLeave={ onMouseLeave }
													onMouseEnter={ onMouseEnter }
												>
													{ this.itemCompanyRender(companySellsy) }
												</div>
											) }
										</Hovered>
									)) }
								</div>
							) }
					</div>
				</div>
			</>
		);
	}

	private itemCompanyRender(companySellsy: CompanySellsyInterface, isCreatedCompany?: boolean ): ReactElement
	{
		if (!isCreatedCompany) {
			return (
				<>
					<div style={ AssociateSellsyCompanyStyles.resultName }> { companySellsy?.name } </div>
					<div style={ AssociateSellsyCompanyStyles.resultInfo }>
						{ (companySellsy?.object.id) && <span> Id: { companySellsy?.object.id } </span> }
						{ (companySellsy?.created) &&
              <span> • Créé le: { dateFormat(companySellsy?.created) }</span> }
						{ (companySellsy?.email) && <span> • { companySellsy?.email } </span> }
					</div>
				</>
			);
		} else if (isCreatedCompany) {
			return (
				<div style={ AssociateSellsyCompanyStyles.createCompany }>
					<img src="/img/logo-sellsy.png" alt="logo Sellsy" width={ 25 }/>
					<span>Finalisez la création de votre client. L'enregistrement sera automatiquement effectuée dans Sellsy.</span>
				</div>
			);
		} else {
			return <></>;
		}
	}
}

export default withGlobalContext(AssociateSellsyCompany);