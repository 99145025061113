import React, { Component, ReactElement } from 'react';
import {
  ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState
} from '@/Modules/Client/Components/ClientLegalNoticeStats/ClientLegalNoticeStats.interface';
import Table from '@/Modules/App/Components/Library/Table/Table';
import {
  ClientLegalNoticeStatsService
} from '@/Modules/Client/Components/ClientLegalNoticeStats/ClientLegalNoticeStats.service';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import ClientLegalNoticeStatsForm
  from '@/Modules/Client/Components/ClientLegalNoticeStats/Form/ClientLegalNoticeStatsForm';
import { ModalContextType } from '@/Provider/ModalProvider';
import { ContentStyles } from '@/Modules/App/Components/Content/Content.styles';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class ClientLegalNoticeStats extends Component<ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState>
{
  private clientLegalNoticeStatsService = new ClientLegalNoticeStatsService();
  private clientLegalNoticeStatsModal: ModalContextType;

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.clientLegalNoticeStatsService.setProps(this.props);
    this.clientLegalNoticeStatsService.subscribeState(this);

    // Modal
    this.clientLegalNoticeStatsModal = this.props.modalContext!;

    // State
    this.state = this.clientLegalNoticeStatsService.getState();

    // Bind
    this.legalNoticeStatsModalRender = this.legalNoticeStatsModalRender.bind(this);
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.clientLegalNoticeStatsService.init();
  }

  componentWillUnmount(): void
  {
    this.clientLegalNoticeStatsService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <LoaderComponent />;
    }

    return (
      <>
        <div style={ { ...ContentStyles.sidebar_block_title, marginTop: 20 } }>
          <Title style={ { marginBottom: 0, marginTop: 0 } } type={ 'h6' }>Statistiques</Title>

          <Button
            variant={ 'inline' }
            label={ 'Ajouter' }
            iconName={ 'LuPlus' }
            onClick={ this.legalNoticeStatsModalRender }
          />
        </div>

        <Table
          columns={ this.clientLegalNoticeStatsService.buildColumnHeader() }
          list={ this.state.list }
          onOrderBy={ () => null }
          filterParams={ null }
          onRowClick={ () => {} }
        />
      </>
    );
  }

  private legalNoticeStatsModalRender(event: any): void
  {
    event.stopPropagation();

    this.clientLegalNoticeStatsModal.isOpen(true);
    this.clientLegalNoticeStatsModal.content(
      'Statistiques',
      <div style={ { width: '800px' } }>
        <ClientLegalNoticeStatsForm
          client={ this.props.client }
          onCreate={ async () => {
            await this.clientLegalNoticeStatsService.onCreateStat();
          }}
        />
      </div>
    );
  }
}

export default withGlobalContext(ClientLegalNoticeStats);