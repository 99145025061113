import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { ModalContextType } from '@/Provider/ModalProvider';

export interface CollaboratorAdminFormCreateInterface
{
}

export interface CollaboratorAdminFormCreateProps
{
	client: ClientInterface,
	modalContext: ModalContextType,
	getComponentState: (state?: CollaboratorAdminFormCreateState) => void
}

export interface CollaboratorAdminFormCreateState
{
	user: UserInterface | null,
	isUserEmail: boolean,
	role: keyof typeof CollaboratorRoleEnum | null,
	collaboratorMail: string,
	extSellsyId: string,
	errorMessage: string,
}

export function CollaboratorAdminFormCreateDefault(): CollaboratorAdminFormCreateState
{
	return {
		user: null,
		isUserEmail: true,
		collaboratorMail: '',
		role: null,
		extSellsyId: '',
		errorMessage: '',
	};
}