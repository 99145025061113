import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const themeColors = {
	primary: colors.blueRibbon500,
	primaryVariant: colors.blueRibbon600,
	secondary: colors.orange500,
	secondaryVariant: colors.orange400,

	background: colors.white,
	surface: colors.white,
	darkBackground: colors.gray900,

	textPrimary: colors.primary,
	textSecondary: colors.secondary,
	textPlaceholder: colors.placeholder,
	textInverted: colors.inverted,

	success: colors.success500,
	warning: colors.warning500,
	error: colors.error500,

	border: colors.gray200,
} as const;