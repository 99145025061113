import { ReactElement } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { border } from '@/Enum/CssVariableEnum';

export default class ActiveStatusEnum
{
	value: string;
	label: string;
	css: {
		backgroundColor: string,
		color: string,
		border?: string,
		icon?: ReactElement | null,
	};

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, border?: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static ACTIVE = new ActiveStatusEnum(
		'ACTIVE',
		'Actif',
		{
			backgroundColor: colors.success50,
			color: colors.success500,
			border: `1px solid ${ colors.success100 }`
		}
	);

	static INACTIVE = new ActiveStatusEnum(
		'INACTIVE',
		'Inactif',
		{
			backgroundColor: colors.error50,
			color: colors.error500,
			border: border(colors.error100)
		}
	);

	static roleOptions: ActiveStatusEnum[] = [
		ActiveStatusEnum.ACTIVE,
		ActiveStatusEnum.INACTIVE,
	];

	static optionsFilter: OptionFilterInterface[] = [
		{ label: ActiveStatusEnum.ACTIVE.label, tag: ActiveStatusEnum.ACTIVE.value },
		{ label: ActiveStatusEnum.INACTIVE.label, tag: ActiveStatusEnum.INACTIVE.value }
	];

	static findByValue(value: string): ActiveStatusEnum | undefined
	{
		return this.roleOptions.find(option => option.value === value);
	}
}
