import React, { Component, ReactElement } from 'react';
import { LayoutStyles } from '@/Modules/App/Components/Layout/Layout.styles';
import { dateHeader } from '@/Utils/DateUtils';
import { LuBell, LuMoon } from 'react-icons/lu';
import Logo from '@/Modules/App/Components/Logo/Logo';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Avatar from '@/Modules/App/Components/Avatar/Avatar';
import ContextSwitcher from '@/Modules/App/Components/ContextSwitcher/ContextSwitcher';
import { UserService } from '@/Modules/App/Services/User/User.service';
import { TopBarProps, TopBarState } from '@/Modules/App/Components/Menu/TopBarMenu/TopBar.interface';
import { TopBarService } from '@/Modules/App/Components/Menu/TopBarMenu/TopBar.service';

class TopBar extends Component<TopBarProps, TopBarState>
{
	topBarService = new TopBarService();

	constructor(props: any)
	{
		super(props);

		// Config
		this.topBarService.setProps(this.props);
		this.topBarService.subscribeState(this);

		// State
		this.state = this.topBarService.getState();
	}

	componentDidMount(): void
	{
	}

	componentWillUnmount(): void
	{
		this.topBarService.unsubscribe();
	}

	render(): ReactElement
	{
		const { authContext } = this.props;

		if (!authContext || !authContext.user) {
			return <LoaderComponent/>;
		}

		return (
			<div style={ LayoutStyles.topBarContainer }>
				<div style={ LayoutStyles.topBarLogoContainer }>
					<Logo width={ 100 } onClickPath={ '/admin' }/>
				</div>
				<div style={ LayoutStyles.topBarMenu }>
					<div>
						{ (UserService.isSuperAdmin() || UserService.isAdmin()) &&
              <div style={ LayoutStyles.topBarSwitcher }>
                <ContextSwitcher/>
              </div>
						}
					</div>

					<div style={ LayoutStyles.topBarNav }>
						{ dateHeader() }
						<div
							style={ LayoutStyles.topBarIcon }
							onClick={ () => this.topBarService.onSwitchTheme() }
						>
							<LuMoon/>
						</div>
						<div
							style={ LayoutStyles.topBarIcon }
							onClick={ () => this.topBarService.onNotification() }
						>
							<LuBell/>
							{
								// TODO : WARNING HANDLE NOTIFICATION TOPBAR
								this.state.isNotification &&
                <div style={ {
									position: 'absolute',
									zIndex: 100,
									top: 0,
									right: 0,
									backgroundColor: 'red',
									height: 10,
									width: 10,
									borderRadius: '50%',
								} }/>
							}
						</div>
						<Avatar/>
					</div>
				</div>
			</div>
		);
	}
}

export default withGlobalContext(TopBar);