import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { TopBarState } from '@/Modules/App/Components/Menu/TopBarMenu/TopBar.interface';

const initState: TopBarState = {
	isNotification: false,
	isLoading: false,
};

export class TopBarService extends BaseComponentService<any, any>
{
	constructor()
	{
		super({} as any, initState);
	}

	/**
	 *
	 */
	async init(): Promise<void>
	{}

	/**
	 *
	 */
	onSwitchTheme(): null
	{
		return null;
	}

	/**
	 *
	 */
	onNotification(): null
	{
		return null;
	}



}