import React from 'react';
import { useMatches, useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsComponent from '@/Modules/App/Components/BreadcrumbsComponent';
import Skeleton from 'react-loading-skeleton';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface BreadcrumbsWrapperProps {
  isLoading?: boolean;
}

const BreadcrumbsWrapper: React.FC<BreadcrumbsWrapperProps> = ({ isLoading }) => {
  const matches = useMatches();
  const params = useParams();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div style={{}}>
        {matches.map((match, index) => (
          <Skeleton key={index} height={18} width={100} baseColor={ CssVariableEnum['--color-grey-200'] }/>
        ))}
      </div>
    );
  }

  return <BreadcrumbsComponent matches={matches} navigation={navigate} params={params} />;
};

export default BreadcrumbsWrapper;
