import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiClientLegalNoticeStatsService extends BaseService
{
  clientUrl: string = `${ BaseService.API_URL }/v1/admin/clients/legalNotice/stats`;

  async list(clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(clientId: number, data: any): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(data),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}