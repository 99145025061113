import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	ToggleOpenClosedProps,
	ToggleOpenClosedState
} from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed.interface';
import { MouseEvent } from 'react';

const initState: ToggleOpenClosedState = {
	isClickOutside: false,
	isOpen: false,
	menuPosition: {},
};

export class ToggleOpenClosedService extends BaseComponentService<ToggleOpenClosedProps, ToggleOpenClosedState>
{
	constructor()
	{
		super({} as ToggleOpenClosedProps, initState);

		// Bind
		this.toggle = this.toggle.bind(this);
		this.close = this.close.bind(this);
		this.setIsOpen = this.setIsOpen.bind(this);
	}

	/**
	 * Handle Close element
	 * @return void
	 */
	close(): void
	{
		this.setState({ isOpen: false });
	}

	/**
	 * Handle Open Element
	 * @return void
	 */
	open(): void { this.setIsOpen(true); }

	/**
	 *
	 * @param isOpen
	 */
	setIsOpen(isOpen: boolean): void
	{
		this.setState({ isOpen });
	}

	/**
	 *
	 * @param isClickOutside
	 */
	setIsClickOutside(isClickOutside: boolean): void
	{
		this.setState({ isClickOutside });
	}

	/**
	 * Toggle the open/closed state
	 * @return void
	 */
	toggle(): void
	{
		this.setState((prevState: ToggleOpenClosedState) =>
		{
			return { isOpen: !prevState.isOpen };
		});
	}

	/**
	 * Handle Click Outside Element
	 * @param event
	 * @param containerRef
	 */
	handleClickOutside(event: MouseEvent, containerRef: React.RefObject<HTMLDivElement>): void
	{
		if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
			this.close();
		}
	}

	/**
	 * Function to calculate the best vertical position for the menu based on available space.
	 * Returns an object with either top or bottom values.
	 *
	 * Logic:
	 * - If there's enough space below the container, position the menu at `top: 0` relative to the container (i.e., open downward).
	 * - If there's not enough space below but enough space above, position the menu at `bottom: 0` relative to the container (i.e., open upward).
	 */
	calculateMenuPosition(menuRef: React.RefObject<HTMLDivElement>, containerRef: React.RefObject<HTMLDivElement>): { top?: number, bottom?: number } | null {
		if (containerRef.current && menuRef.current) {
			const containerRect = containerRef.current.getBoundingClientRect();
			const menuRect = menuRef.current.getBoundingClientRect();

			// Get the window's height
			const windowHeight = window.innerHeight;

			// Calculate the space available above and below the container
			const spaceAbove = containerRect.top; // Space available above the container
			const spaceBelow = windowHeight - containerRect.bottom; // Space available below the container

			// Default position: decide between top or bottom
			let position: { top?: number, bottom?: number } = {};

			// If there's enough space below, position the menu at top: 0 (i.e., below the container)
			if (spaceBelow >= menuRect.height) {
				position.top = 30;
			}
			// If not enough space below, but enough space above, position the menu at bottom: 0 (i.e., above the container)
			else if (spaceAbove >= menuRect.height) {
				position.bottom = 30;
			}

			return position; // Return the calculated position object
		}

		return null; // If refs are unavailable, return null
	}


}