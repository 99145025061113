import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	CollaboratorAdminSwitchOwnerProps,
	CollaboratorAdminSwitchOwnerState
} from './CollaboratorAdminSwitchOwner.interface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';

const initState: CollaboratorAdminSwitchOwnerState = {
	isLoading: true,
	collaboratorOwner: {} as CollaboratorInterface,
	newCollaboratorOwner: null,
};

export class CollaboratorAdminSwitchOwnerService extends BaseComponentService<CollaboratorAdminSwitchOwnerProps, CollaboratorAdminSwitchOwnerState>
{
	private apiAdminCollaboratorService: ApiAdminCollaboratorService = new ApiAdminCollaboratorService();

	constructor()
	{
		super({} as CollaboratorAdminSwitchOwnerProps, initState);

		// Bind
		this.handleSwitchCollaborator = this.handleSwitchCollaborator.bind(this);
		this.handleSelected = this.handleSelected.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.getCollaboratorOwner();
	}

	/**
	 * Get Collaborator owner from current client
	 */
	getCollaboratorOwner(): void
	{
		const collaboratorOwner = this.props.collaborators.find(
			(collaborator: CollaboratorInterface) => collaborator.role === CollaboratorRoleEnum.ROLE_OWNER.value
		);

		this.setState({
			collaboratorOwner
		}, () => this.setState({ isLoading: false }));
	}

	/**
	 * handle Switch Collaborator
	 * @param currentState
	 */
	async handleSwitchCollaborator(currentState: CollaboratorAdminSwitchOwnerState): Promise<void>
	{
		this.props.modalContext.isLoadingOverlay(true);

		await this.apiAdminCollaboratorService.switchOwner(
			{ oldOwnerId: currentState.collaboratorOwner.id, newOwnerId: currentState.newCollaboratorOwner?.id },
			this.props.clientId
		);

		this.props.modalContext.isOpen(false);
		this.props.modalContext.isLoadingOverlay(false);

		this.props.getComponentState(currentState);
	}

	/**
	 * Select new collaborator Owner
	 * @param collaborator
	 */
	handleSelected(collaborator: CollaboratorInterface): void
	{
		this.setState({
			newCollaboratorOwner: collaborator
		});
	}

	/**
	 * Build Collaborator list from current client
	 */
	buildListCollaborators(): any[]
	{
		const owner: CollaboratorInterface = this.state.collaboratorOwner;

		return this.props.collaborators.filter(
			(collaborator: CollaboratorInterface): boolean => collaborator.id !== owner.id
		);
	}
}