import React, { Component, ReactElement } from 'react';
import { LoaderFullPageProps, LoaderFullPageState } from './LoaderFullPage.interface';
import { ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { LoaderFullPageStyles } from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage.styles';

class LoaderFullPage extends Component<LoaderFullPageProps, LoaderFullPageState>
{

	constructor(props: LoaderFullPageProps)
	{
		super(props);

		// State
		this.state = { isLoading: true };
	}

	render(): ReactElement
	{
		const { isLoading } = this.state;

		return (
			<div style={ LoaderFullPageStyles.container }>
				{ isLoading && (
					<div style={ {} }>
						<ClipLoader
							color={ colors.gray400 }
							loading={ isLoading }
							size={ 35 }
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				) }
			</div>
		);
	}
}

export default LoaderFullPage;