import { Component, ReactElement } from 'react';
import { SandboxStyles } from '@/Modules/App/Components/Sandbox/Sandbox.styles';
import { SandboxProps } from '@/Modules/App/Components/Sandbox/Sandbox.interface';
import { Link } from 'react-router-dom';
import { LuArrowUpRight, LuX } from 'react-icons/lu';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';

class Sandbox extends Component<SandboxProps, { isHovered: boolean }>
{
	constructor(props: SandboxProps)
	{
		super(props);
		this.state = {
			isHovered: false,
		};

		// Bind methods
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
	}

	handleMouseEnter(): void
	{
		this.setState({ isHovered: true });
	}

	handleMouseLeave(): void
	{
		this.setState({ isHovered: false });
	}

	render(): ReactElement
	{
		const { content, title, action } = this.props;

		return (

			<ToggleOpenClosed forceIsOpen={true} isClickOutside={false}>
				{({ isOpen, close }) => (
					<div
						style={SandboxStyles.container(isOpen)}
						onMouseEnter={this.handleMouseEnter}
						onMouseLeave={this.handleMouseLeave}
					>
						<div style={SandboxStyles.title}>{title}</div>
						<div style={SandboxStyles.content}>{content}</div>
						{action && (
							<Hovered<HTMLAnchorElement>>
								{({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
									<Link
										to={action?.path}
										style={{
											...SandboxStyles.action,
											...(isHovered ? SandboxStyles.hoveredAction : {}),
										}}
										target={'_blank'}
										ref={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
									>
										{action?.label}
										<LuArrowUpRight />
									</Link>
								)}
							</Hovered>
						)}

						<ButtonOld
							type={'success'}
							iconLeft={<LuX />}
							onClick={() => {
								this.props.handleActive(isOpen);
								close();
							}}
							style={SandboxStyles.closedButton(isOpen)}
						/>
					</div>
				)}
			</ToggleOpenClosed>

		);
	}
}

export default Sandbox;
