import React, { ReactNode, useContext } from 'react';
import { AuthContext } from '@/Provider/Auth/Auth.provider';
import { AuthClientContext } from '@/Provider/AuthClient/AuthClient.provider';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { NotificationContext } from '@/Provider/NotificationProvider';
import { ModalContext } from '@/Provider/ModalProvider';
import { GlobalContext } from '@/Context/Global/Global.context';
import { useNavigate, useParams } from 'react-router-dom';
import { OffcanvaContext } from '@/Provider/OffcanvaProvider';

interface GlobalContextProps {
	children: ReactNode;
}

export const GlobalContextProvider: React.FC<GlobalContextProps> = ({ children }) => {
	const authContext = useContext(AuthContext);
	const authClientContext = useContext(AuthClientContext);
	const authCompanyContext = useContext(AuthCompanyContext);
	const flashMessageContext = useContext(FlashMessageContext);
	const notificationContext = useContext(NotificationContext);
	const modalContext = useContext(ModalContext);
	const offcanvaContext = useContext(OffcanvaContext);
	const navigation = useNavigate();
	const params = useParams();

	const contexts = {
		authContext,
		authClientContext,
		authCompanyContext,
		flashMessageContext,
		notificationContext,
		offcanvaContext,
		modalContext,
		navigation,
		params,
	};

	return (
		<GlobalContext.Provider value={ contexts }>
			{ children }
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => {
	const context = useContext(GlobalContext);
	if (!context) {
		throw new Error('useGlobalContext must be used within a GlobalContextProvider');
	}
	return context;
};
