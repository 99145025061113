import React, { ReactElement } from 'react';
import { OffCanvasComponentStyle } from '@/Modules/App/Style/OffCanvasComponentStyle';
import { LuChevronDown, LuChevronUp, LuChevronsRight } from 'react-icons/lu';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { ButtonType } from '@/Modules/App/Components/Atom/Button/Type/ButtonType';

export interface OffCanvasHeaderActionButtonInterface
{
	label: string,
	type: ButtonType,
	onClick: () => void,
}

interface ComponentProps
{
	onHide: () => void,
	isNavigateArrow: boolean,
	onPrevious?: () => void,
	onNext?: () => void,
	isSellsyId?: boolean,
	headerActionButton?: OffCanvasHeaderActionButtonInterface,
}

export default class OffCanvasHeaderActionsComponent extends React.Component
	<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<div style={ OffCanvasComponentStyle.headerActionsStyle() }>
				<div style={ { display: 'flex' } }>
					<ButtonOld
						type={ 'closed' }
						iconLeft={ <LuChevronsRight/> }
						onClick={ () => this.props.onHide() }
					/>

					{ this.props.isNavigateArrow &&
            <>
              <ButtonOld
                type={ 'closed' }
                iconLeft={ <LuChevronUp/> }
                onClick={ this.props.onPrevious }
              />
              <ButtonOld
                type={ 'closed' }
                iconLeft={ <LuChevronDown/> }
                onClick={ this.props.onNext }
              />
            </>
					}
					{ this.props.isSellsyId &&
            <div>
              <img
                style={ {
									height: '30px',
									marginLeft: 5,
									padding: '8px',
									borderRadius: '5px',
									backgroundColor: CssVariableEnum['--color-purple-200']
								} }
                src="/img/logo-sellsy.png" alt="Sellsy Logo"
              />
            </div>
					}
				</div>
				<div>
					{
						this.props?.headerActionButton &&
            <ButtonOld
              type={ this.props.headerActionButton.type }
              label={ this.props.headerActionButton.label }
              onClick={ this.props.headerActionButton.onClick }
            />
					}
				</div>
			</div>
		);
	}
}