import React, { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { timeSince } from '@/Utils/DateUtils';
import { LuX } from 'react-icons/lu';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';

export interface Notification
{
	id: number;
	title: string | null,
	message: string,
	type: 'success' | 'error' | 'info',
	isRead: boolean,
	isOpen: boolean,
	date: number,
	path: string | null,
}

export interface LegalNoticeFilePendingResponseInterface
{
	legalNoticeId: number;
	legalNoticeFile: string;
	isLoading: boolean;
}

export interface NotificationProviderState
{
	legalNoticeList: LegalNoticeInterface[],
	legalNoticeFilePendingResponse: LegalNoticeFilePendingResponseInterface[],
	notifications: Notification[],
	isFetching: boolean,
	key: number,
}

export interface NotificationContextType
{
	notification: (title: string | null, message: string, type: 'success' | 'error' | 'info', path: string | null) => void;
	setLegalNoticeFilePendingResponse: (dataObject: LegalNoticeFilePendingResponseInterface) => void;
	updateNotification: (notificationData: Partial<Notification>) => void;
	isRead: (notificationId: number, isRead: boolean) => void,
	allRead: (isRead: boolean) => void,
	getState: () => NotificationProviderState,
	removeNotification: (notificationId: number) => void,
	getNotifications: () => Notification[] | null,
	refreshNotifications: () => void,
}

export const NotificationContext = React.createContext<NotificationContextType | null>(null);

export default class NotificationProvider extends React.Component<any, NotificationProviderState>
{
	legalNoticeService: ApiAdminLegalNoticeService;

	constructor(props: any)
	{
		super(props);

		// Services
		this.legalNoticeService = new ApiAdminLegalNoticeService();

		// State
		this.state = {
			legalNoticeList: [] as LegalNoticeInterface[],
			legalNoticeFilePendingResponse: [] as LegalNoticeFilePendingResponseInterface[],
			notifications: [] as Notification[],
			isFetching: false,
			key: 0,
		};
	}

	render(): ReactElement
	{
		return (
			<NotificationContext.Provider value={ {
				notification: this.setNotification.bind(this),
				setLegalNoticeFilePendingResponse: this.setLegalNoticeFilePendingResponse.bind(this),
				updateNotification: this.updateNotification.bind(this),
				removeNotification: this.removeNotification.bind(this),
				isRead: this.isRead.bind(this),
				allRead: this.allRead.bind(this),
				getState: this.getState.bind(this),
				getNotifications: this.getNotifications.bind(this),
				refreshNotifications: this.refreshNotifications.bind(this),

			} }>

				{ this.props.children }

				{ this.notificationRender() }
			</NotificationContext.Provider>
		);
	}

	async componentDidMount(): Promise<void>
	{
		// try {
		// 	// Set LegalNotice Validate List
		// 	const legalNoticeToValidate = await this.legalNoticeService.specificList('validate');
		// 	this.setState({ legalNoticeList: legalNoticeToValidate.items });
		//
		// 	// Push inside localStorage
		// 	const items: { legalNoticeId: number }[] = [];
		// 	legalNoticeToValidate.items.map((legalNotice: LegalNoticeInterface) => {
		// 		items.push({ legalNoticeId: legalNotice.id });
		// 	});
		// 	localStorage.setItem('ValidateLegalNotice', JSON.stringify(items));
		//
		// } catch (error: any) {
		// 	console.log('Something Wrong with LegalNoticeList Notification Provider');
		// }
	}

	async componentDidUpdate(): Promise<void>
	{

	}

	private notificationRender(): ReactElement
	{
		return (
			<div style={ {
				position: 'relative',
				top: 50,
			} }>
				{ this.state.notifications && this.state.notifications.map((notification: Notification) => (
					<div
						key={ notification.id }
						style={ {
							position: 'absolute',
							top: 5,
							right: 5,
							width: 350,
							zIndex: 500,
							padding: '10px 15px',
							backgroundColor: CssVariableEnum['--color-grey-50'],
							border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
							borderRadius: 15,
							boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px',
							transform: notification.isOpen ? 'translateX(0)' : 'translateX(100%)',
							opacity: notification.isOpen ? 1 : 0,
							transition: 'transform 0.5s ease, opacity 0.5s ease',
						} }>
						<div
							style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 } }>
							<div style={ { fontSize: 12, fontWeight: 600 } }>{ notification.title }</div>
							<div
								style={ { fontSize: 12, fontWeight: 600, cursor: 'pointer' } }
								onClick={ () => this.closeNotification(notification.id) }
							>
								<LuX/>
							</div>
						</div>
						<div>
							<div
								style={ { fontSize: 12, lineHeight: '15px', marginBottom: 8 } }
								dangerouslySetInnerHTML={ { __html: notification.message } }
							/>
							<div style={ { ...FontStyle.littleGrey(), marginTop: 3 } }>
								{ timeSince(notification.date) }
							</div>
						</div>
					</div>
				)) }
			</div>
		);
	}

	private setLegalNoticeFilePendingResponse(dataObject: LegalNoticeFilePendingResponseInterface): void
	{
		this.setState((prevState: any) =>
		{
			const existingIndex = prevState.legalNoticeFilePendingResponse.findIndex(
				(response: LegalNoticeFilePendingResponseInterface) =>
					response.legalNoticeId === dataObject.legalNoticeId &&
					response.legalNoticeFile === dataObject.legalNoticeFile
			);

			let updatedList;
			if (existingIndex !== -1) {
				// Update the existing entry
				updatedList = prevState.legalNoticeFilePendingResponse.map((response: LegalNoticeFilePendingResponseInterface, index: number) =>
					index === existingIndex ? dataObject : response
				);
			} else {
				// Add the new entry
				updatedList = [...prevState.legalNoticeFilePendingResponse, dataObject];
			}

			return {
				legalNoticeFilePendingResponse: updatedList,
			};
		});
	}

	private updateNotification(notificationData: Partial<Notification>): void
	{
		this.setState((prevState: any) =>
		{
			const updatedNotifications = prevState.notifications.map((notification: Notification) =>
			{
				if (notification.id === notificationData.id) {
					return { ...notification, ...notificationData };
				}
				return notification;
			});

			localStorage.setItem('notifications', JSON.stringify(updatedNotifications));

			return {
				notifications: updatedNotifications,
			};
		});
	}

	private isRead(notificationId: number, isRead: boolean): void
	{
		this.setState((prevState: any) =>
		{
			const updatedNotifications = prevState.notifications.map((notification: Notification) =>
			{
				if (notification.id === notificationId) {
					return { ...notification, isRead: isRead };
				}
				return notification;
			});

			localStorage.setItem('notifications', JSON.stringify(updatedNotifications));

			return {
				notifications: updatedNotifications,
			};
		});
	}

	private allRead(isRead: boolean): void
	{
		this.setState((prevState: any) =>
		{
			const updatedNotifications = prevState.notifications.map((notification: Notification) =>
			{
				return { ...notification, isRead: isRead };
			});

			localStorage.setItem('notifications', JSON.stringify(updatedNotifications));

			return {
				notifications: updatedNotifications,
			};
		});
	}

	private setNotification(title: string | null, message: string, type: 'success' | 'error' | 'info', path: string | null): void
	{
		const newNotification: Notification = {
			id: Date.now(),
			title,
			message,
			type,
			isOpen: true,
			date: Date.now(),
			isRead: false,
			path: path || ''
		};
		this.setState((prevState: any) =>
		{
			const updatedNotifications = [...prevState.notifications, newNotification];
			localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
			return {
				notifications: updatedNotifications,
			};
		});

		setTimeout(() =>
		{
			this.closeNotification(newNotification.id);
		}, 5000);
	}

	private closeNotification(id: number): void
	{
		this.setState((prevState: any) =>
		{
			const updatedNotifications = prevState.notifications.map((notification: Notification) =>
				notification.id === id ? { ...notification, isOpen: false } : notification
			);
			localStorage.setItem('notifications', JSON.stringify(updatedNotifications)); // Save to localStorage
			return {
				notifications: updatedNotifications,
			};
		});
	}

	private removeNotification(id: number): void
	{
		this.setState((prevState: any) =>
		{
			const updatedNotifications = prevState.notifications.filter((n: Notification) => n.id !== id);
			localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
			return {
				notifications: updatedNotifications,
			};
		});
	}

	private getState(): NotificationProviderState
	{
		return this.state;
	}

	private getNotifications(): Notification[]
	{
		const notifications = localStorage.getItem('notifications');

		if (notifications) {
			try {
				const parsedNotifications = JSON.parse(notifications) as Notification[];
				parsedNotifications.sort((a, b) => b.date - a.date);
				return parsedNotifications;
			} catch (error) {
				console.error('Erreur lors du parsing des notifications:', error);
				return [];
			}
		}

		return [];
	}

	private refreshNotifications(): void
	{
		this.setState({
			notifications: this.getNotifications(),
		});
	}

}
