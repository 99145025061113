import { UserInterface } from '@/Modules/User/Interface/UserInterface';

export interface CreateUserFormProps
{
	isAdmin: boolean,
	onFormSubmit: (formData: UserInterface, event?: any) => void,
	getComponentState?: (createUserFormState: CreateUserFormState) => void,
	errorMessage?: string,
	allowedUserRole?: boolean,
	resetErrorMessage?: () => void
}

export interface CreateUserFormState
{
	formData: CreateUserFormSchema,
	errorMessage: string,
	isFormValid: boolean,
	isLoading: boolean,
}

export interface CreateUserFormSchema
{
	user: UserInterface,
	inviteCode: string | null
}

export function createDefaultUserForm(): CreateUserFormSchema
{
	return {
		user: {} as UserInterface,
		inviteCode: null
	};
}

