import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeContentProps, LegalNoticeContentState } from './LegalNoticeContent.interface';

const initState: LegalNoticeContentState = {};

export class LegalNoticeContentService extends BaseComponentService<LegalNoticeContentProps, LegalNoticeContentState>
{
	constructor()
	{
		super({} as LegalNoticeContentProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}
}