import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class DashboardAdminViewStyle
{
  static containerCardExport(): CSSProperties
  {
    return {
      display: 'flex',
      width: '450px',
      flexDirection: 'column',
      backgroundColor: CssVariableEnum['--color-white'],
      border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
      borderRadius: '6px',
      padding: '10px',
      marginTop: '10px'
    };
  }

  static containerCardCounter(): CSSProperties
  {
    return {
      display: 'flex',
      minWidth: '300px',
      width: '33%',
      justifyContent: 'space-between',
      flexDirection: 'column',
      backgroundColor: CssVariableEnum['--color-white'],
      border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
      borderRadius: '6px',
      padding: '10px'
    };
  }

  static bigNumberStats(): CSSProperties
  {
    return {
      fontSize: 46,
      fontWeight: 600,
      color: CssVariableEnum['--color-grey-900']
    }
  }

  static statsCompare(value: number): CSSProperties
  {
    let backgroundColor, color;

    if (value > 0) {
      backgroundColor = CssVariableEnum['--color-green-100'];
      color = CssVariableEnum['--color-green-500'];
    } else if (value < 0) {
      backgroundColor = CssVariableEnum['--color-error-100'];
      color = CssVariableEnum['--color-error-500'];
    } else {
      backgroundColor = CssVariableEnum['--color-yellow-100'];
      color = CssVariableEnum['--color-yellow-500'];
    }

    return {
      backgroundColor,
      color,
      fontWeight: 500,
      padding: '2px 10px',
      borderRadius: CssVariableEnum['--border-radius-main'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };
  }

  static textStatusActive(): CSSProperties
  {
    return {
      fontSize: 18,
      fontWeight: 600,
      color: CssVariableEnum['--color-grey-500'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end'
    };
  }
}