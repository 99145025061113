export default class LocalStorageService
{
  public static getToken()
  {
    return localStorage.getItem('token');
  }

  /**
   *
   * @return
   */
  public static getUser(): { exp: number, issueAt: number, userId: number, userRole: string }
  {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  }

  static setUser(user: any)
  {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public static getAuthCompanyId(): any
  {
    return localStorage.getItem('authCompanyId');
  }

  /**
   * Set Auth Company LocalStorage
   * @param companyId
   */
  public static setAuthCompany(companyId: number): void
  {
    localStorage.setItem('authCompanyId', companyId.toString());
  }

  public static getAuthClient(): any
  {
    return localStorage.getItem('authClientId');
  }

  public static setAuthClient(clientId: number): void
  {
    localStorage.setItem('authClientId', clientId.toString());
  }

  public static clearLocalStorage()
  {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('authClientId');
    localStorage.removeItem('authCompanyId');
  }
}