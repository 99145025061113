import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class ModalStyle
{

	static container(): CSSProperties
	{
		return {
			position: 'relative',
			minHeight: '150px',
			minWidth: '500px',
			maxWidth: '1200px',
			backgroundColor: 'white',
			borderRadius: '20px',
			border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
		};
	}

	static errorMessage(): CSSProperties
	{
		return {
			backgroundColor: CssVariableEnum['--color-error-100'],
			color: CssVariableEnum['--color-error-600'],
			position: 'relative',
			transitionProperty: 'opacity, transform',
			transitionDuration: '500ms',
			transitionTimingFunction: 'ease'
		};
	}
}