import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { PaginationProps, PaginationState } from './Pagination.interface';

const initState: PaginationState = {
	elementPerPage: 10,
	isLoading: false,
};

export class PaginationService extends BaseComponentService<PaginationProps, PaginationState>
{
	constructor()
	{
		super({} as PaginationProps, initState);

		// Bind
		this.selectedElementPerPage = this.selectedElementPerPage.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		const savedElementsPerPage = localStorage.getItem(`elementsPerPage_${this.props.tag}`);
		const elementsPerPage = savedElementsPerPage ? parseInt(savedElementsPerPage) : 10;
		this.setState({
			elementPerPage: elementsPerPage,
		});
	}

	/**
	 * Generates the list of options for pagination, specifying the number of elements per page.
	 */
	getPaginationOptions(): { elementPerPage: number; label: string }[]
	{
		return [
			{ elementPerPage: 10, label: '10 éléments' },
			{ elementPerPage: 25, label: '25 éléments' },
			{ elementPerPage: 50, label: '50 éléments' },
			{ elementPerPage: 100, label: '100 éléments' },
			{ elementPerPage: 250, label: '250 éléments' },
		];
	}

	/**
	 * Handles the selected number of elements per page.
	 * This method updates the state, stores the selection in localStorage,
	 * and calls the parent component's onItemsPerPageChange callback.
	 * @param numberOfElement - Object containing the selected number of elements per page
	 */
	selectedElementPerPage(numberOfElement: { elementPerPage: number }): void
	{
		this.setState({ elementPerPage: numberOfElement.elementPerPage, },
			() => {
				localStorage.setItem(`elementsPerPage_${this.props.tag}`, numberOfElement.elementPerPage.toString());
			}
		);
		this.props.onItemsPerPageChange!(numberOfElement.elementPerPage);
	}
}
