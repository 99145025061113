import React, { Component, ReactElement } from 'react';
import { CreateClientFormProps, CreateClientFormState } from './CreateClientForm.interface';
import { CreateClientFormService } from './CreateClientForm.service';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { _GridStyles } from '@/Modules/App/Style/Components/_Grid.styles';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import { CreateClientFormStyles } from '@/Modules/Client/Components/CreateClientForm/CreateClientForm.styles';
import { UserService } from '@/Modules/App/Services/User/User.service';
import AssociateSellsyCompany from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import AssociateUser from '@/Modules/User/Components/AssociateUser/AssociateUser';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { AssociateUserState } from '@/Modules/User/Components/AssociateUser/AssociateUser.interface';
import ErrorBanner from '@/Modules/App/Components/Error/ErrorBanner/ErrorBanner';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';

class CreateClientForm extends Component<CreateClientFormProps, CreateClientFormState>
{
	private createClientFormService = new CreateClientFormService();

	constructor(props: CreateClientFormProps)
	{
		super(props);

		// Config service
		this.createClientFormService.setProps(this.props);
		this.createClientFormService.subscribeState(this);

		// State
		this.state = this.createClientFormService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.createClientFormService.init();
	}

	componentWillUnmount(): void
	{
		this.createClientFormService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				{/* HANDLE ERRORS */}
				{ this.state.errorMessage &&
          <ErrorBanner
            key={ this.state.errorMessage }
            errorMessage={ this.state.errorMessage }
          />
				}

				{/* MODAL FORM */}
				<div style={ CreateClientFormStyles.modal }>
					<div style={ CreateClientFormStyles.container }>

						{ this.formInputsRender() }

						<AssociateUser
							getComponentState={
								(associateUserState: AssociateUserState) =>
								{
									this.createClientFormService.setUserData(associateUserState, this.state);
								}
							}
						/>

						{ (UserService.isSuperAdmin() || UserService.isAdmin()) &&
							// TODO: Kill GlobalContext Provider and Add ModalContextConsumer inside
              <GlobalContextProvider>
                <AssociateSellsyCompany
                  getComponentState={
										(companySellsyState: AssociateSellsyCompanyState) =>
											this.createClientFormService.setSellsyData(companySellsyState)
									}
                />
              </GlobalContextProvider>
						}
					</div>

					<div style={ ModalStyles.bottomActions }>
						<Button
							variant={ 'primary' }
							label={ 'Créer' }
							onClick={ (event: any) => this.createClientFormService.handleCreate(event, this.state) }
						/>
					</div>
				</div>
			</>
		);
	}

	private formInputsRender(): ReactElement
	{
		const { formData, showSiretInput } = this.state;
		const isDisabled: boolean = formData.siren.length !== 9;

		return (
			<>
				<div style={ CreateClientFormStyles.form }>
					<div style={ CreateClientFormStyles.siren }>
						<Input
							label={ 'Siren' }
							isLoading={ this.state.isLoading }
							textHelp={ 'Veuillez entrer un Siren valide pour remplir automatiquement les champs ci-dessous.' }
							type={ 'text' }
							name={ 'create-client-siren' }
							width={ '300px' }
							value={ formData.siren || '' }
							onChange={ (event) => this.createClientFormService.handleSirenEventField(event) }
							onPaste={ (event) => this.createClientFormService.handleSirenEventField(event) }
							allowedCharacters={ 'digits' }
							required={ true }
						/>

						{ showSiretInput && (
							<Input
								label={'SIRET'}
								type="text"
								name="siret"
								value={formData.siret || ''}
								onChange={(event) => handleChangeInput(event, this.setState.bind(this))}
								isLoading={this.state.isLoading}
								required={true}
							/>
						)}

					</div>
					<div style={ _GridStyles._3col('300px', '100px') }>
						<Input
							label={ 'Société' }
							type="text"
							name="name"
							value={ this.state.formData.name || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							isLoading={ this.state.isLoading }
							disabled={ isDisabled }
							required={ true }
						/>
						<Input
							label={ 'APE/NAF' }
							type="text"
							name="apeCode"
							value={ this.state.formData.apeCode || '' }
							disabled={ isDisabled }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							isLoading={ this.state.isLoading }
						/>
						<Input
							label={ 'Téléphone' }
							type="text"
							name="phone"
							disabled={ isDisabled }
							value={ this.state.formData.phone || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							allowedCharacters={ 'digits' }
							isLoading={ this.state.isLoading }
							required={ true }
						/>
					</div>

					<div style={ _GridStyles._2col('150px') }>
						<Input
							label={ 'Numéro' }
							type="number"
							name="address.number"
							disabled={ isDisabled }
							value={ this.state.formData.address.number != null ? this.state.formData.address.number : '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							required={ true }
							isLoading={ this.state.isLoading }
						/>
						<Input
							label={ 'Rue' }
							type="text"
							name="address.street"
							disabled={ isDisabled }
							value={ this.state.formData.address.street || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							required={ true }
							isLoading={ this.state.isLoading }
						/>
					</div>

					<Input
						label={ 'Complément' }
						type="text"
						style={ { display: 'flex' } }
						name="address.additionalData"
						disabled={ isDisabled }
						value={ this.state.formData.address.additionalData || '' }
						onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
						isLoading={ this.state.isLoading }
					/>

					<div style={ _GridStyles._3col() }>
						<Input
							label={ 'Code Postal' }
							width={ '100%' }
							type="text"
							name="address.zipCode"
							disabled={ isDisabled }
							value={ this.state.formData.address.zipCode || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							isLoading={ this.state.isLoading }
							allowedCharacters={ 'digits' }
							required={ true }
						/>
						<Input
							label={ 'Ville' }
							width={ '100%' }
							type="text"
							name="address.city"
							disabled={ isDisabled }
							value={ this.state.formData.address.city || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							isLoading={ this.state.isLoading }
							allowedCharacters={ 'letters' }
							required={ true }
						/>
						<Input
							label={ 'Pays' }
							width={ '100%' }
							type="text"
							name="address.country"
							disabled={ isDisabled }
							value={ this.state.formData.address.country || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							isLoading={ this.state.isLoading }
							allowedCharacters={ 'letters' }
							required={ true }
						/>
					</div>
				</div>
			</>
		);
	}
}

export default CreateClientForm;