import React, { Component, ReactElement } from 'react';
import {
	CollaboratorsAdminProps,
	CollaboratorsAdminState
} from '@/Modules/Collaborator/Admin/List/CollaboratorsAdmin.interface';
import { CollaboratorsAdminService } from '@/Modules/Collaborator/Admin/List/CollaboratorsAdmin.service';
import Table from '@/Modules/App/Components/Library/Table/Table';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalContext } from '@/Provider/ModalProvider';
import CollaboratorAdminFormCreate from '@/Modules/Collaborator/Components/Form/Create/CollaboratorAdminFormCreate';
import CollaboratorAdminSwitchOwner from '@/Modules/Collaborator/Components/SwitchOwner/CollaboratorAdminSwitchOwner';
import CollaboratorShow from '@/Modules/Collaborator/Admin/Show/CollaboratorShow';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { UserService } from '@/Modules/App/Services/User/User.service';
import CollaboratorInviteForm from '@/Modules/Collaborator/Components/Form/Invite/CollaboratorInviteForm';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';

class CollaboratorsAdmin extends Component<CollaboratorsAdminProps, CollaboratorsAdminState>
{
	private collaboratorsAdminService: CollaboratorsAdminService = new CollaboratorsAdminService();

	constructor(props: CollaboratorsAdminProps)
	{
		super(props);
		// Config service
		this.collaboratorsAdminService.setProps(this.props);
		this.collaboratorsAdminService.subscribeState(this);

		// State
		this.state = this.collaboratorsAdminService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.collaboratorsAdminService.init();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<ModalContext.Consumer>
				{ (modalContext) => (
					<>
						<div style={ { display: 'flex', width: '100%', justifyContent: 'flex-end', gap: 20 } }>
							{ this.state.isRoleOwner && (
								<Button
									label={ 'Changer de propriétaire' }
									iconName={ 'LuRepeat' }
									variant={ 'secondary' }
									disabled={ this.props.client.collaborators.length < 2 }
									onClick={ (event: any) =>
									{
										event.stopPropagation();
										modalContext?.content(
											`Changer le propriétaire`,
											<CollaboratorAdminSwitchOwner
												clientId={ this.props.client.id }
												collaborators={ this.state.list.items }
												modalContext={ modalContext }
												getComponentState={ this.collaboratorsAdminService.refresh }
											/>
										);
									} }
								/>
							) }

							<Button
								variant={ 'inline' }
								iconName={ 'LuPlus' }
								label={ 'Ajouter' }
								onClick={ (event: any) =>
								{
									event.stopPropagation();
									(UserService.isSuperAdmin() || UserService.isAdmin()) ? (
										modalContext?.content(
											'Ajouter un collaborateur',
											<CollaboratorAdminFormCreate
												client={ this.props.client }
												modalContext={ modalContext }
												getComponentState={ this.collaboratorsAdminService.refresh }
											/>
										)
									) : (
										modalContext?.content(
											'Ajouter un collaborateur',
											<GlobalContextProvider>
												<CollaboratorInviteForm/>
											</GlobalContextProvider>
										)
									);
								} }
							/>
						</div>
						<Table
							columns={ this.collaboratorsAdminService.buildColumnHeader() }
							list={ this.state.list }
							filterParams={ null }
							onOrderBy={ () => null }
							onRowClick={ (item: CollaboratorInterface, event: any) =>
							{
								event.preventDefault();
								event.stopPropagation();

								modalContext?.content(
									'Détails du Collaborateur',
									<CollaboratorShow
										collaborator={ item }
										client={ this.props.client }
										getComponentState={ (state) => null }
									/>
								);
							} }
						/>
					</>
				) }
			</ModalContext.Consumer>
		);
	}
}

export default CollaboratorsAdmin;