import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SearchSellsyCompanyProps, SearchSellsyCompanyState } from './SearchSellsyCompany.interface';

const initState: SearchSellsyCompanyState = {
	companySellsySearchResults: [],
	searchTerm: null,
};

export class SearchSellsyCompanyService extends BaseComponentService<SearchSellsyCompanyProps, SearchSellsyCompanyState>
{
	constructor()
	{
		super({} as SearchSellsyCompanyProps, initState);

		// Bind
		this.handleSearch = this.handleSearch.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 *
	 * @param event
	 * @private
	 */
	onChangeSearchSellsy(event: any): void
	{
		if (event.target.value === '') {
			this.setState({ searchTerm: null });
		}

		this.setState({ searchTerm: event.target.value });
	}

	/**
	 *
	 * @param event
	 */
	handleSearch(event: any): void
	{
		this.props.updateSearchResults(event, this.state.searchTerm);
	}

	/**
	 *
	 * @param event
	 */
	handleCreate(event: any): void
	{
		this.props.createCompany(event);
	}
}