import React, { ReactElement, ReactNode } from 'react';

export default class ContentOld extends React.Component
	<{ top?: number, children: ReactNode }, any>
{

	render(): ReactElement
	{
		return (
			<>
				<div style={ { position: 'relative', top: (this.props.top) ? this.props.top : 90 } }>
						{ this.props.children }
				</div>
			</>
		);
	}

}