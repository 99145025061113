import React, { Component, ReactElement } from 'react';
import { AssociateUserProps, AssociateUserState } from './AssociateUser.interface';
import { AssociateUserService } from './AssociateUser.service';
import Search from '@/Modules/App/Components/Library/Search/Search';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { AssociateUserStyles } from '@/Modules/User/Components/AssociateUser/AssociateUser.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalContext } from '@/Provider/ModalProvider';
import CreateUserForm from '@/Modules/User/Components/Form/Create/CreateUserForm';
import { CreateUserFormState } from '@/Modules/User/Components/Form/Create/CreateUserForm.interface';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';

class AssociateUser extends Component<AssociateUserProps, AssociateUserState>
{
	private associateUserService: AssociateUserService = new AssociateUserService();
	private apiAdminUserService: ApiAdminUserService = new ApiAdminUserService();

	constructor(props: AssociateUserProps)
	{
		super(props);

		// Config service
		this.associateUserService.setProps(this.props);
		this.associateUserService.subscribeState(this);

		// State
		this.state = this.associateUserService.getState();

		// Bind
		this.apiAdminUserService.search.bind(this.apiAdminUserService);
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.associateUserService.init();
	}

	componentWillUnmount(): void
	{
		this.associateUserService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { selectedUser } = this.state;

		return (
			<>
				<ModalContext.Consumer>
					{ (modalContext) => (
						<>
							{ !selectedUser &&
                <Search
                  inputName={ 'search-user-associate' }
                  inputLabel={ 'Associer un utilisateur' }
                  inputTextHelp={ 'Pour créer un nouveau donneur d\'ordre, vous devez associer un utilisateur existant ou en créer un nouveau si nécessaire.' }
                  isRequired={ true }
                  searchService={ this.apiAdminUserService.search.bind(this.apiAdminUserService) }
                  withResultList={ true }
                  onSelectElement={ (user) => this.associateUserService.handleSelectedUser(user) }
                  notFoundButtonAction={ {
										label: 'Ajouter un utilisateur',
										onClick: (event) => {
											event.stopPropagation();
											modalContext?.content(
												'Ajouter une utilisateur',
												<CreateUserForm
													isAdmin={ true }
													getComponentState={
														(createUserFormState: CreateUserFormState) =>
															this.associateUserService.handleCreatedUser(createUserFormState, modalContext)
													}
													onFormSubmit={ this.associateUserService.createUser }
												/>,
												true
											);
										}
									}}
									roleFilterList={ UserRoleEnum.ROLE_USER }
                  renderResult={ (result) => (
										<div style={ AssociateUserStyles.searchList }>
											<div style={ AssociateUserStyles.left }>
												<span style={ AssociateUserStyles.lastName }>{ result.lastname.toUpperCase() }</span>
												<span>{ result.firstname.toLowerCase() }</span>
											</div>
											<div style={ AssociateUserStyles.right }>
												<span> { result.email }</span>
											</div>
										</div>
									) }
                />
							}

							{ selectedUser &&
                <div style={ AssociateUserStyles.selectedUserContainer }>
                  <div>
                    <div style={ AssociateUserStyles.label }>Utilisateur associé</div>
                    <div style={ FontStyles.textHelp }>
                      Vous avez associé l'utilisateur sélectionné au donneur d'ordre. <br/>
                      Cet utilisateur sera le propriétaire de ce client.
                    </div>
                  </div>
                  <div style={ AssociateUserStyles.selectedUser }>
                    <div style={ AssociateUserStyles.searchList }>
                      <div style={ AssociateUserStyles.left }>
                        <span style={ AssociateUserStyles.lastName }>{ selectedUser.lastname.toUpperCase() }</span>
                        <span>{ selectedUser.firstname.toLowerCase() }</span>
                      </div>
                      <div style={ AssociateUserStyles.right }>
                        <span> { selectedUser.email }</span>
                      </div>
                    </div>
                    <div style={ { display: 'inline-flex', marginLeft: 15 } }>
                      <Button
                        variant={ 'smallDark' }
                        label={ 'Annuler' }
                        onClick={ (event: any) => this.associateUserService.handleCancel(event) }
                      />
                    </div>
                  </div>
                </div>
							}
						</>
					) }
				</ModalContext.Consumer>
			</>
		);
	}
}

export default AssociateUser;