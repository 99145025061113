import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { AssociateUserProps, AssociateUserState } from './AssociateUser.interface';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { ModalContextType } from '@/Provider/ModalProvider';
import { CreateUserFormState } from '@/Modules/User/Components/Form/Create/CreateUserForm.interface';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';

const initState: AssociateUserState = {
	selectedUser: null,
	showCreateUserForm: false,
};

export class AssociateUserService extends BaseComponentService<AssociateUserProps, AssociateUserState>
{
	private apiAdminUserService: ApiAdminUserService = new ApiAdminUserService();
	constructor()
	{
		super({} as AssociateUserProps, initState);

		// Bind
		this.handleSelectedUser = this.handleSelectedUser.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleCreatedUser = this.handleCreatedUser.bind(this);
		this.createUser = this.createUser.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 * Handle selected User on research
	 * @param user
	 */
	handleSelectedUser(user: UserInterface): void
	{
		this.setState(
			{ selectedUser: user },
			() => {
				this.props.getComponentState(this.state);
			}
		);
	}

	/**
	 * Handle Create User From AssociateUser Component
	 * @param createdUserFormState
	 * @param modalContext
	 */
	async handleCreatedUser(createdUserFormState: CreateUserFormState, modalContext?: ModalContextType): Promise<void>
	{
		modalContext?.isLoadingFull(createdUserFormState.isLoading);

		if (modalContext && !createdUserFormState.isLoading && createdUserFormState.formData.user) {
			modalContext.isOpen(false);
		}
	}

	async createUser(userData: UserInterface): Promise<void>
	{
		const response = await this.apiAdminUserService.create(userData);
		this.handleSelectedUser(response);
	}

	/**
	 * Handle Cancel Selected User
	 */
	handleCancel(event: any): void
	{
		event.stopPropagation();

		this.setState({ selectedUser: null },
			() => this.props.getComponentState(this.state)
		);
	}

}