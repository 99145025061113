import React, { Component, ReactElement } from 'react';
import {
	ContextSwitcherProps,
	ContextSwitcherState
} from '@/Modules/App/Components/ContextSwitcher/ContextSwitcher.interface';
import { ContextSwitcherService } from '@/Modules/App/Components/ContextSwitcher/ContextSwitcher.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { ContextSwitcherStyles } from '@/Modules/App/Components/ContextSwitcher/ContextSwitcher.styles';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import { LuChevronsUpDown } from 'react-icons/lu';
import { CompanyInterface } from '@/Modules/Company/Interface/CompanyInterface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';
import LoaderSwitchContext from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext';

class ContextSwitcher extends Component<ContextSwitcherProps, ContextSwitcherState>
{
	private contextSwitcherService = new ContextSwitcherService();

	constructor(props: ContextSwitcherProps)
	{
		super(props);

		// Config service
		this.contextSwitcherService.setProps(this.props);
		this.contextSwitcherService.subscribeState(this);

		// State
		this.state = this.contextSwitcherService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.contextSwitcherService.init();
	}

	componentWillUnmount(): void
	{
		this.contextSwitcherService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderSwitchContext textLoading={'Changement de société...'}/>;
		}

		return (
			<div style={{ position: 'relative' }}>
				{ this.props.authContext?.user.role === 'ROLE_SUPER_ADMIN' &&
					<ToggleOpenClosed isClickOutside={ true }>
						{({ toggle, close, isOpen }) => (
							<>
								<Hovered<HTMLDivElement>>
									{({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
										<div
											ref={ref}
											style={{
												...ContextSwitcherStyles.buttonContainer,
												...(isHovered ? ContextSwitcherStyles.hoveredButton : {}),
											}}
											onMouseEnter={ onMouseEnter }
											onMouseLeave={ onMouseLeave }
											onClick={ toggle }
										>
											{ this.state.contextName }
											<LuChevronsUpDown />
										</div>
									)}
								</Hovered>

								<div style={ ContextSwitcherStyles.openMenuContainer(isOpen) }>
									{ this.state.contextList.map((contextItem: CompanyInterface | ClientInterface) => (
										<Hovered<HTMLDivElement> key={ contextItem.id }>
											{({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
												<div
													ref={ ref }
													style={{
														...ContextSwitcherStyles.openMenuLi,
														...(isHovered ? ContextSwitcherStyles.hoveredItem : {}),
													}}
													onMouseEnter={ onMouseEnter }
													onMouseLeave={ onMouseLeave }
													onClick={() => this.contextSwitcherService.switchContext(contextItem.id, contextItem.name)}
												>
													{ contextItem.name }
												</div>
											)}
										</Hovered>
									))}
								</div>
							</>
						)}
					</ToggleOpenClosed>
				}
			</div>
		);
	}
}

export default withGlobalContext(ContextSwitcher);