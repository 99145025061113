import React from 'react';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { AuthCompanyProps } from '@/Modules/AuthCompany/AuthCompany.interface';
import AuthCompany from '@/Modules/AuthCompany/AuthCompany';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

const AuthCompanyWrapper = (props: AuthCompanyProps) => {

  if (props.authContext?.isLoading) {
    return <LoaderFullPageComponent />;
  }

  if (!props.authContext?.user) {
    return <div>User not authenticated</div>;
  }

  return <AuthCompany { ...props } />;
};

export default withGlobalContext(AuthCompanyWrapper);