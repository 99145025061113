import React, { Component, ReactElement } from 'react';
import { DashboardAdminViewStyle } from '@/Modules/Dashboard/Style/DashboardAdminViewStyle';
import LoadingWrapper from '@/Modules/App/Components/Atom/Loader/LoadingWrapper';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { CardCounterProps } from '@/Modules/App/Components/Card/Counters/CardCounter.interface';
import CountUp from 'react-countup';

class CardCounter extends Component<CardCounterProps>
{
  render(): ReactElement
  {
    return (
      <>
        <div
          style={ DashboardAdminViewStyle.containerCardCounter() }
        >
          <LoadingWrapper isLoading={ this.props.isLoading } height={ 18 } width={ '50%' }>
            <Title type={ 'h6' } style={ { marginTop: 0, marginBottom: 0 } }>{ this.props.title }</Title>
          </LoadingWrapper>
          <div style={ { display: 'flex', justifyContent: 'space-between' } }>
            <LoadingWrapper isLoading={ this.props.isLoading } width={ 50 } height={ 60 }>
              <div style={ DashboardAdminViewStyle.bigNumberStats() }>
                <CountUp
                  start={ 0 }
                  end={ (this.props.isActiveInactiveCounter) ? this.props.stateCounter.active : this.props.stateCounter.all }
                  duration={ 3 }
                  separator=" "
                />
              </div>
            </LoadingWrapper>

            <LoadingWrapper isLoading={ this.props.isLoading } >
              { this.props.isActiveInactiveCounter &&
                <div style={ DashboardAdminViewStyle.textStatusActive() }>
                  Inactif : { this.props.stateCounter.inactive }
                </div>
              }
            </LoadingWrapper>
          </div>
        </div>
      </>
    );
  }
}

export default withGlobalContext(CardCounter);