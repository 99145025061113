import React, { Component, ReactElement } from 'react';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import {
  LegalNoticesProps, LegalNoticesState
} from '@/Modules/LegalNotice/Common/LegalNotices.interface';
import { LegalNoticesAdminService } from '@/Modules/LegalNotice/Admin/List/Regular/LegalNoticesAdmin.service';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import Subtitle from '@/Modules/App/Components/Atom/Title/Subtitle';

class LegalNoticesValidateAdmin extends Component<LegalNoticesProps, LegalNoticesState>
{
  apiLegalNoticeAdminService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
  private legalNoticesAdminService: LegalNoticesAdminService = new LegalNoticesAdminService();

  constructor(props: LegalNoticesProps)
  {
    super(props);

    // Config service
    this.legalNoticesAdminService.setProps(this.props);
    this.legalNoticesAdminService.subscribeState(this);

    // State & props
    this.state = this.legalNoticesAdminService.getState();

    // Document title
    document.title = 'Publisur - ADMIN - Legal notice';
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.legalNoticesAdminService.init('payment');
  }

  componentWillUnmount(): void
  {
    this.legalNoticesAdminService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <HeroSection
          title={
            <>
              <div>Annonces légales</div>
              <Subtitle style={{ fontSize: 14 }}>Paiement en cours</Subtitle>
            </>
          }
          icon={ null }
          button={ {
            label: 'Ajouter une annonce légale',
            variant: 'primary',
            iconName: 'LuPlus',
            onClick: () => this.props.navigation('/admin/legal-notices/create')
          }}
        />

        <ListTableManager
          refreshList={ this.state.legalNoticeList }
          tag={ 'legal-notice-payment' }
          tableColumns={ this.legalNoticesAdminService.buildColumnHeader() }
          serviceList={ (params) => this.legalNoticesAdminService.list({ type: 'payment', filterParams: params }) }
          filters={ this.legalNoticesAdminService.buildFilters() }
          onRowClick={ (event: any, item: any) => this.props.navigation('/admin/legal-notices/' + item.id) }
        />
      </>
    );
  }
}

export default withGlobalContext(LegalNoticesValidateAdmin);