import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';
import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const borderStyles = {
	border: `1px solid ${ themeColors.border }`
} as const;

export const _inputStyles = {
	main: {
		width: '100%',
		height: 28,
		padding: '4px 8px',
		backgroundColor: colors.white,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: colors.gray300,
		borderRadius: 6
	} as CSSProperties,
} as const;

export const boxShadows = {
	main: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' },
	short: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }
} as const;

export const VariablesStyles = {

} as const;