import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SelectProps, SelectState } from './Select.interface';

const initState: SelectState = {
	selectedValue: null,
	searchValue: ''
};

export class SelectService extends BaseComponentService<SelectProps, SelectState>
{
	constructor()
	{
		super({} as SelectProps, initState);

		// Bind
		this.handleSelect = this.handleSelect.bind(this);
		this.getFilteredOptions = this.getFilteredOptions.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.value) {
			const label = this.getLabelForValue(this.props.value);
			this.setState({ selectedValue: label || this.props.value });
		}

		if (this.props.isSearchNeeded) {
			this.getFilteredOptions(this.props);
		}
	}

	/**
	 * Handle select option from selector
	 * @param value
	 */
	handleSelect(value: string): void
	{
		const label = this.getLabelForValue(value);
		this.setState({ selectedValue: label || value });
	}

	onSearchChange(event: React.ChangeEvent<HTMLInputElement>): void
	{
		this.setState({ searchValue: event.target.value });
	}

	/**
	 * Build Filter option from props
	 * @param props
	 */
	getFilteredOptions(props: SelectProps): any[] {
		const { listOptions = [], displayKey = 'label' } = props; // Default to an empty array
		const { searchValue } = this.state;

		// Ensure `listOptions` is an array
		if (!Array.isArray(listOptions)) {
			console.error("listOptions must be an array, but received:", listOptions);
			return [];
		}

		if (searchValue!.trim() === '') {
			return listOptions;
		}

		return listOptions.filter((option) =>
			option[displayKey]?.toString().toLowerCase().includes(searchValue!.toLowerCase())
		);
	}


	/**
	 * Get the label for a given value from listOptions
	 * @param value The value to find the label for
	 * @return The label if found, otherwise null
	 */
	getLabelForValue(value: string | number): string | null
	{
		const { listOptions, displayKey = 'label' } = this.props;
		const matchingOption = listOptions.find((option) => option.value === value || option[displayKey] === value);
		return matchingOption ? matchingOption[displayKey] : null;
	}
}