import { CSSProperties } from 'react';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';

export const PaginationStyles = {
	container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '12px 0',
		position: 'sticky',
		zIndex: 1,
		bottom: 0,
		backgroundColor: themeColors.background,
		boxShadow: '0px -3px 3px -3px rgba(48, 49, 61, 0.2)'
	} as CSSProperties,

	pageButtons: {
		display: 'flex',
		gap: 4,
	} as CSSProperties,

	buttons: {
		display: 'flex',
		gap: 10
	} as CSSProperties,

} as const;