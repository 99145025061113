import React, { Component, ReactElement } from 'react';
import { LegalNoticePriceDetailsProps, LegalNoticePriceDetailsState } from './LegalNoticePriceDetails.interface';
import { LegalNoticePriceDetailsService } from './LegalNoticePriceDetails.service';
import Table from '@/Modules/App/Components/Library/Table/Table';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import {
	LegalNoticePriceDetailsStyles
} from '@/Modules/LegalNotice/Components/LegalNoticePriceDetails/LegalNoticePriceDetails.styles';

class LegalNoticePriceDetails extends Component<LegalNoticePriceDetailsProps, LegalNoticePriceDetailsState>
{
	private legalNoticePriceDetailsService = new LegalNoticePriceDetailsService();

	constructor(props: LegalNoticePriceDetailsProps)
	{
		super(props);

		// Config service
		this.legalNoticePriceDetailsService.setProps(this.props);
		this.legalNoticePriceDetailsService.subscribeState(this);

		// State
		this.state = this.legalNoticePriceDetailsService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticePriceDetailsService.init();
	}

	async componentDidUpdate(prevProps: LegalNoticePriceDetailsProps): Promise<void>
	{
		if (
			prevProps.legalNotice !== this.props.legalNotice ||
			prevProps.selectedDepartment !== this.props.selectedDepartment ||
			prevProps.selectedCategory !== this.props.selectedCategory
		) {
			if (!this.state.isPriceLoaded) {
				await this.legalNoticePriceDetailsService.updateState();
				await this.legalNoticePriceDetailsService.handlePrintPrice();
			}
		}
	}

	componentWillUnmount(): void
	{
		this.legalNoticePriceDetailsService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading || !this.state.isPriceLoaded) {
			return <LoaderComponent/>;
		}

		// Calculate total HT (priceExclVat) and TTC (priceInclVat)
		const totalExclTax = this.state.priceList.items.reduce((sum, item) => {
			return sum + parseFloat(item.priceExclVat);
		}, 0);

		const totalInclTax = this.state.priceList.items.reduce((sum, item) => {
			return sum + parseFloat(item.priceInclVat);
		}, 0);

		return (
			<>
				<Table
					list={ this.state.priceList }
					columns={ this.legalNoticePriceDetailsService.buildColumnHeader() }
					filterParams={ null }
					onRowClick={ () => null }
					onOrderBy={ () => null }
				/>
				<div style={ LegalNoticePriceDetailsStyles.amountDue }>
					<div>Montant HT</div>
					<div>{ totalExclTax.toFixed(2) } €</div>
				</div>
				<div style={ LegalNoticePriceDetailsStyles.amountDue }>
					<div>Montant TTC</div>
					<div style={{ fontWeight: 700 }}>{ totalInclTax.toFixed(2)  } €</div>
				</div>
			</>
		);
	}
}

export default LegalNoticePriceDetails;