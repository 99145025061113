import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ButtonProps, ButtonState } from '@/Modules/App/Components/Library/Button/Button.interfaces';

const initState: ButtonState = {};

export class ButtonService extends BaseComponentService<ButtonProps, ButtonState>
{
	constructor()
	{
		super({} as ButtonProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}
}