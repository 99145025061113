import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Library/Input/Input';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { LuCaseUpper } from "react-icons/lu";

interface ComponentProps
{
  formData: any,
  category: FormBuilderCategoryInterface|null,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export default class ConsumerFormComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        <div style={{ display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' }}>
          <div style={{ flex: '1 1 50%', display: 'flex', alignItems: 'flex-end' }}>
            <Input
              type="text"
              label="Dénomination"
              name="name"
              width="100%"
              style={{ flexGrow: 1 }}
              value={this.props.formData.name || ''}
              onChange={this.props.onChange}
              disabled={!!(this.props.category
                && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                && !this.props.formData.siren)}
            />
            <ButtonOld
              type="default"
              iconLeft={<LuCaseUpper />}
              onClick={() => this.handleDataToToggleCase('name')}
            />
          </div>
          {this.props.category && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value &&
            <div style={{ flex: '1 1 50%', display: 'flex', alignItems: 'flex-end' }}>
              <Input
                type="text"
                style={{ flexGrow: 1 }}
                label="RCS"
                name="rcs"
                value={this.props.formData.rcs || ''}
                onChange={this.props.onChange}
                disabled={!!(this.props.category
                  && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                  && !this.props.formData.siren)}
              />
              <ButtonOld
                type="default"
                iconLeft={<LuCaseUpper />}
                onClick={() => this.handleDataToToggleCase('rcs')}
              />
            </div>
          }
        </div>
        <div style={{ display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' }}>
          <div style={{ flex: '1 1 50%', display: 'flex', alignItems: 'flex-end' }}>
            <Input
              type="text"
              label="Forme juridique"
              name="legalStatus"
              style={{ flexGrow: 1 }}
              value={this.props.formData.legalStatus || ''}
              onChange={this.props.onChange}
              disabled={!!(this.props.category
                && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                && !this.props.formData.siren)}
            />
            <ButtonOld
              type="default"
              iconLeft={<LuCaseUpper />}
              onClick={() => this.handleDataToToggleCase('legalStatus')}
            />
          </div>
          <div style={{ flex: '1 1 50%' }}>
            <Input
              type="number"
              style={{ flexGrow: 1 }}
              label="Capital"
              name="capital"
              value={this.props.formData.capital || ''}
              onChange={this.props.onChange}
              disabled={!!(this.props.category
                && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                && !this.props.formData.siren)}
            />
          </div>
        </div>
      </>
    );

  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private handleDataToToggleCase(fieldName: string): void
  {
    const currentValue = this.props.formData[fieldName] || '';
    const isUpperCase = currentValue === currentValue.toUpperCase();

    const updatedValue = isUpperCase ? currentValue.toLowerCase() : currentValue.toUpperCase();

    const event = {
      target: {
        name: fieldName,
        value: updatedValue,
      }
    };

    this.props.onChange(event as React.ChangeEvent<HTMLInputElement>);
  }

  //</editor-fold>
}