import React, { ReactElement } from 'react';
import TableOld from '@/Modules/App/Components/Atom/Table/TableOld';
import { ApiAdminPricingService } from '@/Service/Admin/ApiAdminPricingService';
import TableHeader from '@/Modules/App/Components/Atom/Table/TableHeader';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import TableCell from '@/Modules/App/Components/Atom/Table/TableCell';
import { TableHeaderColumnInterface } from '@/Modules/App/Components/Atom/Interface/TableHeaderColumnInterface';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import { PricingLineInterface } from '@/Modules/Pricing/Interface/PricingLineInterface';
import InputTableCell from '@/Modules/App/Components/Atom/Form/Input/InputTableCell';
import SelectComponentOld from '@/Modules/App/Components/Atom/Form/Select/SelectComponentOld';
import TvaEnum from '@/Modules/Pricing/Enum/TvaEnum';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { LuPlus, LuPencilLine, LuTrash2, LuSave, LuMoreHorizontal } from 'react-icons/lu';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';

export default class PricingAdminPriceLineComponent extends React.Component
	<{
		flashMessageContext: FlashMessageContextType;
	},
		{
			editPriceLineId: number | null
			linePriceList: PricingLineInterface[],
			openMenuActionId: number | null,
			hovered: number | null,
		}
	>
{
	pricingService: ApiAdminPricingService;
	actionMenuRef: React.RefObject<HTMLDivElement> | null;

	state = {
		editPriceLineId: null,
		linePriceList: [],
		openMenuActionId: null,
		hovered: null,
	};

	constructor(props: any)
	{
		super(props);

		// Services
		this.pricingService = new ApiAdminPricingService();

		// Ref
		this.actionMenuRef = React.createRef();

		// Bind
		this.addNewRecord = this.addNewRecord.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onSaveData = this.onSaveData.bind(this);
		this.getPricingLineList = this.getPricingLineList.bind(this);
		this.onSelectedVatRate = this.onSelectedVatRate.bind(this);
	}

	handleClickOutside = (event: MouseEvent): void =>
	{
		if (!this.actionMenuRef?.current?.contains(event.target as Node)) {
			this.setState({ openMenuActionId: null });
		}
	};

	async componentDidMount()
	{
		await this.getPricingLineList();
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	onClickOpenMenuAction = (priceLineId: number): void =>
	{
		this.setState({ openMenuActionId: priceLineId });
	};

	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					position: 'relative',
					backgroundColor: CssVariableEnum['--color-white'],
					borderRadius: '8px',
					padding: '20px 10px',
					border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
					marginTop: '20px',
				} }>
					{
						(this.state.editPriceLineId || this.state.editPriceLineId === 0) &&
            <div style={ {
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: 5
						} }>
              <ButtonOld
                type="default-blue"
								iconLeft={ <LuSave /> }
                onClick={ () => (this.state.editPriceLineId || this.state.editPriceLineId === 0)
									? (this.state.editPriceLineId === 0)
										? this.onSaveData(0)
										: this.onSaveData(this.state.editPriceLineId)
									: null
								}
              >
                Enregistrer
              </ButtonOld>
							{ this.state.editPriceLineId !== 0 &&
                <ButtonOld
                  type="inline-default"
                  onClick={ () => this.setState({ editPriceLineId: null }) }
                >
                  Annuler
                </ButtonOld>
							}
            </div>
					}
					<TableOld>
						<TableHeader
							columnHeaderList={ this.builderTableHeaderColumns() }
							isFilters={ true }
						/>
						{ this.state.linePriceList.map((priceLine: PricingLineInterface) => (
							<TableRow
								key={ priceLine.id }
								borderBottom={ true }
								style={ { backgroundColor: (this.state.editPriceLineId === priceLine.id) ? CssVariableEnum['--color-grey-100'] : 'inherit' } }
							>
								<TableCol columnSize={ 2 }  isEditMode={ !!(this.state.editPriceLineId) }>
									{ this.state.editPriceLineId === priceLine.id ? (
										<InputTableCell
											value={ priceLine.label }
											onChange={ (event) => this.onInputChange(event.target.value, 'label', priceLine.id) }
										/>
									) : (
										<TableCell>
											{ priceLine.label }
										</TableCell>
									) }
								</TableCol>
								<TableCol columnSize={ 6 } isEditMode={ !!(this.state.editPriceLineId) }>
									{ this.state.editPriceLineId === priceLine.id ? (
										<InputTableCell
											value={ priceLine.description }
											onChange={ (event) => this.onInputChange(event.target.value, 'description', priceLine.id) }
										/>
									) : (
										<TableCell>
											{ (priceLine.description) ? priceLine.description : '-' }
										</TableCell>
									)
									}
								</TableCol>
								<TableCol columnSize={ 2 }  isEditMode={ !!(this.state.editPriceLineId) }>
									{ this.state.editPriceLineId === priceLine.id ? (
										<SelectComponentOld
											style={ { flex: '0 0 200px', maxWidth: '200px' } }
											listOptions={ TvaEnum.options }
											selectedValue={ (priceLine.vatRate) ? TvaEnum.findByValue(priceLine.vatRate) : null }
											onSelectedOption={ this.onSelectedVatRate }
											renderOptionLabel={ option => `(${ option.label }%) ${ option.description }` }
											selectionText={ 'Choix TVA' }
										/>
									) : (
										<TableCell>
											{ priceLine.vatRate } %
										</TableCell>
									) }
								</TableCol>
								<TableCol columnSize={ 2 }  isEditMode={ !!(this.state.editPriceLineId) }>
									{ this.state.editPriceLineId === priceLine.id ? (
										<InputTableCell
											type={ 'number' }
											value={ priceLine.price }
											onChange={ (event) => this.onInputChange(event.target.value, 'price', priceLine.id) }
										/>
									) : (
										<TableCell>
											{ priceLine.price } €
										</TableCell>
									) }
								</TableCol>
								<div style={ {
									position: 'relative',
									minWidth: '5%',
									height: '100%',
								} }>
									<TableCell>
										{ this.state.editPriceLineId !== priceLine.id &&
                      <ButtonOld
                        type={ 'inline-default' }
                        iconLeft={ <LuMoreHorizontal /> }
                        onClick={ () => this.onClickOpenMenuAction(priceLine.id) }
                      />
										}
									</TableCell>
									{ this.state.openMenuActionId === priceLine.id &&
										this.menuActionRow(priceLine)
									}
								</div>
							</TableRow>
						)) }
						<TableRow isCheckboxInput={ false }>
							<ButtonOld
								type={ 'inline-default-blue' }
								iconLeft={ <LuPlus/> }
								onClick={ () => this.addNewRecord() }
								disabled={ this.state.linePriceList.some((priceLine: PricingLineInterface) => priceLine.id === 0) }
								style={ { marginTop: '10px' } }
							>
								Ajouter une ligne
							</ButtonOld>
						</TableRow>
					</TableOld>
				</div>
			</>
		);
	}

	//<editor-fold desc="Fetch Data" defaultstate="collapsed">

	async getPricingLineList(): Promise<void>
	{
		try {
			const linePriceList = await this.pricingService.listPricingLine();
			this.setState({ linePriceList });

		} catch (error: any) {
			console.log('An error occurred while fetching pricing Line list:', error);
		}
	}

	//</editor-fold>

	//<editor-fold desc="Build Html" defaultstate="collapsed"> 	//</editor-fold>

	private menuActionRow(priceLine: any): ReactElement
	{
		return (
			<div
				ref={ this.actionMenuRef }
				style={ {
					position: 'absolute',
					zIndex: 400,
					backgroundColor: CssVariableEnum['--color-white'],
					width: 150,
					top: 0,
					left: -100,
					border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
					boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
					borderRadius: 8,
					padding: 10,
					cursor: 'pointer'
				} }>
				{ this.menuActionItem(1, 'Modifier', <LuPencilLine/>, () => this.onEdit(priceLine.id)) }
				{ this.menuActionItem(2, 'Supprimer', <LuTrash2/>, () => this.onDeletePricingLine(priceLine.id)) }
			</div>
		);
	}

	private menuActionItem(hoveredId: number, label: string, icon: ReactElement, action: () => void): ReactElement
	{
		return (
			<div
				style={ {
					padding: '3px 6px',
					borderRadius: 8,
					backgroundColor: (this.state.hovered === hoveredId) ? CssVariableEnum['--color-grey-100'] : 'inherit',
				} }
				onMouseEnter={ () => this.setState({ hovered: hoveredId }) }
				onMouseLeave={ () => this.setState({ hovered: null }) }
				onClick={ action }
			>
				{ icon } { label }
			</div>
		);
	}

	private builderTableHeaderColumns(): TableHeaderColumnInterface[]
	{
		return [
			{ columnTitle: 'Libellé', columnSize: 2, name: 'label' },
			{ columnTitle: 'Description', columnSize: 6, name: 'description' },
			{ columnTitle: 'TVA', columnSize: 2, name: 'vatRate' },
			{ columnTitle: 'Prix unitaire', columnSize: 2, name: 'unitPrice' },
		];
	}

	private addNewRecord()
	{
		const newRecord: PricingLineInterface = {
			id: 0,
			label: '',
			description: '',
			vatRate: 0,
			price: 0
		};

		this.setState((prevState: any) => ({
			...prevState,
			linePriceList: [...prevState.linePriceList, newRecord],
			editPriceLineId: newRecord.id
		}));
	}

	//</editor-fold>

	//<editor-fold desc="Handle Change Data" defaultstate="collapsed">

	private onInputChange(value: string, field: string, priceLineId: number): void
	{
		let parsedValue: string | number = value;

		if (field === 'price' || field === 'tva') {
			parsedValue = parseFloat(value);
		}

		this.setState((prevState: any) => ({
			linePriceList: prevState.linePriceList.map((priceLine: PricingLineInterface) =>
				priceLine.id === priceLineId ? { ...priceLine, [field]: parsedValue } : priceLine
			)
		}));
	}

	private onSelectedVatRate(vatRate: TvaEnum): void
	{
		this.setState((prevState: any) => ({
			linePriceList: prevState.linePriceList.map((priceLine: PricingLineInterface) =>
				priceLine.id === this.state.editPriceLineId ? { ...priceLine, vatRate: vatRate.label } : priceLine
			)
		}));
	}

	private onEdit(priceLineId: number): void
	{
		this.setState((prevState) => ({
			...prevState,
			editPriceLineId: priceLineId,
			openMenuActionId: null
		}));
	}

	private async onSaveData(priceLineId: number): Promise<void>
	{

		const modifyPriceLine: any = this.state.linePriceList.find((priceLine: PricingLineInterface) => priceLine.id === priceLineId);

		if (modifyPriceLine.id === 0) {
			await this.pricingService.createPriceLine(modifyPriceLine);

			this.props.flashMessageContext.flashMessage(
				'Creation réussie',
				'Une nouvelle ligne a été ajoutée',
				'success'
			);

		} else {
			await this.pricingService.editPriceLine(modifyPriceLine, priceLineId);

			this.props.flashMessageContext.flashMessage(
				'Mise à jour réussie',
				'Votre tableau a bien été modifié, merci de bien vérifier les nouvelles données',
				'success'
			);
		}

		this.setState({ editPriceLineId: null }, () => this.getPricingLineList());
	}

	private async onDeletePricingLine($priceLineId: number): Promise<void>
	{
		try {
			await this.pricingService.deletePriceLine($priceLineId);
			await this.getPricingLineList();
			this.props.flashMessageContext.flashMessage(
				'Suppression réussie',
				'La ligne sélectionnée a bien été supprimée',
				'success'
			);
		} catch (error: any) {
			console.log('your price line is deleted', $priceLineId);
		}
	}

	//</editor-fold>
}