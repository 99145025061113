import React, { ReactElement } from 'react';
import Label from '@/Modules/App/Components/Library/Label/Label';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

interface RadioOption
{
	label: string;
	value: string | number;
}

export default class Radio extends React.Component
	<
		{
			style?: React.CSSProperties,
			options: RadioOption[];
			label?: string;
			textHelp?: string | ReactElement;
			name: string;
			selectedValue: any;
			onSelectedOption: (value: string | number) => void;
			isRequired?: boolean,
		},
		{
			selectedValue: string | number | null;
		}
	>
{
	state = {
		selectedValue: this.props.selectedValue,
	};

	handleSelectOption = (value: string | number) =>
	{
		this.setState({ selectedValue: value });
		this.props.onSelectedOption(value);
	};

	render(): ReactElement
	{
		return (
			<div style={ this.props.style }>
				{ this.props.label && <Label label={ this.props.label } isRequired={ this.props.isRequired ?? false }/> }
				{ this.props.textHelp &&
          <div style={ { ...FontStyles.textHelp, marginBottom: 5 } }>
						{ this.props.textHelp }
          </div>
				}
				<div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
					{ this.props.options.map((option) => (
						<label key={ option.value.toString() } style={ { display: 'flex', gap: 5, alignItems: 'center' } }>
							<input
								type="radio"
								name={ this.props.name }
								value={ option.value }
								checked={ this.state.selectedValue === option.value }
								onChange={ () => this.handleSelectOption(option.value) }
							/>
							{ option.label }
						</label>
					)) }
				</div>
			</div>
		);
	}
}