import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ModalProps, ModalState } from './Modal.interface';
import React, { ReactElement } from 'react';

const initState: ModalState = {
	isLoadingFull: false,
	isLoadingOverlay: false,
	modalStack: [],
};

export class ModalService extends BaseComponentService<ModalProps, ModalState>
{
	constructor()
	{
		super({} as ModalProps, initState);

		// Bind
		this.isOpenModal = this.isOpenModal.bind(this);
		this.modalContent = this.modalContent.bind(this);
		this.isLoadingUpdated = this.isLoadingUpdated.bind(this);
		this.modalActions = this.modalActions.bind(this);
		this.canCloseModal = this.canCloseModal.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleErrorMessage = this.handleErrorMessage.bind(this);
		this.clearModals = this.clearModals.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 * Handle OPEN / CLOSED multi modals
	 * @param isOpen
	 */
	isOpenModal(isOpen: boolean): void
	{
		if (isOpen) {
			const lastModal = this.state.modalStack[this.state.modalStack.length - 1];
			if (lastModal) {
				this.setState(prevState => ({
					modalStack: prevState.modalStack.map((modal, index) =>
						index === prevState.modalStack.length - 1 ? { ...modal, isOpen: true } : modal
					)
				}));
			}
		} else {
			this.setState(prevState => {
				const updatedStack = prevState.modalStack.slice(0, -1);
				return {
					modalStack: updatedStack
				};
			});
		}
	}

	/**
	 *
	 * @param isLoadingOverlay
	 */
	isLoadingOverlay(isLoadingOverlay: boolean): void
	{
			this.setState({ isLoadingOverlay });
	}

	/**
	 * Handle Modal Content
	 * @param title - The title of the modal
	 * @param content - The content of the modal
	 * @param loadingDuration - Optional duration for the loading state (default: 2000ms)
	 */
	modalContent(title: string | null, content: ReactElement, loadingDuration: number = 300): void
	{
		this.setState(prevState => {
			let modalStack = [...prevState.modalStack];

			// Check if the modal is already in the stack by matching the title
			const existingModalIndex = modalStack.findIndex(modal => modal.title === title);

			// If found, remove the existing modal from the stack
			if (existingModalIndex !== -1) {
				modalStack.splice(existingModalIndex, 1);
			}

			// Add the new modal to the stack
			modalStack = [
				...modalStack,
				{
					isOpen: true,
					title,
					content,
					errorMessage: null,
					actions: [],
					modalRef: React.createRef<HTMLDivElement>(),
					isLoading: true,  // Start with isLoading true
				},
			];

			return { modalStack };
		});

		// Simulate a loading duration (optional)
		setTimeout(() => {
			this.setState(prevState => {
				const modalStack = [...prevState.modalStack];
				const lastModal = modalStack[modalStack.length - 1];

				// Stop loading for the newly opened modal
				if (lastModal && lastModal.title === title) {
					lastModal.isLoading = false;
				}

				return { modalStack };
			});
		}, loadingDuration);
	}


	/**
	 * Handle is Loading
	 * @param isLoading
	 */
	isLoadingUpdated = (isLoading: boolean) => {
		this.setState((prevState) => {
			if (prevState.modalStack.length > 0) {
				const modalStack = [...prevState.modalStack];
				const lastModal = modalStack[modalStack.length - 1];
				lastModal.isLoading = isLoading;
				return { modalStack };
			}
			return prevState;
		});
	};


	/**
	 * Handle modal actions
	 * @param actions
	 */
	modalActions(actions: {
		label: string,
		action: ((event: any) => Promise<void>) | ((event?: any) => any)
	}[]): void
	{
		this.setState(prevState =>
		{
			const modalStack = [...prevState.modalStack];
			const lastModal = modalStack[modalStack.length - 1];
			if (lastModal) {
				lastModal.actions = actions;
			}
			return { modalStack };
		});
	}

	/**
	 * Handle Error message
	 * @param errorMessage
	 */
	handleErrorMessage(errorMessage: string | null): void
	{
		this.setState(prevState =>
		{
			const modalStack = [...prevState.modalStack];
			if (modalStack.length > 0) {
				modalStack[modalStack.length - 1].errorMessage = errorMessage;
			}
			return { modalStack };
		});
	}

	/**
	 * Handle click outside modal
	 * @param event
	 */
	handleClickOutside(event: MouseEvent): void
	{
		const modalIndex = this.state.modalStack.length - 1;
		const modal = this.state.modalStack[modalIndex];
		if (modal && modal.modalRef
			&& modal.modalRef.current
			&& !modal.modalRef.current.contains(event.target as Node)
		) {
			if (this.canCloseModal(modalIndex)) {
				this.setState(prevState =>
				{
					const modalStack = prevState.modalStack.slice(0, -1);
					return { modalStack };
				});
			}
		}
	}

	/**
	 * Handle Close Modal
	 * @param modalIndex
	 */
	canCloseModal(modalIndex: number): boolean
	{
		const modal = this.state.modalStack[modalIndex];
		if (modal && modal.modalRef?.current) {
			// Cast inputs to HTMLInputElement
			const inputs = modal.modalRef.current.querySelectorAll('input');
			const inputArray = Array.from(inputs) as HTMLInputElement[];

			for (let input of inputArray) {
				// Check if the input has a non-empty value
				if (input.value.trim() !== '') return false;
			}

			// Cast selectors to generic Element (or you can narrow down based on the expected type)
			const selectors = modal.modalRef.current.querySelectorAll('.custom-select');
			const selectorArray = Array.from(selectors) as Element[];

			for (let selector of selectorArray) {
				let textContent = selector.textContent ?? '';
				// Check if the selector's textContent is valid
				if (textContent.trim() !== 'Sélectionner une Option' && textContent.trim() !== '') {
					return false;
				}
			}
		}

		// Return true if no conditions to keep the modal open are met
		return true;
	}

	/**
	 * Clear all modal inside the state
	 */
	clearModals(): void {
		this.setState({ modalStack: [] }); // Vider la pile de modals
	}

}