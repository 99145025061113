import { CSSProperties } from 'react';

export const EditableStyles = {
	container: {} as CSSProperties,

	buttons: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'flex-end',
		gap: 5,

	} as CSSProperties,
} as const;