import React, { createRef, ReactElement } from 'react';
import { BsBoxArrowRight } from 'react-icons/bs';
import { LuAlignLeft } from 'react-icons/lu';
import { dateHeader } from '@/Utils/DateUtils';
import CardActionComponent from '@/Modules/App/Components/Atom/Card/CardActionComponent';
import { AuthContext } from '@/Provider/Auth/Auth.provider';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { NavigateFunction } from 'react-router-dom';
import BreadcrumbsWrapper from '@/Modules/App/Wrapper/BreadcrumbsWrapper';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { Badge } from 'react-bootstrap';
import MainNavigationDeveloperComponent from '@/Modules/App/Components/Developer/Menu/MainNavigationDeveloperComponent';

export default class LayoutDeveloper extends React.Component
	<{
		authContext: AuthContextType,
		flashMessageContext: FlashMessageContextType,
		children?: React.ReactNode,
		navigation: NavigateFunction
	},
	{
		isAvatarCardOpen: boolean, isModalOpen: boolean
	}>
{
	static contextType = AuthContext;
	navigation: NavigateFunction;
	menuActionRef = createRef<HTMLDivElement>();
	ENV: any = process.env.REACT_APP_ENV;

	constructor(props: any)
	{
		super(props);

		this.state = {
			isAvatarCardOpen: false,
			isModalOpen: false,
		};

		// Navigate
		this.navigation = props.navigation;

		// BIND
		this.handleLogout = this.handleLogout.bind(this);
	}

	handleAvatarClick = () =>
	{
		this.setState(prevState => ({ isAvatarCardOpen: !prevState.isAvatarCardOpen }));
	};

	closeAvatarCardAction = () =>
	{
		this.setState({ isAvatarCardOpen: false });
	};

	render(): ReactElement
	{
		return (
			<>
				<div className="publisur__container">
					<div className="header" style={ { border: 'none', backgroundColor: CssVariableEnum['--color-grey-900'] } }>
						<div className="header__container">
							<div className="header-brand">
								<div
									className="logo"
									style={ {
										fontSize: 20,
										fontWeight: 500,
										display: 'flex',
										alignItems: 'center',
										gap: '5px',
										color: CssVariableEnum['--color-white'],
										cursor: 'pointer'
									} }
									onClick={ () => { this.props.navigation(`/admin/`); } }
								>
									<div style={ {
										height: 25,
										width: 25,
										borderRadius: '6px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									} }><LuAlignLeft /></div>
									Publisur.
								</div>
							</div>
							<div className="header-main w-100">
								<Badge style={{ marginLeft: '20px', marginTop: '0', marginRight: '0' }} bg={"info"}>
									---- MODE DEVELOPPEUR ----
								</Badge>

								<div className="d-flex justify-content-end w-100">
									<div className="date px-5 mt-1" style={{ color: CssVariableEnum['--color-white']}}>
										{ dateHeader() }
									</div>
									<CardActionComponent
										className="menu-action-avatar"
										buildActionsContent={ this.buildCardActionsContent() }
										menuActionRef={ this.menuActionRef }
										isOpen={ this.state.isAvatarCardOpen }
										onClose={ this.closeAvatarCardAction }
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="layout">
						<div className="sidebar" style={ {
							border: 'none',
							backgroundColor: CssVariableEnum['--color-grey-900'],
							color: CssVariableEnum['--color-white']
						} }>
							<div className="sidebar__container">
								<div className="sidebar-menu">
									<MainNavigationDeveloperComponent/>
								</div>
								<div className="sidebar-footer">

								</div>
							</div>
						</div>
						<main className="outlet" style={ { backgroundColor: CssVariableEnum['--color-grey-900'] } }>
							<div style={ {
								height: '100%',
								position: 'fixed',
								borderTopLeftRadius: '26px',
								width: 'calc(100% - 240px)',
								border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
								backgroundColor: CssVariableEnum['--color-grey-100']
							} }>
								<div style={ {
									height: 'calc(100vh - 100px)',
									overflow: 'scroll',
									overflowX: 'hidden'
								} }>
									{ this.ENV === 'dev' &&
										<div style={{
											backgroundColor: CssVariableEnum['--color-green-300'],
											color: CssVariableEnum['--color-green-700'],
											position: 'relative',
											transitionProperty: 'opacity, transform',
											transitionDuration: '500ms',
											transitionTimingFunction: 'ease',
											padding: '10px',
											borderRadius: '25px 0 0 0',
											textAlign: 'center'
										}}>
											ATTENTION : Vous êtes en environnement de dev !
										</div>
									}
									<div style={{ paddingTop: 10}}>
										<BreadcrumbsWrapper />
									</div>
									{ this.props.children }
								</div>
							</div>
						</main>
					</div>
				</div>
			</>
		);
	}

	private buildCardActionsContent()
	{
		return [
			[
				{
					name: 'Déconnexion',
					isClickable: true,
					icon: <BsBoxArrowRight/>,
					action: this.handleLogout
				}
			]
		];
	}

	private handleLogout()
	{
		const authContext = this.context as AuthContextType;
		if (authContext && authContext.logout) {
			authContext.logout();
			this.navigation('/auth');
		}
	}
}