import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { AppConfigAdminProps, AppConfigAdminState } from '@/Modules/Config/Admin/AppConfigAdmin.interface';
import { ApiAdminSupplierService } from '@/Service/Admin/ApiAdminSupplierService';
import { SupplierInterface } from '@/Modules/Supplier/Interface/SupplierInterface';

const initState: AppConfigAdminState = {
  isLoading: false,
  isEdit: false,
  supplierList: [],
  selectedSupplier: null
}

export class AppConfigAdminService extends BaseComponentService<AppConfigAdminProps, AppConfigAdminState>
{
  private apiSupplierService: ApiAdminSupplierService

  constructor()
  {
    super({} as AppConfigAdminProps, initState);

    // Service
    this.apiSupplierService = new ApiAdminSupplierService();

    // Bind
    this.onSelectedSupplier = this.onSelectedSupplier.bind(this);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    const supplierList = await this.apiSupplierService.list();
    this.setState({ supplierList, selectedSupplier: this.props.company.supplier, isLoading: false });
  }

  async onSelectedSupplier(supplier: SupplierInterface): Promise<void>
  {
    this.setState({ selectedSupplier: supplier, isEdit: true });
  }

  async edit(state: AppConfigAdminState): Promise<void>
  {
    await this.apiSupplierService.edit(this.props.company.id, state.selectedSupplier?.id as number);
    this.setState({ isEdit: false }, async () => { await this.init() });
    await this.init();
    this.props.onEdit();
  }
}