import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeDetailsProps, LegalNoticeDetailsState } from './LegalNoticeDetails.interface';

const initState: LegalNoticeDetailsState = {
	isLoading: true
};

export class LegalNoticeDetailsService extends BaseComponentService<LegalNoticeDetailsProps, LegalNoticeDetailsState>
{
	constructor()
	{
		super({} as LegalNoticeDetailsProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ isLoading: false });
	}
}