import { CSSProperties } from 'react';

export const LegalNoticeControlsStyles = {
	container: {
		width: '100%',
	} as CSSProperties,

	canEditContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	} as CSSProperties,

	canEditActions: {
		display: 'flex',
		gap: 5
	} as CSSProperties,
} as const;