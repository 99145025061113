import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import InviteStateEnum from '@/Enum/InviteStateEnum';
import { InviteCollaboratorInterface } from '@/Modules/Collaborator/Interface/InviteCollaboratorInterface';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';

export default class InviteCollaboratorModel
{
	id: number = 0;
	inviteEmail: string = '';
	collaboratorRole: keyof typeof CollaboratorRoleEnum | null = null;
	state: InviteStateEnum | null = null;
	additionalInfo: string = 'Invitation à votre nouvel espace';
	createdAt: Date = new Date();
	updatedAt: Date = new Date();
	code: string = '';
	client: ClientModel = new ClientModel();

	constructor()
	{
		this.state = InviteStateEnum.PENDING;
	}

	static prepare(inviteCollaborator: InviteCollaboratorInterface): InviteCollaboratorModel
	{
		let model = new InviteCollaboratorModel();
		model.id = inviteCollaborator.id;
		model.inviteEmail = inviteCollaborator.inviteEmail;
		model.collaboratorRole = (Object.keys(CollaboratorRoleEnum) as Array<keyof typeof CollaboratorRoleEnum>)
			.find(key => CollaboratorRoleEnum[key] === inviteCollaborator.collaboratorRole) || null;
		model.state = Object.values(InviteStateEnum).find(state => state.value === inviteCollaborator.state);
		model.additionalInfo = inviteCollaborator.additionalInfo;
		model.code = inviteCollaborator.code;
		model.client = inviteCollaborator.client;
		model.createdAt = inviteCollaborator.createdAt;
		model.updatedAt = inviteCollaborator.updatedAt;

		return model;
	}
}
