import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

export const SandboxStyles = {
	container: (isOpen: boolean) => ({
		position: 'relative',
		top: 0,
		height: (isOpen) ? 44 : 0,
		width: '100vw',
		backgroundColor: colors.success600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 25px',
		transition: 'height 0.3s, transform 0.3s ease-in-out',
		transform: isOpen ? 'translateY(0)' : 'translateY(-100%)',
	}) as CSSProperties,

	title: {
		...FontStyles.textContrast,
		color: colors.white,
		fontWeight: 600,
	} as CSSProperties,

	content: {
		...FontStyles.textContrast,
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'center',
		color: colors.white,
	} as CSSProperties,

	action: {
		...FontStyles.textContrast,
		display: 'inline-flex',
		padding: '5px 10px',
		color: colors.white
	},

	hoveredAction: {
		color: colors.success200
	} as CSSProperties,

	closedButton: (isHovered: boolean) => ({
		display: 'inline-flex',
		width: (isHovered) ? 30 : 0,
		opacity: isHovered ? 1 : 0,
		transform: isHovered ? 'translateX(0)' : 'translateX(100%)',
		transition: 'opacity 0.3s ease, transform 0.3s ease, width 0.3s ease',
		backgroundColor: colors.success600,
	}) as CSSProperties,

} as const;