import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const ErrorBannerStyles = {
	container: {
		width: '100%',
		minHeight: 30,
		maxWidth: '700px',
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: '0 20px',
		backgroundColor: colors.error50,
		color: colors.error600,
	} as CSSProperties,
} as const;