import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const AssociateSellsyContactStyles = {
	container: {
		width: '100%',
		backgroundColor: colors.purple50,
		padding: 10,
		...borderStyles,
		borderColor: colors.purple200,
		borderRadius: 8,
	} as CSSProperties,

	notfound: {
		width: '100%',
		backgroundColor: colors.purple200,
		...borderStyles,
		borderColor: colors.purple400,
		borderRadius: 6,
		padding: '5px 10px',
		color: colors.purple900,
	} as CSSProperties,

	create: {
		width: '100%',
		height: 50,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',

	} as CSSProperties,

	sellsyLogo: {
		width: '35px',
		padding: '5px',
		borderRadius: '5px',
		backgroundColor: colors.purple200
	} as CSSProperties,

	associateContact: {
		display: 'flex',
		justifyContent: 'flex-start',
		gap: 10,
		alignItems: 'center',
	} as CSSProperties
} as const;