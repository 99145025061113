import { CSSProperties } from 'react';

export const ClientOptionsStyles = {
  container: {
    position: 'relative',
    borderRadius: '6px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 60px 20px',
    gap: 30,
    maxHeight: '600px',
    overflow: 'scroll'
  } as CSSProperties,

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  } as CSSProperties,

  button: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  } as CSSProperties,

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
    padding: '10px'
  } as CSSProperties
} as const;