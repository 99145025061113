import React, { CSSProperties, ReactElement } from 'react';

export default class Card extends React.Component
  <
    {
      title?: string;
      text?: string;
      renderContent?: () => ReactElement
    },
    any
  >
{
  render(): ReactElement
  {
    return(
      <>
        <div style={ this.cardStyle() }>
          { this.props.title && <div style={ this.cardHeaderStyle() }>{ this.props.title }</div> }
          <div style={ this.cardBodyStyle() }>
            { this.props.text && <p style={ this.cardTextStyle() }>{ this.props.text }</p> }
            { this.props.renderContent && this.props.renderContent() }
          </div>
        </div>
      </>
    );
  }

  private cardStyle(): CSSProperties
  {
    return {
      border: '1px solid #eee',
      borderRadius: '3px'
    };
  }

  private cardHeaderStyle(): CSSProperties
  {
    return {
      backgroundColor: '#00bff',
      padding: '10px',
      borderRadius: '3px'
    };
  }

  private cardBodyStyle(): CSSProperties
  {
    return {
      padding: '10px'
    };
  }

  private cardTextStyle(): CSSProperties
  {
    return {
      margin: '0'
    };
  }
}