import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientProps, ClientState } from './Client.interface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import LocalStorageService from '@/Service/Common/LocalStorageService';

const initState: ClientState = {
	isLoading: true,
	client: {} as ClientInterface,
};

export class ClientService extends BaseComponentService<ClientProps, ClientState>
{
	private apiClientService: ApiClientService = new ApiClientService();

	constructor()
	{
		super({} as ClientProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		await this.getClient();
	}

	/**
	 * Get Connected Client
	 * @private
	 */
	private async getClient(): Promise<void>
	{
		try {

			const clientId = LocalStorageService.getAuthClient();
			const client: ClientInterface = await this.apiClientService.show(clientId);

			this.setState({ client }, () => this.setState({ isLoading: false }));

		} catch (error) {

		}
	}
}