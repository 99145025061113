import React, { ReactElement } from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import AuthFormComponent, { AuthViewState } from '../Components/Form/AuthFormComponent';
import { AuthContext } from '@/Provider/Auth/Auth.provider';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { AuthModel } from '@/Modules/Auth/Model/AuthModel';
import { UserRoleEnumOld } from '@/Enum/UserRoleEnumOld';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { Helmet } from 'react-helmet';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { LuUser } from 'react-icons/lu';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { AuthViewStyle } from '@/Modules/Auth/Style/AuthViewStyle';
import { BaseStyle } from '@/Style/BaseStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface ViewState {
	authModel: AuthModel,
	errorMessage: string | null,
	isLoading: boolean
}

export default class AuthView extends React.Component
	<any, ViewState>
{
	static contextType = AuthContext;
	context!: AuthContextType;
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Navigate
		this.navigation = props.navigation;

		// Bind
		this.handleAuthForm = this.handleAuthForm.bind(this);

		// Document title
		document.title = 'Publisur - Auth';
	}

	render(): ReactElement
	{
		return (
			<>
				<Helmet>
					<meta name="robots" content="noindex"/>
				</Helmet>
				<h2 style={ { ...FontStyle.h2(), textAlign: 'center' }}>Connexion</h2>
				{ this.state.errorMessage && (
					<div style={{ textAlign: 'center' }} className="error-message" role="alert">
						{ this.state.errorMessage }
					</div>
				)}
				{ (this.state.isLoading)
					? <LoaderComponent timeout={ 20000 } height={ 200 }/>
					: <AuthFormComponent onFormSubmit={ this.handleAuthForm } email={ this.state.authModel?.email }/>
				}
				<div style={ AuthViewStyle.mainContainer() }>
					<div style={ AuthViewStyle.border() }></div>
					<div style={ AuthViewStyle.flexJustifyContentCenter() }>ou</div>
					<div style={ AuthViewStyle.border() }></div>
				</div>
				<div style={ AuthViewStyle.textUnderForm() }>
					Je suis un entrepreneur, un
					particulier. Je ne souhaite pas de compte
				</div>
				<div style={ BaseStyle.displayFlex('center', 'center') }>
					<ButtonOld type={ 'default' } iconLeft={ <LuUser/> }
										 onClick={ () => this.props.navigation('/particular/legal-notice') }>
						Publier sans compte
					</ButtonOld>
				</div>
				{ this.authFooterJsx() }
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		this.setState({ isLoading: false });
		LocalStorageService.clearLocalStorage();
	}

	private initState(): ViewState
	{
		return {
			authModel: new AuthModel('', ''),
			errorMessage: null,
			isLoading: true,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private authFooterJsx(): ReactElement
	{
		return (
			<>
				<div className="auth-footer" style={ { marginTop: 100 } }>
					<div 	style={{ textAlign: 'center', fontSize: 12, color: CssVariableEnum['--color-grey-900'], marginBottom: 10, textDecoration: 'underline'}}>
						<Link
							to="/ask-forgot-password"
						>
							Mot de passe oublié ?
						</Link>
					</div>
					<div style={{ textAlign: 'center', fontSize: 10, color: CssVariableEnum['--color-grey-500'], marginBottom: 4}}>
						Vous souhaitez créer un compte ? Contacter notre équipe.
					</div>
					<div>
						<div style={{ textAlign: 'center', fontSize: 10, color: CssVariableEnum['--color-grey-500']}}>
							En cliquant sur les boutons de connexion ci-dessus, vous reconnaissez avoir lu, compris et accepté
							les <Link to=""> Conditions générales</Link> et la <Link to=""> Politique de confidentialité </Link> <br/>
							de Publisur.
						</div>
					</div>
				</div>
			</>
		);
	}

	private async handleAuthForm(formData: AuthViewState)
	{
		this.setState({ isLoading: true });

		await this.context.login(formData.email, formData.password)
			.then((response) =>
			{
				// Error message reset
				this.setState({ errorMessage: null, authModel: { email: formData.email, password: '' } });
				// Navigate by user role
				this.navigateByUserRole();
				this.setState({ isLoading: false });
			})
			.catch((error: any) =>
			{
				// Set error message
				this.setState({ errorMessage: error.message, isLoading: false, authModel: { email: formData.email, password: '' } });
			})
		;
	}

	private navigateByUserRole(): void
	{
		const userLocalStorageData: any = LocalStorageService.getUser();

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnumOld.ROLE_USER) {
			this.navigation('/auth/client');
		}

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnumOld.ROLE_SUPER_ADMIN) {
			this.navigation('/auth/company');
		}

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnumOld.ROLE_ADMIN) {
			this.navigation('/admin');
		}

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnumOld.ROLE_DEVELOPER) {
			this.navigation('/admin');
		}
	}

	//</editor-fold>
}
