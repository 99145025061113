import React, { Component, ReactElement } from 'react';
import { LegalNoticeDocumentsProps, LegalNoticeDocumentsState } from './LegalNoticeDocuments.interface';
import { LegalNoticeDocumentsService } from './LegalNoticeDocuments.service';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { UserService } from '@/Modules/App/Services/User/User.service';
import {
	LegalNoticeDocumentsStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeDocuments/LegalNoticeDocuments.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalContext } from '@/Provider/ModalProvider';
import BlockSendToComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockSendToComponent';
import CreatePaymentFormComponent from '@/Modules/Payment/Components/CreatePaymentFormComponent';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class LegalNoticeDocuments extends Component<LegalNoticeDocumentsProps, LegalNoticeDocumentsState>
{
	private legalNoticeDocumentsService = new LegalNoticeDocumentsService();

	constructor(props: LegalNoticeDocumentsProps)
	{
		super(props);

		// Config service
		this.legalNoticeDocumentsService.setProps(this.props);
		this.legalNoticeDocumentsService.subscribeState(this);

		// State
		this.state = this.legalNoticeDocumentsService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeDocumentsService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeDocumentsService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		// Props | Styles
		const { legalNotice } = this.props;
		const styles = LegalNoticeDocumentsStyles;

		// Const
		const certificateFiles = this.state.legalNoticeFiles
			.filter((file: any) => file.type !== 'QUOTE' && file.type !== 'INVOICE' && file.type !== 'CREDIT_NOTE');

		const quotes = legalNotice.legalNoticeFiles.filter((file: any) => file.type === 'QUOTE');
		const billings = legalNotice.legalNoticeFiles.filter((file: any) => file.type === 'INVOICE');
		const creditNotes = legalNotice.legalNoticeFiles.filter((file: any) => file.type === 'CREDIT_NOTE');
		const certificate = certificateFiles.filter((file: any) => file.type === 'CERTIFICATE');
		const webCertificate = certificateFiles.filter((file: any) => file.type === 'WEB_CERTIFICATE');
		const hasCertificate = certificateFiles.some((file: any) => file.type === 'CERTIFICATE');
		const hasDigitalCertificate = certificateFiles.some((file: any) => file.type === 'WEB_CERTIFICATE');
		const isPlanned = legalNotice.status.toString() === PublishStateEnum.PLANNED.value;
		const isPublish = legalNotice.status.toString() === PublishStateEnum.PUBLISH.value;
		const isPaid = legalNotice.paymentStatus.toString() === PaymentStateEnum.PAID.value;
		const isSuperAdminOrAdmin = UserService.isAdmin() || UserService.isSuperAdmin();

		return (
			<div style={ styles.container }>
				{ hasCertificate && this.blockDocumentRender('Attestation', certificate, {
					label: 'Attestation',
					value: 'certificate'
				}) }
				{ hasDigitalCertificate && this.blockDocumentRender('Certificat Numérique', webCertificate, {
					label: 'Certificat Numérique',
					value: 'invoice'
				}) }
				{ this.blockDocumentRender('Factures', billings, { label: 'Facture', value: 'invoice' }) }
				{ (isPlanned || isPublish) && (!isPaid) && (isSuperAdminOrAdmin) && (this.blockToPaidInvoice(billings[0])) }
				{ this.blockDocumentRender('Devis', quotes, { label: 'Devis', value: 'estimate' }) }
				{ this.blockDocumentRender('Avoir', creditNotes, { label: 'Avoir', value: 'credit' }) }
			</div>
		);
	}

	private blockDocumentRender(title: string, documents: any[], data: { label: string, value: string }): ReactElement
	{
		const fieldsMap: { [key: string]: string[] } = {
			all: ['invoice', 'certificate', 'certificate-web', 'receipt', 'credit'],
			estimate: ['invoice', 'quote'],
			certificate: ['certificate'],
			certificateWeb: ['certificate-web'],
			invoice: ['invoice'],
			credit: ['credit']
		};

		const fieldsToShow = fieldsMap[data.value] || ['estimate', 'invoice', 'certificate', 'certificate-web', 'receipt', 'credit'];

		return (
			<>
				<ModalContext.Consumer>
					{ (modalContext) => (
						<div>
							<div style={ LegalNoticeDocumentsStyles.title }>
								{ title }
							</div>
							{ documents.length > 0 ? (
								documents.map((file: any) => (
									<div
										key={ file.name }
										style={ {
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											marginBottom: '10px'
										} }
									>
										<div style={ FontStyles.textHelp }>{ file.name }</div>
										<div style={ LegalNoticeDocumentsStyles.actions }>
											<Button
												variant={ 'document' }
												iconName={ 'LuFileOutput' }
												onClick={ () => this.legalNoticeDocumentsService.getFile(file) }
											/>
											<Button
												variant={ 'secondary' }
												iconName={ 'LuSend' }
												onClick={ (event: any) =>
												{
													event.stopPropagation();
													modalContext?.content(
														`Renvoi des documents (${ title })`,
														<>
															<div style={ { padding: '0 15px', marginBottom: 10, width: 800 } }>
																<BlockSendToComponent
																	isDisplayBlock={ true }
																	selectedClient={ this.props.legalNotice.client }
																	sendTo={ this.props.legalNotice.sendTo }
																	onSelectionMail={ this.legalNoticeDocumentsService.onSelectionMail.bind(this) }
																	fieldsToShow={ fieldsToShow }
																/>
															</div>

															<div style={ {
																display: 'flex',
																marginTop: 20,
																justifyContent: (this.state.isSendMailLoading) ? 'space-between' : 'end',
																marginBottom: 10,
																marginRight: 15
															} }>
																<Button
																	variant={ 'primary' }
																	label={ 'Renvoyer les documents' }
																	onClick={ (event: any) => this.legalNoticeDocumentsService.sendBackEmailApi(event, data, modalContext, this.state.sendBackSendTo) }
																	disabled={ false }
																/>
															</div>
														</>
													);
												} }
											/>
										</div>
									</div>
								))
							) : (
								<div
									style={ {
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginBottom: '10px'
									} }
								>
									<div style={ FontStyles.textHelp }>Aucun document</div>
									<Button
										variant={ 'document' }
										iconName={ 'LuFileOutput' }
										onClick={ () => null }
										disabled={ true }
									/>
								</div>
							) }
						</div>
					) }
				</ModalContext.Consumer>
			</>
		);
	}

	private blockToPaidInvoice(billing: any): ReactElement
	{
		return (
			<ModalContext.Consumer>
				{ (modalContext) => (
					<div style={ { display: 'inline-flex', justifyContent: 'flex-end' } }>
						<Button
							variant={ 'secondary' }
							label={ 'Régler la facture' }
							onClick={ (event) =>
							{
								event.stopPropagation();
								modalContext?.content(
									`Régler la facture: ${ billing.name }`,
									<CreatePaymentFormComponent
										legalNotice={ this.props.legalNotice }
										onCreate={ this.legalNoticeDocumentsService.handleCreatePayment.bind(this) }
										modalContext={ modalContext }
										billing={ billing }
									/>
								);
							} }
						/>
					</div>
				) }
			</ModalContext.Consumer>
		);
	}
}

export default withGlobalContext(LegalNoticeDocuments);