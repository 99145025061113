import { CSSProperties } from 'react';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import { borderStyles, boxShadows } from '@/Modules/App/Style/Variables/Variables.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';

export const ContextSwitcherStyles = {
	container: {
		position: 'relative',
		width: '100%',
	} as CSSProperties,

	buttonContainer: {
		height: 32,
		width: '100%',
		...borderStyles,
		borderRadius: 8,
		padding: '8px 12px',
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	} as CSSProperties,

	openMenuContainer: (isOpen: boolean) => ({
		position: 'absolute',
		visibility: (isOpen) ? 'visible' : 'hidden',
		zIndex: 10,
		top: 30,
		width: 230,
		...boxShadows.main,
		...CardStyles.cardContainer(),
		opacity: isOpen ? 1 : 0,
		transform: isOpen ? 'scale(1)' : 'scale(0.95)',
		transition: 'opacity 270ms ease, transform 270ms ease',
	}) as CSSProperties,

	openMenuLi: {
		padding: '2px 10px'
	},

	hoveredButton: {
		borderRadius: 6,
		backgroundColor: colors.gray100,
		color: themeColors.textPrimary
	} as CSSProperties,

	hoveredItem: {
		borderRadius: 6,
		backgroundColor: colors.gray100,
		color: colors.blueRibbon500
	} as CSSProperties,

} as const;