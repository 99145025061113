import { HoveredProps } from './Hovered.interface';
import { CSSProperties } from 'react';

export const HoveredStyles = {
	hover: (isHovered: boolean, props: HoveredProps<any>) => ({
		backgroundColor: (isHovered && props.backgroundHoveredColor) ? props.backgroundHoveredColor : 'inherit',
		color: isHovered ? props.textHoveredColor : 'inherit',
		borderRadius: isHovered ? 8 : 0,
		borderColor: isHovered ? props.borderColorHovered : 'inherit',
		cursor: isHovered ? 'pointer' : 'default',
	}) as CSSProperties,
} as const;
