import React, { Component, createRef, ReactElement } from 'react';
import { EditableProps, EditableState } from './Editable.interface';
import { EditableService } from './Editable.service';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { EditableStyles } from '@/Modules/App/Components/Library/Editable/Editable.styles';
import { UserService } from '@/Modules/App/Services/User/User.service';

class Editable extends Component<EditableProps, EditableState>
{
	private editableService = new EditableService();
	private containerRef = createRef<HTMLDivElement>();

	constructor(props: EditableProps)
	{
		super(props);

		// Config service
		this.editableService.setProps(this.props);
		this.editableService.subscribeState(this);

		// State
		this.state = this.editableService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.editableService.init();
	}

	componentWillUnmount(): void
	{
		this.editableService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { children } = this.props;
		const { isEditable } = this.state;
		const isAdmin = UserService.isAdmin() || UserService.isSuperAdmin();

		return (
			<div ref={ this.containerRef }>
				<div style={ EditableStyles.buttons }>
					{isEditable ? (
						<>
							<Button
								label="Enregistrer"
								variant="primary"
								onClick={() => {
									this.editableService.saveEdit();
									this.props.onEdit();
								}}
							/>
							<Button
								label="Annuler"
								variant="secondary"
								onClick={this.editableService.cancelEdit}
							/>
						</>
					) : (
						isAdmin &&
						<Button
							label="Modifier"
							variant="inline"
							onClick={this.editableService.toggleEdit}
						/>
					)}
				</div>

				{ typeof children === 'function'
					? children({
						isEditable: this.state.isEditable,
						toggleEdit: this.editableService.toggleEdit,
						saveEdit: this.editableService.saveEdit,
					})
					: null }
			</div>
		);
	}
}

export default Editable;