import { CSSProperties } from 'react';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const _AlertsStyles = {
	container: (dynamicColor: { [key: string]: string }) => ({
		width: '100%',
		padding: '8px 15px',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: 8,
		backgroundColor: dynamicColor[100],
		color: dynamicColor[600],
		...borderStyles,
		borderColor: dynamicColor[300],
		fontWeight: 500,
		borderRadius: 8,
	}) as CSSProperties,
} as const;
