import React, { Component, ReactElement } from 'react';
import { DashboardAdminService } from '@/Modules/Dashboard/Admin/DashboardAdmin.service';
import { DashboardAdminProps, DashboardAdminState } from '@/Modules/Dashboard/Admin/DashboardAdmin.interface';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Skeleton from 'react-loading-skeleton';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Subtitle from '@/Modules/App/Components/Atom/Title/Subtitle';
import CardCounter from '@/Modules/App/Components/Card/Counters/CardCounter';
import SimpleBar from '@/Modules/App/Components/Library/Chart/SimpleBar/SimpleBar';

class DashboardAdmin extends Component<DashboardAdminProps, DashboardAdminState>
{
  private dashboardAdminService: DashboardAdminService = new DashboardAdminService();

  //<editor-fold desc="Ctor" defaultstate="collapsed">

  constructor(props: DashboardAdminProps)
  {
    super(props);

    // Config service
    this.dashboardAdminService.setProps(props);
    this.dashboardAdminService.subscribeState(this);

    // State & props
    this.state = this.dashboardAdminService.getState();

    // Document title
    document.title = 'Publisur - ADMIN - Dashboard';
  }

  //</editor-fold>

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.dashboardAdminService.init();
  }

  async componentDidUpdate(prevProps: DashboardAdminProps): Promise<void>
  {
    if (prevProps.authCompanyContext?.authCompany !== this.props.authCompanyContext?.authCompany) {
      await this.dashboardAdminService.init();
    }
  }

  componentWillUnmount(): void
  {
    this.dashboardAdminService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <HeroSection title="Tableau de bord" icon={ null }/>
        <div style={ { marginBottom: 20, width: '100%', marginTop: 30 } }>
          { this.state.isLoading
            ? <Skeleton width={ 300 } height={ 20 }/>
            : <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Title type={ 'h6' } style={ { marginBottom: 4, marginTop: 0 } }>
                    👋
                    Bonjour { this.state.userConnected&&this.dashboardAdminService.handleUserCivility(this.state.userConnected.civility) }
                    { this.state.userConnected&&<> { this.state.userConnected.firstname } </> }
                    { this.state.userConnected&&<> { this.state.userConnected.lastname } </> }
                  </Title>
                  <Subtitle>Bienvenue sur votre tableau de bord</Subtitle>
                </div>

                <div style={ { display: 'flex', justifyContent: 'center', gap: 20 } }>
                  {/* Card counter LegalNotices */ }
                  <CardCounter
                    isLoading={ this.state.isLoading }
                    title={ 'Annonces Légales' }
                    stateCounter={ this.state.countLegalNotices }
                    isActiveInactiveCounter={ false }
                  />

                  {/* Card counter Clients */ }
                  <CardCounter
                    isLoading={ this.state.isLoading }
                    title={ 'Donneurs d\'ordres' }
                    stateCounter={ this.state.countClients }
                    isActiveInactiveCounter={ true }
                  />

                  {/* Card counter Users */ }
                  <CardCounter
                    isLoading={ this.state.isLoading }
                    title={ 'Utilisateurs' }
                    stateCounter={ this.state.countUsers }
                    isActiveInactiveCounter={ true }
                  />
                </div>
              </div>

              <hr/>

              <Title type={ 'h6' } style={ { marginBottom: 4, marginTop: 0 } }>
                Statistiques
              </Title>
              <Subtitle style={ { marginBottom: 20 } }>
                Statistiques annonces légales.
              </Subtitle>

              <SimpleBar
                chartTitle={
                  <>
                    Ratio d'annonces légales entre
                    <b style={ { color: '#8884d8' } }> HJE</b>
                    <span> / </span>
                    Annonces <b style={ { color: '#82ca9d' } }>EXTERNE</b>
                  </>
                }
                area1={ { title: 'HJE', key: 'intern', name: 'Annonces Publisur', color: '#8884d8' } }
                area2={ { title: 'Externe', key: 'extern', name: 'Annonces Externes', color: '#82ca9d' } }
                data={ this.state.chartData }
                isLoading={ this.state.isLoading }
              />
            </>
          }
        </div>
      </>
    );
  }
}

export default withGlobalContext(DashboardAdmin);