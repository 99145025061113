import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { OffcanvaProps, OffcanvaState } from '@/Provider/Offcanva/Offcanva.interface';
import React, { ReactElement } from 'react';

const initState: OffcanvaState = {
  isLoadingFull: false,
  isLoadingOverlay: false,
  offcanvaStack: [],
};

export class OffcanvaService extends BaseComponentService<OffcanvaProps, OffcanvaState>
{
  constructor()
  {
    super({} as OffcanvaProps, initState);

    // Bind methods for usage in the component
    this.isOpen = this.isOpen.bind(this);
    this.offcanvaContent = this.offcanvaContent.bind(this);
    this.isLoadingUpdated = this.isLoadingUpdated.bind(this);
    this.clearOffcanvas = this.clearOffcanvas.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  /**
   * Handle OPEN / CLOSED multiple offcanvas windows
   * @param isOpen
   */
  isOpen(isOpen: boolean): void
  {
    this.setState(prevState => {
      const offcanvaStack = [...prevState.offcanvaStack];
      const lastOffcanva = offcanvaStack[offcanvaStack.length - 1];

      if (lastOffcanva) {
        lastOffcanva.isOpen = isOpen;
      }

      return { offcanvaStack };
    });
  }

  /**
   * Set loading overlay
   * @param isLoadingOverlay
   */
  isLoadingOverlay(isLoadingOverlay: boolean): void
  {
    this.setState({ isLoadingOverlay });
  }

  /**
   * Handle Offcanva Content
   * @param title - The title of the Offcanva
   * @param content - The content of the Offcanva
   * @param loadingDuration - Optional duration for the loading state (default: 300ms)
   */
  offcanvaContent(title: string | ReactElement | null, content: ReactElement, loadingDuration: number = 300): void
  {
    this.setState((prevState) => {
      let offcanvaStack = [...prevState.offcanvaStack];

      // Remove existing offcanva with the same title if found
      const existingIndex = offcanvaStack.findIndex((offcanva) => offcanva.title === title);
      if (existingIndex !== -1) {
        offcanvaStack.splice(existingIndex, 1);
      }

      // Add the new Offcanva to the stack
      offcanvaStack = [
        ...offcanvaStack,
        {
          isOpen: true,
          title,
          content,
          offcanvaRef: React.createRef<HTMLDivElement>(),
          isLoading: true,
        },
      ];

      return { offcanvaStack };
    });

    // Optional loading simulation
    setTimeout(() => {
      this.setState((prevState) => {
        const offcanvaStack = [...prevState.offcanvaStack];
        const lastOffcanva = offcanvaStack[offcanvaStack.length - 1];

        // Stop loading for the newly opened Offcanva
        if (lastOffcanva && lastOffcanva.title === title) {
          lastOffcanva.isLoading = false;
        }

        return { offcanvaStack };
      });
    }, loadingDuration);
  }

  /**
   * Update loading state of the last Offcanva
   * @param isLoading
   */
  isLoadingUpdated(isLoading: boolean): void
  {
    this.setState((prevState) => {
      if (prevState.offcanvaStack.length > 0) {
        const offcanvaStack = [...prevState.offcanvaStack];
        const lastOffcanva = offcanvaStack[offcanvaStack.length - 1];
        lastOffcanva.isLoading = isLoading;
        return { offcanvaStack };
      }
      return prevState;
    });
  }

  /**
   * Handle click outside the Offcanva to close it
   * @param event
   */
  handleClickOutside(event: MouseEvent): void
  {
    const lastOffcanvaIndex = this.state.offcanvaStack.length - 1;
    const lastOffcanva = this.state.offcanvaStack[lastOffcanvaIndex];
    if (lastOffcanva && lastOffcanva.offcanvaRef?.current && !lastOffcanva.offcanvaRef.current.contains(event.target as Node)) {
      this.setState((prevState) => {
        const offcanvaStack = prevState.offcanvaStack.slice(0, -1);
        return { offcanvaStack };
      });
    }
  }

  /**
   * Clear all Offcanvas in the stack
   */
  clearOffcanvas(): void
  {
    this.setState({ offcanvaStack: [] });
  }
}