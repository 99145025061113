import React, { ReactElement } from 'react';
import * as Icons from 'react-icons/lu';
import { Link } from 'react-router-dom';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { MenuItemPros } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.interface';
import { MenuItemStyles } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.styles';
import { withLocation } from '@/Modules/HOC/withLocation';
import { LuChevronDown } from 'react-icons/lu';

class MenuItem extends React.Component<MenuItemPros, any>
{

  render(): ReactElement
  {
    const IconComponent = Icons[this.props.iconName!];
    const isActive = this.props.location.pathname === this.props.urlPath;

    return (
      <>
        { this.props.type === 'separatorItem' && (
          <div style={ MenuItemStyles.separatorItem }>
            <LuChevronDown size={ 14 }/>
            <span>{ this.props.label }</span>
          </div>
        ) }

        { this.props.type === 'menuItem' && (
          <Hovered<HTMLDivElement>>
            { ({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
              <Link to={ this.props.urlPath! }>
                <div
                  ref={ ref }
                  style={{
                    ...MenuItemStyles.item,
                    ...(isHovered ? MenuItemStyles.hoveredItem : {}),
                  }}
                  onMouseEnter={ onMouseEnter }
                  onMouseLeave={ onMouseLeave }
                  onClick={ this.props.onClick }
                >
                  { IconComponent && (
                    <IconComponent fontSize={ 18 } color={ isActive ? colors.blueRibbon500 : colors.gray600 } />
                  )}
                  <span style={ MenuItemStyles.text(isActive) }>{ this.props.label }</span>
                </div>
              </Link>
            ) }
          </Hovered>
        ) }
      </>
    );
  }
}

export default withLocation(MenuItem);
