import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import LegalNoticeSendToFormComponent, {
	SendToSelections
} from '@/Modules/LegalNotice/Components/Form/LegalNoticeSendToFormComponent';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

interface ComponentProps
{
	isDisplayBlock: boolean,
	selectedClient: ClientInterface | null,
	onSelectionMail: (selections: SendToSelections) => void
	sendTo: {},
	fieldsToShow?: string[]
}

export default class BlockSendToComponent extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<>
				{ this.props.isDisplayBlock &&
          <>
            <SectionCollapsed title={ 'Envoi des documents' } isCollapsed={ false } highlightColor={ colors.gray100 }>
              <LegalNoticeSendToFormComponent
                selectedClient={ this.props.selectedClient }
                sendTo={ this.props.sendTo }
                onSelections={ this.props.onSelectionMail }
                fieldsToShow={ this.props.fieldsToShow }
              />
            </SectionCollapsed>
          </>
				}
			</>
		);
	}
}