import React, { CSSProperties, ReactElement, ReactNode } from 'react';

export default class TableOld extends React.Component
	<{ children: ReactNode, style?: CSSProperties, id?: string }, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					width: '100%',
					flexGrow: 1,
					flexShrink: 0,
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
					...this.props.style
				} } id={ this.props.id }>
					{ this.props.children }
				</div>
			</>
		);
	}
}