import { useLocation } from 'react-router-dom';
import { ReactElement } from 'react';

export function withLocation(Component: React.ComponentType<any>) {
	function ComponentWithLocationProp(props: any): ReactElement {
		const location = useLocation();
		return <Component {...props} location={location} />;
	}

	return ComponentWithLocationProp;
}