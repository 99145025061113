import React, { ReactElement } from 'react';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { ApiAdminCompanyService } from '@/Service/Admin/ApiAdminCompanyService';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { AuthCompanyService } from '@/Modules/AuthCompany/AuthCompany.service';
import { AuthCompanyState } from '@/Modules/AuthCompany/AuthCompany.interface';

export const AuthCompanyContext = React.createContext<AuthCompanyContextType|null>(null);

export class AuthCompanyProvider extends React.Component<any, AuthCompanyState>
{
  companyService: ApiAdminCompanyService;
  authCompanyService: AuthCompanyService;
  userService: ApiAdminUserService;

  constructor(props: any)
  {
    super(props);

    // Services
    this.companyService = new ApiAdminCompanyService();
    this.userService = new ApiAdminUserService();
    this.authCompanyService = new AuthCompanyService();

    // config
    this.authCompanyService.setProps(this.props);
    this.authCompanyService.subscribeState(this);

    // State
    this.state = this.authCompanyService.getState();
  }

  //<editor-fold desc="useEffects Method" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.authCompanyService.init();
  }

  componentWillUnmount(): void
  {
    this.authCompanyService.unsubscribe();
  }

  // </editor-fold>

  render(): ReactElement
  {
    return (
      <AuthCompanyContext.Provider value={{
        authCompany: this.state.authCompany,
        refreshCompany: this.authCompanyService.refreshCompany,
        companies: this.state.companies,
        storedCompany: this.state.authCompany,
        fetchCompanies: this.authCompanyService.fetchCompanies,
        isLoading: this.state.isLoading
      }}>
        { this.props.children }
      </AuthCompanyContext.Provider>
    );
  }
}