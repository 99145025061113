import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { AuthCompanyState } from '@/Modules/AuthCompany/AuthCompany.interface';
import { ApiAdminCompanyService } from '@/Service/Admin/ApiAdminCompanyService';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { UserService } from '@/Modules/App/Services/User/User.service';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { redirect } from 'react-router-dom';
import { CompanyInterface } from '@/Modules/Company/Interface/CompanyInterface';
import { GlobalContextInterface } from '@/Context/Global/Global.interface';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';

const initState: AuthCompanyState = {
  authCompany: null,
  isLoading: false,
  companies: [],
  isCompanyFetch: false,
  errorMessage: null,
};

export class AuthCompanyService extends BaseComponentService<any, AuthCompanyState>
{
  private companyService = new ApiAdminCompanyService();
  private userService = new ApiAdminUserService();
  private currentUser: UserInterface | null = null;

  constructor()
  {
    super({} as any, initState);

    // Bind
    this.fetchCompanies = this.fetchCompanies.bind(this);
    this.refreshCompany = this.refreshCompany.bind(this);
    this.onSwitchCompany = this.onSwitchCompany.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
  }

  /**
   * Get the current user from the local storage or API.
   * @return Promise<UserInterface>
   */
  async getCurrentUser(): Promise<UserInterface>
  {
    if (!this.currentUser) {
      const userToken = LocalStorageService.getUser();
      if (userToken) {
        this.currentUser = await this.userService.show(userToken.userId);
      }
    }

    if (!this.currentUser) {
      throw new Error('Utilisateur non trouvé');
    }

    return this.currentUser;
  }

  /**
   * Fetch and Set Companies
   * @return void
   */
  async fetchCompanies(): Promise<void>
  {
    this.setState({ isLoading: true });
    try {
      const companies = await this.companyService.list();
      this.setState({ companies });
    } catch (error: any) {
      this.setState({ errorMessage: 'Error fetching companies' });
    } finally {
      this.setState({ isCompanyFetch: true, isLoading: false });
    }
  }

  /**
   * Refresh Company
   * @param companyId
   * @return Promise<void>
   */
  async refreshCompany(companyId: number): Promise<void>
  {
    try {
      this.setState({ isLoading: true });
      const authCompany = await this.companyService.show(companyId);
      const user = await this.getCurrentUser();

      if (UserService.isSuperAdmin()) {
        // SUPER_ADMIN: Can switch between companies
        await this.userService.switchCompany(user.id, authCompany.id);
        this.setState({ authCompany });
        LocalStorageService.setAuthCompany(companyId);
      } else if (user.role === 'ROLE_ADMIN') {
        // ADMIN: Automatically set authCompany to their company
        if (user.company && user.company.id) {
          this.setState({ authCompany: user.company });
          LocalStorageService.setAuthCompany(user.company.id);
        } else {
          throw new Error('L\'utilisateur admin n\'a pas de société associée');
        }
      } else {
        alert("Vous n'avez pas l'autorisation nécessaire");
        redirect('/auth');
      }

    } catch (error) {
      console.error('Error selecting company:', error);
      this.setState({ errorMessage: 'Error selecting company' });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  /**
   * Switch and Refresh current company
   * @param company
   * @param globalContext
   */
  async onSwitchCompany(company: CompanyInterface, globalContext: GlobalContextInterface): Promise<void>
  {
    try {
      if (UserService.isSuperAdmin()) {
        await this.refreshCompany(company.id);

        globalContext.flashMessageContext?.flashMessage(
          'Choix de l\'entreprise réussi',
          `Vous êtes maintenant connecté sur ${ company.name }`,
          'success'
        );

        globalContext.navigation('/admin');
      } else {
        globalContext.flashMessageContext?.flashMessage('Vous n\'avez pas l\'autorisation de changer d\'entreprise', 'error');
      }
    } catch (error: any) {
      console.error('Error changing company:', error);
      globalContext.flashMessageContext?.flashMessage('Erreur lors du changement d\'entreprise', 'error');
    }
  }

  /**
   * Init Method into ComponentDidMount
   * @return Promise<void>
   */
  async init(): Promise<void>
  {
    this.setState({ isLoading: true });

    try {
      const user = await this.getCurrentUser();
      if (user.role === 'ROLE_ADMIN') {
        // Set authCompany directly for ADMIN
        await this.refreshCompany(user.company.id);
      } else {
        // SUPER_ADMIN fetch the stored company or default to first available
        const storedCompanyId = LocalStorageService.getAuthCompanyId();
        if (storedCompanyId) {
          await this.refreshCompany(storedCompanyId);
        }
        await this.fetchCompanies();
      }
    } catch (error: any) {
      console.error('Error during init:', error);
      this.setState({ errorMessage: 'Error on Init AuthCompany', isLoading: false });
    } finally {
      this.setState({ isLoading: false });
    }
  }
}
