import { CSSProperties } from 'react';

export const ClientDetailsStyles = {
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		columnGap: 40,
	} as CSSProperties,

	column: {
		display: 'flex',
		flexDirection: 'column',
		gap: 3
	} as CSSProperties,

	title: {
		fontWeight: 700,
	},

	line: {
		display: 'flex',
		justifyContent: 'space-between'
	} as CSSProperties,

	editAction: {
		height: 40,
		width: '100%',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end'
	} as CSSProperties,

} as const;