import { CSSProperties } from 'react';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

export const HeroSectionStyles = {

	base: {
		height: 100,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSProperties,

	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
		background: themeColors.background,
		margin: '0 auto',
	} as CSSProperties,

	blockTitle: {
		display: 'flex',
		alignItems:'center',
		justifyContent: 'space-between',
		height: '100%'
	} as CSSProperties,

	title: {
		...FontStyles.h1,
	} as CSSProperties,
} as const;