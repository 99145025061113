import { CSSProperties } from 'react';

export const CreateClientFormStyles = {
	modal: {
		width: 700,
	} as CSSProperties,

	container: {
		position: 'relative',
		borderRadius: '6px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: 20,
		gap: 30,
	} as CSSProperties,

	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	} as CSSProperties,

	siren: {
		display: 'flex',
		alignItems: 'flex-end',
		gap: 20
	} as CSSProperties,

} as const;