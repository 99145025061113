import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export const AppConfigAdminStyles = {
  container: {
    position: 'relative',
    borderRadius: '6px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 60px 20px',
    gap: 30,
    maxHeight: '600px',
    overflow: 'scroll'
  } as CSSProperties,
  cardContainer: {
    backgroundColor: CssVariableEnum['--color-white'],
    borderRadius: '3px',
    padding: '20px 10px',
    border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
  } as CSSProperties
} as const;