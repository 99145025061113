import React, { Component, ReactElement } from 'react';
import { LegalNoticeTableListProps, LegalNoticeTableListState } from './LegalNoticeTableList.interface';
import { LegalNoticeTableListService } from './LegalNoticeTableList.service';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Table from '@/Modules/App/Components/Library/Table/Table';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import Pagination from '@/Modules/App/Components/Library/Pagination/Pagination';
import { ListTableManagerService } from '@/Modules/App/Components/ListTableManager/ListTableManager.service';

class LegalNoticeTableList extends Component<LegalNoticeTableListProps, LegalNoticeTableListState>
{
	private legalNoticeTableListService = new LegalNoticeTableListService();
	private dataTableManagerService = new ListTableManagerService();

	constructor(props: LegalNoticeTableListProps)
	{
		super(props);

		// Config service
		this.legalNoticeTableListService.setProps(this.props);
		this.legalNoticeTableListService.subscribeState(this);

		// State
		this.state = this.legalNoticeTableListService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeTableListService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeTableListService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<Table
					columns={ this.legalNoticeTableListService.buildColumnHeader() }
					list={ this.state.list }
					filterParams={ this.state.filterParams }
					onOrderBy={ () => null }
					onRowClick={ (item: LegalNoticeInterface) =>
					{
						this.props.navigation('/admin/legal-notices/' + item?.id + `?from=${ window.location.pathname }`);
					} }
				/>

				<Pagination
					tag={ 'legalNotice' }
					currentPage={ this.state.filterParams.page }
					itemsPerPage={ this.state.filterParams.itemsPerPage }
					list={ this.state.list }
					onPageChange={ this.dataTableManagerService.handlePageChange }
				/>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticeTableList);