import React, { ReactElement } from 'react';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import InputMedium from '@/Modules/App/Components/Atom/Form/Input/InputMedium';
import { FormBuilderInputTypeEnum } from '@/Enum/FormBuilderInputTypeEnum';
import { ApiAdminFormBuilderInputOptionService } from '@/Service/Admin/ApiAdminFormBuilderInputOptionService';
import { FormBuilderOptionsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderOptionsInterface';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import Input from '@/Modules/App/Components/Library/Input/Input';
import Textarea from '@/Modules/App/Components/Atom/Form/Textarea';
import SelectComponentOld from '@/Modules/App/Components/Atom/Form/Select/SelectComponentOld';
import FormBuilderStyle from '@/Modules/App/Style/Components/FormBuilderStyle';
import FormBuilderSectionInputOptionsComponent
	from '@/Modules/FormBuilder/Components/FormBuilderSectionInputOptionsComponent';

interface ComponentProps
{
	sectionId: number | null
	input: FormBuilderInputsInterface | null,
	refreshInputs: (sectionId: number) => Promise<any>,
	isOpen: (isOpen: boolean) => void,
	onCreateInput: (inputData: Partial<FormBuilderInputsInterface>, sectionId: number) => Promise<void>,
	onUpdateInput: (editInputData: Partial<FormBuilderInputsInterface>, inputId: number) => Promise<any>
	onDelete: (section: FormBuilderInputsInterface) => void,
	onCreateInputOption: (optionLabel: {
		label: string
	}, inputId: number) => Promise<FormBuilderOptionsInterface | null | undefined>
	onEditInputOption: (editInputOptionData: any, formBuilderInputId: number, formBuilderInputOptionId: number) => Promise<any>
	onDeleteInputOption: (inputId: number, optionId: number) => Promise<any>
}

interface ComponentState
{
	isLoading: boolean,
	formData: FormBuilderInputsInterface,
	options: FormBuilderOptionsInterface[],
	errorMessage: string | null
}

export default class FormBuilderSectionInputComponent extends React.Component<ComponentProps, ComponentState>
{
	formBuilderInputOptionService: ApiAdminFormBuilderInputOptionService;
	styles: { [key: string]: React.CSSProperties };

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Service
		this.formBuilderInputOptionService = new ApiAdminFormBuilderInputOptionService();
		this.styles = FormBuilderStyle.input();

		// Bind
		this.setFormDataPartial = this.setFormDataPartial.bind(this);
		this.handleCreateOrEditInput = this.handleCreateOrEditInput.bind(this);
	}

	render(): ReactElement
	{
		return (
			this.createInputFormRender()
		);
	}

	// <editor-fold desc="State / Component Mount" defaultstate="collapsed">

	componentDidMount(): void
	{
		if (this.props.input) {
			this.setState({
				formData: {
					id: this.props.sectionId as number,
					label: this.props.input.label || '',
					type: this.props.input.type,
					placeholder: this.props.input.placeholder || '',
					cmsContent: this.props.input.cmsContent,
					isRequired: this.props.input.isRequired,
					textHelp: this.props.input.textHelp || '',
					sortOrder: this.props.input.sortOrder,
					formBuilderOptions: this.props.input.formBuilderOptions || [],
				}
			});
		}
	}

	private initState(): ComponentState
	{
		return {
			formData: {
				id: '0',
				label: '',
				type: FormBuilderInputTypeEnum.TEXT,
				placeholder: '',
				cmsContent: '',
				isRequired: true,
				textHelp: '',
				formBuilderOptions: [],
			} as FormBuilderInputsInterface,
			isLoading: false,
			options: [],
			errorMessage: null,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content Input methods" defaultstate="collapsed">

	private createInputFormRender(): ReactElement
	{
		return (
			<div style={ this.styles.modalContainer }>
				<div style={ this.styles.formGrid }>
					<div style={ this.styles.formContainer }>
						<SelectComponentOld
							label={ 'Type de bouton' }
							buttonWidth={ 250 }
							listOptions={ this.buildInputType() }
							selectedValue={ this.findInputType(this.props.input?.type) }
							onSelectedOption={ (event: any) => this.setInputType(event) }
							renderOptionLabel={ (option) => option.type }
						/>

						<InputMedium
							type={ FormBuilderInputTypeEnum.SWITCH }
							label="Champ obligatoire ?"
							isChecked={ this.state.formData.isRequired }
							onChange={ (event: any) => this.setFormDataPartial({ isRequired: event.target.checked }) }
						/>

						<Input
							type={ 'text' }
							style={ { width: 250 } }
							name={ 'input-section-label' }
							label="Ajouter un label"
							placeholder=""
							value={ this.state.formData.label }
							onChange={ (event: any) => this.setFormDataPartial({ label: event.target.value }) }
						/>

						{ this.state.formData.type !== FormBuilderInputTypeEnum.SELECTOR &&
              <Input
                type={ 'text' }
                style={ { width: 250 } }
                name={ 'input-section-placeholder' }
                label="Ajouter un texte d'exemple"
                placeholder="Sera affiché comme ceci"
                value={ this.state.formData.placeholder || '' }
                onChange={ (event: any) => this.setFormDataPartial({ placeholder: event.target.value }) }
              />
						}
						{ (!this.props.input && this.state.formData.type === FormBuilderInputTypeEnum.SELECTOR) &&
              <div> Merci de valider avant les options </div>
						}
						{ this.props.input && this.state.formData.type === FormBuilderInputTypeEnum.SELECTOR &&
              <FormBuilderSectionInputOptionsComponent
                key={ this.state.isLoading.toString() }
                isLoading={ this.state.isLoading }
                sectionInput={ this.props.input }
                listOption={ this.state.formData.formBuilderOptions || [] }
                onCreate={ this.handleCreateInputOptions.bind(this) }
                onEdit={ this.handleEditInputOptions.bind(this) }
                onDelete={ this.handleDeleteInputOptions.bind(this) }
              />
						}
					</div>
					<div>
						<Textarea
							label={ 'Corps de texte' }
							style={ { height: 130, width: 300 } }
							name={ 'text-for-legal-notice' }
							value={ this.state.formData.cmsContent || '' }
							onChange={ (event: any) => this.setFormDataPartial({ cmsContent: event.target.value }) }
						/>
						<div style={ this.styles.cmsContentFooterAction }>
							<ButtonOld
								label={ 'Insérer une variable' }
								type={ 'default' }
								onClick={ this.insertVariable.bind(this) }
							/>
						</div>
					</div>
				</div>

				<div style={ this.styles.modalFooter }>
					<ButtonOld
						label={ (this.props.input) ? 'Mettre à jour' : 'Créer' }
						type={ 'default-blue' }
						onClick={ async () => this.handleCreateOrEditInput() }
					/>
				</div>
			</div>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private async handleCreateOrEditInput(): Promise<void>
	{
		if (this.props.sectionId) {
			await this.props.onCreateInput(this.state.formData, this.props.sectionId);
		}
		if (this.props.input) {
			await this.props.onUpdateInput(this.state.formData, this.props.input.id as number);
		}
	}

	private async handleCreateInputOptions(label: string): Promise<void>
	{
		try {
			this.setState({ isLoading: true });

			if (this.props.input && this.props.input.id) {
				const newOption = await this.props.onCreateInputOption({ label: label }, this.props.input.id as number);

				this.setState((prevState: any) => ({
					formData: {
						...prevState.formData,
						formBuilderOptions: [...(prevState.formData.formBuilderOptions ?? []), newOption]
					}
				}));
			}
			this.setState({ isLoading: false });
		} catch (error: any) {
			console.log('Something wrong with input options creation', error.message);
			this.setState({ isLoading: false });
		}
	}

	private async handleEditInputOptions(optionData: FormBuilderOptionsInterface, optionId: number): Promise<void>
	{
		try {
			this.setState({ isLoading: true });

			if (this.props.input && this.props.input.id) {
				const response = await this.props.onEditInputOption(optionData, this.props.input.id as number, optionId);

				// Refresh Option list
				const refreshOptionList = await this.formBuilderInputOptionService.list(this.props.input.id as number);
				console.log(refreshOptionList);

				// Set formData
				this.setState((prevState: any) => ({
					formData: {
						...prevState.formData,
						formBuilderOptions: refreshOptionList
					}
				}));

				if (response && this.props.sectionId) {
					await this.props.refreshInputs(this.props.sectionId);
				}
			}

			this.setState({ isLoading: false });

		} catch (error: any) {
			console.log('Something wrong with deleted input options', error.errorMessage);
		}
	}

	private async handleDeleteInputOptions(optionId: number): Promise<void>
	{
		try {
			this.setState({ isLoading: true });

			if (this.props.input && this.props.input.id) {
				this.props.onDeleteInputOption(this.props.input.id as number, optionId);

				// Refresh Option list
				const refreshOptionList = await this.formBuilderInputOptionService.list(this.props.input.id as number);

				// Set formData
				this.setState((prevState: any) => ({
					formData: {
						...prevState.formData,
						formBuilderOptions: refreshOptionList
					}
				}));

				if (this.props.sectionId)
					await this.props.refreshInputs(this.props.sectionId);
			}

			this.setState({ isLoading: false });

		} catch (error: any) {
			console.log('Something wrong with deleted input options', error.errorMessage);
		}
	}

	private buildInputType(): { id: number, type: FormBuilderInputTypeEnum, css: string }[]
	{
		return [
			{ id: 1, type: FormBuilderInputTypeEnum.TEXT, css: 'input m-0' },
			{ id: 2, type: FormBuilderInputTypeEnum.DATE, css: 'input m-0 date' },
			{ id: 3, type: FormBuilderInputTypeEnum.SELECTOR, css: 'select m-0' },
			{ id: 4, type: FormBuilderInputTypeEnum.CHECKBOX, css: 'select m-0' },
			{ id: 5, type: FormBuilderInputTypeEnum.TEXTAREA, css: 'select m-0' },
		];
	}

	private findInputType(inputType: string | undefined): {
		id: number,
		type: FormBuilderInputTypeEnum,
		css: string
	} | null
	{
		if (inputType) {
			return this.buildInputType().find((type) => type.type === inputType) || null;
		} else {
			return null;
		}
	}

	private setFormDataPartial(partialData: Partial<ComponentState['formData']>): void
	{
		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				...partialData
			},
		}));
	}

	private setInputType(inputType: { id: number, type: FormBuilderInputTypeEnum, css: string }): void
	{
		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				type: inputType.type
			},
		}));
	}

	private insertVariable(): void
	{
		const textarea = document.getElementsByName('text-for-legal-notice')[0] as HTMLTextAreaElement;
		const startPos = textarea.selectionStart;
		const endPos = textarea.selectionEnd;

		const newText = this.state.formData?.cmsContent?.slice(0, startPos) + '{{var}}' + this.state.formData?.cmsContent?.slice(endPos);

		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				cmsContent: newText,
			},
		}), () =>
		{
			// Set the cursor position after the inserted text
			textarea.setSelectionRange(startPos + 6, startPos + 6);
			textarea.focus();
		});
	}

	//</editor-fold>

}