import { CSSProperties } from 'react';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const TableStyles = {
	container: {
		overflowX: 'auto',
	} as CSSProperties,

	table: {
		minWidth: '100%',
		maxHeight: '100%',
		borderSpacing: 0,
		borderCollapse: 'collapse',
	} as CSSProperties,

	thead: {
		wordBreak: 'break-word',
		boxSizing: 'border-box',
		position: 'sticky',
		top: 0,
		backgroundColor: colors.white,
		zIndex: 1,
	} as CSSProperties,

	thead_tr: {
		boxSizing: 'border-box',
	} as CSSProperties,

	thead_th: (column: TableHeadColumnsInterface) => ({
		width: 'auto',
		boxShadow: `inset 0 -1px 0 0 ${colors.gray200}`,
		textAlign: column.textAlign ?? 'left',
		padding: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
	}) as CSSProperties,

	thead_th_div: {
		display: 'flex',
		minHeight: 20,
		margin: 0,
		width: '100%',
		fontSize: 12,
		lineHeight: '20px',
		fontWeight: 700,
		padding: '8px 12px 8px 4px',
		boxSizing: 'border-box',
		cursor: 'pointer'
	} as CSSProperties,

	tbody: {
		boxSizing: 'border-box',
	} as CSSProperties,

	tbody_tr: (isHovered: boolean) => ({
		boxSizing: 'border-box',
		wordBreak: 'break-word',
		backgroundColor: isHovered ? colors.gray100 : 'inherit',
		cursor: 'pointer',
	}) as CSSProperties,

	tbody_td: {
		boxShadow: `inset 0 -1px 0 0 ${colors.gray200}`,
		boxSizing: 'border-box',
		whiteSpace: 'nowrap',
	} as CSSProperties,

	tbody_td_div: {
		margin: 0,
		width: '100%',
		padding: '10px 12px 10px 4px',
		lineHeight: 1,
		boxSizing: 'border-box',
	} as CSSProperties,
} as const;
