import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { InputProps, InputState } from './Input.interface';

const initState: InputState = {
	isFocused: false,
	error: null,
};

export class InputService extends BaseComponentService<InputProps, InputState>
{
	constructor()
	{
		super({} as InputProps, initState);

		// Bind
		this.handleBlur = this.handleBlur.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleChangeAllowedCharacters = this.handleChangeAllowedCharacters.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{}

	/**
	 * Handle input focus
	 * @return void
	 */
	handleFocus(): void
	{
		this.setState({ isFocused: true });
	}

	/**
	 * Handle input blur (when focus is lost)
	 * @return void

	 */
	handleBlur(): void
	{
		this.setState({ isFocused: false });
	}

	/**
	 * Handle tap Enter on Keyboard
	 * @param event
	 */
	handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
	{
		if (event.key === 'Enter') {
			this.props.onKeyDown && this.props.onKeyDown(event);
		}
	}

	/**
	 * Handle input characters
	 * @param event
	 */
	handleChangeAllowedCharacters(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const { allowedCharacters, onChange } = this.props;
		let value = event.target.value;

		if (allowedCharacters === 'digits') {
			value = value.replace(/\D/g, '');
		} else if (allowedCharacters === 'letters') {
			value = value.replace(/[^a-zA-Z]/g, '');
		} else if (allowedCharacters === 'alphanumeric') {
			value = value.replace(/[^a-zA-Z0-9]/g, '');
		}

		event.target.value = value;
		onChange!(event);
	}
}