import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  ClientLegalNoticeStatsFormProps, ClientLegalNoticeStatsFormState
} from '@/Modules/Client/Components/ClientLegalNoticeStats/Form/ClientLegalNoticeStatsForm.interface';
import { ApiClientLegalNoticeStatsService } from '@/Service/Api/ApiClientLegalNoticeStatsService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';

const initState: ClientLegalNoticeStatsFormState = {
  formData: {
    id: 0,
    year: new Date().getFullYear(),
    intern: null,
    extern: null,
    client: {} as ClientInterface
  }
}

export class ClientLegalNoticeStatsFormService extends BaseComponentService<ClientLegalNoticeStatsFormProps, ClientLegalNoticeStatsFormState>
{
  private apiAdminClientLegalNoticeStats: ApiClientLegalNoticeStatsService = new ApiClientLegalNoticeStatsService();

  constructor()
  {
    super({} as ClientLegalNoticeStatsFormProps, initState);
  }

  async init(): Promise<any>
  {}

  onCreate(state: ClientLegalNoticeStatsFormState): void
  {
    this.apiAdminClientLegalNoticeStats.create(this.props.client.id, state.formData);
    this.props.onCreate();
  }
}