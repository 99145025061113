import React, { ReactElement } from 'react';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import ContentOld from '@/Modules/App/Components/Content/ContentOld';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';

export default class DashboardView extends React.Component
{
	render(): ReactElement
	{
		return (
			<>
				<HeroSection title="Dashboard" icon={ null }/>
				<ContentOld>
					<MainWrapper>

					</MainWrapper>
				</ContentOld>
			</>
		);
	}
}
