import { CSSProperties } from 'react';

export const LegalNoticeDocumentsStyles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	} as CSSProperties,

	title: {
		fontWeight: 600
	} as CSSProperties,

	actions: {
		display: 'flex',
		gap: 5
	} as CSSProperties,

} as const;