import { CSSProperties } from 'react';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const SidebarMenuStyles = {
	container: {
		backgroundColor: colors.white,
		padding: '0 15px',
		width: '240px',
		borderRight: borderStyles.border,
		borderColor: colors.gray200
	} as CSSProperties,
	sidebar: {

	} as CSSProperties,
} as const;


