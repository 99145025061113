import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { EditableProps, EditableState } from './Editable.interface';

const initState: EditableState = {
	isEditable: false,
};

export class EditableService extends BaseComponentService<EditableProps, EditableState>
{
	constructor()
	{
		super({} as EditableProps, initState);

		// Bind
		this.toggleEdit = this.toggleEdit.bind(this);
		this.saveEdit = this.saveEdit.bind(this);
		this.cancelEdit = this.cancelEdit.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 *
	 */
	toggleEdit(): void
	{
		this.setState((prevState) => ({
			isEditable: !prevState.isEditable,
		}));
	}

	/**
	 *
	 */
	saveEdit(): void
	{
		this.setState({ isEditable: false });
	}

	/**
	 *
	 */
	cancelEdit(): void
	{
		this.setState({ isEditable: false });
	}
}