import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';

export const SellsyCompanyStyles = {
	container: {
		padding: '10px 20px',
		backgroundColor: colors.purple50,
		border: `1px solid ${colors.purple200}`,
		borderRadius: 8,
		display: 'flex',
		flexDirection: 'column',
		gap: 3,
	} as CSSProperties,

	notFound: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	} as CSSProperties,

	notFound_title: {
		fontWeight: 600,
		color: colors.purple500
	} as CSSProperties,

	notFound_actions: {
		display: 'flex',
		gap: 5
	} as CSSProperties,

	modal: {
		...ModalStyles.padding,
		width: 700,
	} as CSSProperties,

} as const;