// PhoneNumberUtils.ts

/**
 * Check if a given phone number is in a valid French format.
 * French numbers should match pattern like:
 * - 0X XX XX XX XX (10 digits)
 *
 * @param phoneNumber - The phone number to be validated.
 * @return boolean - True if the phone number is valid, false otherwise.
 */
export function isValidFrenchPhoneNumber(phoneNumber: string): boolean {
	// Define the regex pattern to match French phone numbers with exactly 10 digits, optionally separated by spaces
	const frenchPhonePattern = /^0\d(\d{8}|\s?\d{2}(\s?\d{2}){3})$/;

	return frenchPhonePattern.test(phoneNumber.trim());
}