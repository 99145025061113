import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { CollaboratorInviteFormProps, CollaboratorInviteFormState } from './CollaboratorInviteForm.interface';
import InviteCollaboratorModel from '@/Modules/Collaborator/Model/InviteCollaboratorModel';
import React from 'react';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { ApiInviteCollaboratorService } from '@/Service/Api/ApiInviteCollaboratorService';

const initState: CollaboratorInviteFormState = {
	formData: new InviteCollaboratorModel(),
	errorMessage: null,
};

export class CollaboratorInviteFormService extends BaseComponentService<CollaboratorInviteFormProps, CollaboratorInviteFormState>
{
	private apiInviteCollaboratorService: ApiInviteCollaboratorService = new ApiInviteCollaboratorService();

	constructor()
	{
		super({} as CollaboratorInviteFormProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{}

	/**
	 * Handle submit invitation collaborator
	 * @param event
	 * @param currentState
	 */
	async submitHandler(event: React.FormEvent<HTMLFormElement>, currentState: CollaboratorInviteFormState): Promise<void>
	{
		event.preventDefault();
		event.stopPropagation();

		this.props.modalContext?.isLoadingOverlay(true);

		// Validate the form before submission
		if (!this.validateForm(currentState)) {
			this.setState({ formData: currentState.formData });
			this.props.modalContext?.isLoadingOverlay(false);
			return;
		}

		try {
			const response = await this.createInvite(
				currentState.formData,
				this.props.authClientContext?.authClient.id!,
			);

			if (response.errorMessage) {
				this.props.modalContext?.isLoadingOverlay(false);

				if (response.errorMessage.includes('This Person is already Invited')) {
					this.setState({ errorMessage: 'Cette personne a déjà reçu une invitation.' });
				} else {
					this.setState({ errorMessage: `Erreur : ${response.errorMessage}` });
				}
				return;
			}

			// If everything is successful
			await this.props.authClientContext?.refreshClient(this.props.authClientContext?.authClient.id);
			this.props.flashMessageContext?.flashMessage('L\'invitation a été envoyée avec succès', '', 'success');
			this.props.modalContext?.isLoadingOverlay(false);
			this.props.modalContext?.isOpen(false);

		} catch (error) {
		 	console.error(`Error occurred on ${this.constructor.name} : SubmitHandlerInvitation`);
		}
	}

	/**
	 * Handle selected Role Option
	 * @param role
	 * @param currentState
	 */
	onSelectedOption(role: CollaboratorRoleEnum, currentState: CollaboratorInviteFormState): void
	{
		const roleKey = Object.keys(CollaboratorRoleEnum).find(
			key => CollaboratorRoleEnum[key as keyof typeof CollaboratorRoleEnum] === role
		);

		if (roleKey) {
			this.setState({
				formData: {
					...currentState.formData,
					collaboratorRole: roleKey as keyof typeof CollaboratorRoleEnum
				}
			});
		}
	}

	/**
	 * Handle valid form
	 * @param currentState
	 * @private
	 */
	private validateForm(currentState: CollaboratorInviteFormState): boolean
	{
		let errorMessage: string = '';
		console.log(currentState.formData);
		if(!currentState.formData.inviteEmail) {
			errorMessage = 'Merci de remplir un mail valide';
		} else if (!currentState.formData.collaboratorRole) {
			errorMessage = 'Merci de choisir un rôle';
		}

		if (errorMessage) {
			this.setState({ errorMessage });
			return false;
		}

		this.setState({ errorMessage: '' });
		return true;
	}

	/**
	 * Handle Create New Invitation Collaborator
	 * @param inviteCollaboratorFormData
	 * @param clientId
	 * @private
	 */
	private async createInvite(inviteCollaboratorFormData: any, clientId: number): Promise<any>
	{
		try {
			return await this.apiInviteCollaboratorService.send(inviteCollaboratorFormData, clientId);
		} catch (error: any) {
			console.log(error.errorMessage);
		}
	}
}