export enum CategoriesWithoutSiren
{
	LEASE_MANAGEMENT = '',
	END_MANAGEMENT = '',
	MATRIMONIAL_REGIME = 'Régime matrimonial',
	HOLOGRAPHIC_WILL = 'Testament olographe',
	DECLARATION_OF_UNSEIZABILITY = 'Déclaration d\'insaisissabilité',
	JUDICIAL_TRIBUNAL = 'Tribunal judiciaire',
	COMMERCIAL_COURT = 'Tribunal de commerce',
	BUSINESS_ASSETS = 'Fonds de commerce',
}