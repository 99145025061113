import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientDetailsProps, ClientDetailsState } from './ClientDetails.interface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';

const initState: ClientDetailsState = {
	formData: {} as ClientInterface,
	isLoading: true,
};

export class ClientDetailsService extends BaseComponentService<ClientDetailsProps, ClientDetailsState>
{
	private apiAdminClientService = new ApiAdminClientService();
	constructor()
	{
		super({} as ClientDetailsProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ formData: this.props.client },
			() => this.setState({ isLoading: false})
		);
	}

	async handleEdit(currentState: ClientDetailsState): Promise<void>
	{
		this.setState({ isLoading: true });
		try {

			const updateClient = await this.apiAdminClientService.edit(currentState.formData, currentState.formData.id);

			if(updateClient.errorMessage) {
				console.error('Something wrong with update Client', updateClient.errorMessage);
				return;
			}

			this.setState({
				formData: currentState.formData,
			});

			this.setState({ isLoading: false });

		} catch (error) {
			console.error('Something wrong with update Client', error);
			this.setState({ isLoading: false });
		}
	}
}