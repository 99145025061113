import React, { Component, ReactElement } from 'react';
import { ErrorBannerProps, ErrorBannerState } from './ErrorBanner.interface';
import { ErrorBannerStyles } from '@/Modules/App/Components/Error/ErrorBanner/ErrorBanner.styles';

class ErrorBanner extends Component<ErrorBannerProps, ErrorBannerState>
{
	//</editor-fold>

	render(): ReactElement
	{
		return (
			<div style={ ErrorBannerStyles.container }>
				{ this.props.errorMessage }
			</div>
		);
	}
}

export default ErrorBanner;