import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const LegalNoticeSendToDetailsStyles = {
	container: {} as CSSProperties,

	grid: {
		display: 'grid',
		gridTemplateColumns: '90px auto',
		gap: 5
	} as CSSProperties,

	label: {
		fontWeight: 500,
	} as CSSProperties,

	emails: {
		fontSize: 12,
		fontWeight: 500,
		color: colors.blueRibbon800,
		marginLeft: 8,
		marginBottom: 10,
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 5
	} as CSSProperties,

	email: {
		padding: '5px 8px',
		background: colors.blueRibbon50,
		color: colors.blueRibbon800,
		...borderStyles,
		borderColor: colors.blueRibbon200,
		borderRadius: '4px'
	} as CSSProperties
} as const;