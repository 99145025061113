import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeSendToDetailsProps, LegalNoticeSendToDetailsState } from './LegalNoticeSendToDetails.interface';

const initState: LegalNoticeSendToDetailsState = {};

export class LegalNoticeSendToDetailsService extends BaseComponentService<LegalNoticeSendToDetailsProps, LegalNoticeSendToDetailsState>
{
	constructor()
	{
		super({} as LegalNoticeSendToDetailsProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}
}