import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const LegalNoticeContentStyles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		padding: '30px 0',
		borderRadius: 8,
	} as CSSProperties,

	title: {
		textAlign: 'center',
		fontWeight: 600,
		overflowWrap: 'break-word'
	} as CSSProperties,

	separatorContainer: {
		display: 'flex',
		justifyContent: 'center',
	} as CSSProperties,

	separator: {
		height: 2,
		backgroundColor: colors.primary,
		width: '40%'
	} as CSSProperties,

	formatText: {
		fontSize: 12,
		textAlign: 'justify',
		hyphens: 'auto',
		msHyphens: 'auto',
		WebkitHyphens: 'auto',
		overflowWrap: 'break-word',
		wordSpacing: '-1px'
	} as CSSProperties,

	signature: {
		textAlign: 'right',
	} as CSSProperties,

} as const;