import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { colors, hexToRgba } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles, boxShadows } from '@/Modules/App/Style/Variables/Variables.styles';

export const ModalStyles = {
	backdrop: (isOpen: boolean) => ({
		position: 'absolute',
		zIndex: 1500,
		height: '100vh',
		width: '100%',
		backgroundColor: hexToRgba(colors.gray400, 0.4),
		backdropFilter: 'blur(2px)',
		top: 0,
		left: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: isOpen ? 1 : 0,
		transform: isOpen ? 'scale(1)' : 'scale(0.95)',
		transition: 'opacity 270ms ease, transform 270ms ease',
	}) as CSSProperties,

	container: (isOpen: boolean) =>({
		position: 'relative',
		minHeight: '150px',
		minWidth: '500px',
		maxWidth: '1200px',
		backgroundColor: 'white',
		borderRadius: 10,
		border: borderStyles.border,
		...boxShadows.main,
		opacity: isOpen ? 1 : 0,
		transform: isOpen ? 'scale(1)' : 'scale(0.95)',
		transition: 'opacity 270ms ease, transform 270ms ease',
	}) as CSSProperties,

	loading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: 'rgba(255, 255, 255, 0.7)',
		color: CssVariableEnum['--color-grey-900'],
		borderRadius: 20,
		zIndex: 1510
	} as CSSProperties,

	header: (title: string) => ({
		width: '100%',
		padding: '10px 20px 5px 20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: title ? 'space-between' : 'flex-end',
	}) as CSSProperties,

	overlay: {
		position: 'absolute',
		zIndex: 200,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: hexToRgba(colors.white, 0.4),
		borderRadius: 10,
	} as CSSProperties,

	headerContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center'
	} as CSSProperties,

	bottomActions: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '0px 20px 20px 20px'
	},

	padding: {
		padding: 20,
	} as CSSProperties,
} as const;