import React, { Component, ReactElement } from 'react';
import { LegalNoticeSendToDetailsProps, LegalNoticeSendToDetailsState } from './LegalNoticeSendToDetails.interface';
import { LegalNoticeSendToDetailsService } from './LegalNoticeSendToDetails.service';
import {
	LegalNoticeSendToDetailsStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeSendToDetails/LegalNoticeSendToDetails.styles';

class LegalNoticeSendToDetails extends Component<LegalNoticeSendToDetailsProps, LegalNoticeSendToDetailsState>
{
	private legalNoticeSendToDetailsService: LegalNoticeSendToDetailsService = new LegalNoticeSendToDetailsService();

	constructor(props: LegalNoticeSendToDetailsProps)
	{
		super(props);

		// Config service
		this.legalNoticeSendToDetailsService.setProps(this.props);
		this.legalNoticeSendToDetailsService.subscribeState(this);

		// State
		this.state = this.legalNoticeSendToDetailsService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeSendToDetailsService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeSendToDetailsService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<div style={ { width: '100%', } }>
					{ this.lineDetailsSendTo('Facture : ', this.props.legalNotice.sendTo.invoice) }
					{ this.lineDetailsSendTo('Attestation : ', this.props.legalNotice.sendTo.certificate) }
					{ this.lineDetailsSendTo('Justificatif : ', this.props.legalNotice.sendTo.receipt) }
					{ this.lineDetailsSendTo('Avoir : ', this.props.legalNotice.sendTo.credit) }
				</div>
			</>
		);
	}

	private lineDetailsSendTo(label: string, emails: string[] | null): ReactElement
	{
		const styles = LegalNoticeSendToDetailsStyles;

		return (
			<div key={ label } style={ styles.grid }>
				<div style={ styles.label }>
					{ label }
				</div>
				<div style={ styles.emails }>
					{ emails && emails.map((email: string, index: number) =>
						{
							return <span style={ styles.email } key={ index }>
									{ email }
								</span>;
						}
					) }
				</div>
			</div>
		);
	}
}

export default LegalNoticeSendToDetails;