import React, { Component, ReactElement } from 'react';
import { SidebarMenuProps } from '@/Modules/App/Components/Menu/SidebarMenu/SidebarMenu.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { SidebarMenuStyles } from './SidebarMenu.styles';
import NavigationMenu from '@/Modules/App/Components/Menu/Navigation/NavigationMenu';
import AppConfigAdmin from '@/Modules/Config/Admin/AppConfigAdmin';
import MenuItem from '@/Modules/App/Components/Menu/MenuItem';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { UserService } from '@/Modules/App/Services/User/User.service';

class SidebarMenu extends Component<SidebarMenuProps, any>
{
	render(): ReactElement
	{
		return (
			<div style={{ ...SidebarMenuStyles.container, display: 'flex', flexDirection: 'column', height: '100%' }}>
				{/* Navigation Menu */}
				<NavigationMenu />

				{/* Spacer */}
				<div style={{ flexGrow: 1 }}></div>

				{/* Footer Menu */}
				<div style={{ marginBottom: 15 }}>
					{/* HJE logo */}
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img style={{ width: 220, marginBottom: 20 }} src="/img/hje+ht.jpg" alt="hje-logo"/>
					</div>
					{/*	Config menu */ }
					{ UserService.isSuperAdmin() &&
						<>
							<MenuItem
								key={ 0 }
								label={ 'Paramètres' }
								type={ 'menuItem' }
								urlPath={ '' }
								iconName={ 'LuSettings' }
								authorization={ UserRoleEnum.ROLE_SUPER_ADMIN }
								onClick={(event: any) => {
									event.preventDefault();
									event.stopPropagation();

									this.props.modalContext?.isOpen(true);
									this.props.modalContext?.content(
										'Paramètres',
										<>
											<div style={{ width: '800px' }}>
												{ this.props.authCompanyContext?.authCompany &&
													<AppConfigAdmin
														company={ this.props.authCompanyContext?.authCompany }
														onEdit={ () => {
															this.props.modalContext?.isLoadingFull(true);
															setTimeout(async () =>
															{
																this.props.flashMessageContext?.flashMessage(
																	'Changement de fournisseur',
																	'Le Fournisseur a bien été changé avec succès ! '
																)
																this.props.modalContext?.isLoadingFull(false);
																this.props.modalContext?.isOpen(false);
																this.props.authCompanyContext?.refreshCompany(this.props.authCompanyContext?.authCompany.id, true);
																this.props.navigation('/admin');
															}, 1000);
														}}
													/>
												}
											</div>
										</>
									);
								}}
							/>
						</>
					}
				</div>
			</div>
		);
	}
}

export default withGlobalContext(SidebarMenu);