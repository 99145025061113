import { CSSProperties } from 'react';

export const LegalNoticePriceDetailsStyles = {
	container: {} as CSSProperties,
	amountDue: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 34,
		fontWeight: 500,
		gap: 50
	} as CSSProperties,
} as const;