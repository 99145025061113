import React, { Component, ReactElement } from 'react';
import { ClientProps, ClientState } from './Client.interface';
import { ClientService } from './Client.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import { ContentStyles } from '@/Modules/App/Components/Content/Content.styles';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import ClientDetails from '@/Modules/Client/Components/Blocks/ClientDetails/ClientDetails';
import CollaboratorsAdmin from '@/Modules/Collaborator/Admin/List/CollaboratorsAdmin';
import ClientsLogos from '@/Modules/Client/Components/ClientLogos/List/ClientsLogos';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { InviteCollaboratorInterface } from '@/Modules/Collaborator/Interface/InviteCollaboratorInterface';

class Client extends Component<ClientProps, ClientState>
{
	private clientService = new ClientService();

	constructor(props: ClientProps)
	{
		super(props);

		// Config service
		this.clientService.setProps(this.props);
		this.clientService.subscribeState(this);

		// State
		this.state = this.clientService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.clientService.init();
	}

	componentWillUnmount(): void
	{
		this.clientService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { isLoading, client } = this.state;

		if (isLoading) {
			return <LoaderFullPage/>;
		}

		return (
			<>
				<HeroSection
					title={ client.name }
					icon={ null }
				/>

				<div style={ ContentStyles.sidebar }>
					<div style={ { display: 'flex', flexDirection: 'column', gap: 50, paddingBottom: 100 } }>
						<SectionCollapsed title={ 'Information client' } highlightColor={ colors.gray100 }>
							<ClientDetails client={ client }/>
						</SectionCollapsed>

						<SectionCollapsed title={ 'Collaborateurs' } highlightColor={ colors.gray100 }>
							<CollaboratorsAdmin client={ client }/>
						</SectionCollapsed>

						<SectionCollapsed title={ 'Logos' } highlightColor={ colors.gray100 }>
							<ClientsLogos client={ client }/>
						</SectionCollapsed>
					</div>

					<div>
						<div>
							<Title style={ ContentStyles.sidebar_block_title } type={ 'h6' }>Détails</Title>
						</div>
						<div>
							<div style={ ContentStyles.sidebar_block_title }>Invitation en attente</div>
							<div>
								{ (client.invites.length > 0) ? (
									client.invites.map((collaboratorInvite: InviteCollaboratorInterface, index) =>
										<div key={ index }
												 style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
											<div>{ collaboratorInvite.inviteEmail }</div>
											<Tag value={ String(collaboratorInvite.state) } enumName={ 'InviteStateEnum' }/>
										</div>
									)
								) : (
									<div> Aucune invitation en attente </div>
								)
								}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(Client);