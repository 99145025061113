import React, { Component, ReactElement } from 'react';
import { LegalNoticeDetailsProps, LegalNoticeDetailsState } from './LegalNoticeDetails.interface';
import { LegalNoticeDetailsService } from './LegalNoticeDetails.service';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { ClientDetailsStyles } from '@/Modules/Client/Components/Blocks/ClientDetails/ClientDetails.styles';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import { dateFormat } from '@/Utils/DateUtils';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';

class LegalNoticeDetails extends Component<LegalNoticeDetailsProps, LegalNoticeDetailsState>
{
	private legalNoticeDetailsService = new LegalNoticeDetailsService();

	constructor(props: LegalNoticeDetailsProps)
	{
		super(props);

		// Config service
		this.legalNoticeDetailsService.setProps(this.props);
		this.legalNoticeDetailsService.subscribeState(this);

		// State
		this.state = this.legalNoticeDetailsService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeDetailsService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeDetailsService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{

		const { isLoading } = this.state;
		const { legalNotice } = this.props;

		if (isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<div style={ ClientDetailsStyles.container }>
					<div style={ ClientDetailsStyles.column }>
						<LineDetailsRender info={ 'Tag' } formData={ legalNotice.tag }
						/>
						<LineDetailsRender
							info={ 'Catégorie' }
						 	formData={ `${ legalNotice.formBuilderCategory.parent?.label } – ${ legalNotice.formBuilderCategory.label }` }
						/>
						<LineDetailsRender
							info={ 'Type de format' }
							formData={ NewspaperTypeEnum.findByValue(legalNotice.newspaper.type).label }
						/>
						<LineDetailsRender
							info={ 'Département' }
						 	formData={ `${ legalNotice.publishDepartment.name } (${ legalNotice.publishDepartment.code })` }
						/>
						<LineDetailsRender info={ 'Journal' } formData={ legalNotice.newspaper.name }/>
					</div>
					<div style={ ClientDetailsStyles.column }>
						<LineDetailsRender info={ 'Date de publication' } formData={ dateFormat(legalNotice.publishDate) }/>
						<LineDetailsRender info={ 'Référence' } formData={ legalNotice.reference }/>
						<LineDetailsRender
							info={ 'Auteur' }
							formData={ `${ legalNotice.author?.lastname } ${ legalNotice.author?.firstname }` }
						/>
						<LineDetailsRender
							info={ 'Collaborateur' }
							formData={ `${ legalNotice.collaborator.user.firstname } ${ legalNotice.collaborator.user.lastname }` }
						/>
						<LineDetailsRender
							info={ 'Date de création' }
							formData={ `${ dateFormat(legalNotice.createdAt) }` }
						/>
					</div>
				</div>
			</>
		);
	}
}

export default LegalNoticeDetails;