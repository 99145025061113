import React, { Component, ReactElement } from 'react';
import { CollaboratorInviteFormProps, CollaboratorInviteFormState } from './CollaboratorInviteForm.interface';
import { CollaboratorInviteFormService } from './CollaboratorInviteForm.service';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Select from '@/Modules/App/Components/Library/Select/Select';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import ErrorBanner from '@/Modules/App/Components/Error/ErrorBanner/ErrorBanner';

class CollaboratorInviteForm extends Component<CollaboratorInviteFormProps, CollaboratorInviteFormState>
{
	private collaboratorInviteFormService = new CollaboratorInviteFormService();

	constructor(props: CollaboratorInviteFormProps)
	{
		super(props);

		// Config service
		this.collaboratorInviteFormService.setProps(this.props);
		this.collaboratorInviteFormService.subscribeState(this);

		// State
		this.state = this.collaboratorInviteFormService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.collaboratorInviteFormService.init();
	}

	componentWillUnmount(): void
	{
		this.collaboratorInviteFormService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				{ this.state.errorMessage &&
          <ErrorBanner
            key={ this.state.errorMessage }
            errorMessage={ this.state.errorMessage }
          />
				}

				<div style={ { padding: 20, display: 'flex', flexDirection: 'column', gap: 10, width: 500 } }>
					<Input
						type={ 'text' }
						label={ 'Email collaborateur' }
						textHelp={ 'Veuillez saisir un email valide. Votre collaborateur recevra une invitation sur cette boîte email.'}
						name={ 'inviteEmail' }
						width={ '100%' }
						placeholder={ 'john.doe@unknow.com' }
						value={ this.state.formData.inviteEmail }
						onChange={
							event =>
								this.setState((prevState: { formData: any; }) => ({
									formData: {
										...prevState.formData,
										inviteEmail: event.target.value
									}
								}))
						}
						required={ true }
					/>

					<Select
						label={ 'Fonction du futur Collaborateur ' }
						textHelp={ 'Des droits d’accès seront accordés selon le rôle choisi' }
						listOptions={ CollaboratorRoleEnum.roleOptionsWithoutOwner }
						onSelect={ (event: any) => this.collaboratorInviteFormService.onSelectedOption(event, this.state) }
						displayKey={ 'label' }
						isRequired={ true }
					/>

					<Input
						type={ 'text' }
						width={ '100%' }
						label={ 'Message pour le futur Collaborateur' }
						name="additonnalData"
						placeholder="Merci de rejoindre mon cabinet"
						value={ this.state.formData.additionalInfo }
						onChange={
							event =>
								this.setState((prevState: { formData: any; }) => ({
									formData: {
										...prevState.formData,
										additionalInfo: event.target.value
									}
								}))
						}
						required={ false }
					/>
				</div>
				<div style={ ModalStyles.bottomActions }>
					<Button
						label={ 'Envoyer l’invitation'}
						variant={ 'primary'}
						onClick={ (event: any) => this.collaboratorInviteFormService.submitHandler(event, this.state) }
					/>
				</div>
			</>
		);
	}
}

export default withGlobalContext(CollaboratorInviteForm);