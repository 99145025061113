import { RefObject } from 'react';

class TogglableMenuService
{
	private isOpen: boolean;
	private menuPosition: { top?: string; bottom?: string };
	private menuRef: RefObject<HTMLDivElement>;

	constructor(menuRef: RefObject<HTMLDivElement>) {
		this.isOpen = false;
		this.menuPosition = { top: '100%' };
		this.menuRef = menuRef;
	}

	toggleMenu(): { isOpen: boolean; menuPosition: { top?: string; bottom?: string } } {
		this.isOpen = !this.isOpen;

		if (this.isOpen) {
			this.updateMenuPosition();
		}

		return { isOpen: this.isOpen, menuPosition: this.menuPosition };
	}

	updateMenuPosition(): void {
		if (this.menuRef.current) {
			const rect = this.menuRef.current.getBoundingClientRect();
			const spaceBelow = window.innerHeight - rect.bottom;
			const spaceAbove = rect.top;

			this.menuPosition = spaceBelow < 150 && spaceAbove > spaceBelow ? { bottom: '100%' } : { top: '100%' };
		}
	}

	handleClickOutside(event: MouseEvent, closeCallback: () => void): void {
		if (this.menuRef.current && !this.menuRef.current.contains(event.target as Node)) {
			closeCallback();
		}
	}
}

export default TogglableMenuService;
