import React, { Component, ReactElement } from 'react';
import { LayoutStyles } from '@/Modules/App/Components/Layout/Layout.styles';
import { LogoProps } from '@/Modules/App/Components/Logo/Logo.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class Logo extends Component<LogoProps, any>
{
	render(): ReactElement
	{
		return(
			<div
				style={ LayoutStyles.logo }
				onClick={ (): void => this.props.navigation( this.props.onClickPath! ) }
			>
				<img
					src={ '/img/logo-publisur-dark.svg' }
					alt="logo publisur"
				/>
			</div>
		);
	}
}

export default withGlobalContext(Logo);