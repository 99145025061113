import React, { Component, ReactElement } from 'react';
import {
	CollaboratorAdminSwitchOwnerProps,
	CollaboratorAdminSwitchOwnerState
} from './CollaboratorAdminSwitchOwner.interface';
import { CollaboratorAdminSwitchOwnerService } from './CollaboratorAdminSwitchOwner.service';
import Select from '@/Modules/App/Components/Library/Select/Select';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';

class CollaboratorAdminSwitchOwner extends Component<CollaboratorAdminSwitchOwnerProps, CollaboratorAdminSwitchOwnerState>
{
	private collaboratorAdminSwitchOwnerService = new CollaboratorAdminSwitchOwnerService();

	constructor(props: CollaboratorAdminSwitchOwnerProps)
	{
		super(props);

		// Config service
		this.collaboratorAdminSwitchOwnerService.setProps(this.props);
		this.collaboratorAdminSwitchOwnerService.subscribeState(this);

		// State
		this.state = this.collaboratorAdminSwitchOwnerService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.collaboratorAdminSwitchOwnerService.init();
	}

	componentWillUnmount(): void
	{
		this.collaboratorAdminSwitchOwnerService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<div style={ { padding: '25px' } }>
					<label style={ { marginBottom: '10px' } }>
						Choisissez un nouveau propriétaire.
						<br/>
						(Propriétaire actuel : <span
						style={ FontStyles.textHelp }>{ this.state.collaboratorOwner.email }</span>)
					</label>
					<Select
						listOptions={ this.collaboratorAdminSwitchOwnerService.buildListCollaborators() }
						label={ 'Collaborateurs' }
						onSelect={ (event: any) => this.collaboratorAdminSwitchOwnerService.handleSelected(event) }
						displayKey={ 'email' }
						isRequired={ true }
					/>
				</div>
				<div style={ ModalStyles.bottomActions }>
					<Button
						label={ 'Valider' }
						variant={ 'primary' }
						disabled={ Boolean(!this.state.newCollaboratorOwner) }
						iconName={ 'LuRepeat' }
						onClick={ () => this.collaboratorAdminSwitchOwnerService.handleSwitchCollaborator(this.state) }
					/>
				</div>
			</>
		);
	}
}

export default CollaboratorAdminSwitchOwner;