import React, { Component, ReactElement, RefObject } from 'react';
import {
	TogglableMenuProps,
	TogglableMenuState
} from '@/Modules/App/Components/Menu/ToggableMenu/TogglableMenu.interface';
import TogglableMenuService from '@/Modules/App/Components/Menu/ToggableMenu/TogglableMenu.service';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import { TogglableMenuStyles } from '@/Modules/App/Components/Menu/ToggableMenu/TogglableMenu.styles';


class TogglableMenu extends Component<TogglableMenuProps, TogglableMenuState>
{
	private menuRef: RefObject<HTMLDivElement>;
	private togglableMenuService: TogglableMenuService;

	constructor(props: TogglableMenuProps)
	{
		super(props);

		this.state = {
			isOpen: false,
			menuPosition: { top: '100%' },
		};

		this.menuRef = React.createRef();
		this.togglableMenuService = new TogglableMenuService(this.menuRef);

		this.toggleMenu = this.toggleMenu.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount(): void
	{
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount(): void
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	toggleMenu(): void
	{
		const { isOpen, menuPosition } = this.togglableMenuService.toggleMenu();
		this.setState({ isOpen, menuPosition });
	}

	handleClickOutside(event: MouseEvent): void
	{
		this.togglableMenuService.handleClickOutside(event, () =>
		{
			this.setState({ isOpen: false });
		});
	}

	render(): ReactElement
	{
		const { isOpen, menuPosition } = this.state;
		const { children, menuList } = this.props;
		const isMenuList = !!menuList;

		return (
			<div ref={ this.menuRef } style={ TogglableMenuStyles.container }>
				<div onClick={ this.toggleMenu }>
					{ children }
				</div>

				<div style={TogglableMenuStyles.menuContainer(isOpen, menuPosition)}>
					{isMenuList && menuList ? (
						<ul>
							{menuList.map((element, index) => (
								<Hovered key={index}>
									{({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
										<li
											ref={ref}
											style={{
												...TogglableMenuStyles.li,
												...(isHovered ? TogglableMenuStyles.hoveredItem : {}),
											}}
											onMouseEnter={onMouseEnter}
											onMouseLeave={onMouseLeave}
											onClick={element.onClick}
										>
											{element.label}
										</li>
									)}
								</Hovered>
							))}
						</ul>
					) : (
						<p>Menu Content Here</p>
					)}
				</div>
			</div>
		);
	}
}

export default TogglableMenu;
