import { CSSProperties } from 'react';

export const LegalNoticeHeadOfInvoiceStyles = {
	container: {

	} as CSSProperties,

	grid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		marginBottom: 30
	} as CSSProperties,

	column: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20
	} as CSSProperties,

	title: {
		fontWeight: 600
	} as CSSProperties
} as const;