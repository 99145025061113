import React, { ReactElement } from 'react';
import { AuthClientProviderState } from '@/Provider/Interface/AuthClient/AuthClientProviderState';
import { AuthClientContextType } from '@/Provider/Interface/AuthClient/AuthClientContextType';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { UserRoleEnumOld } from '@/Enum/UserRoleEnumOld';

export const AuthClientContext = React.createContext<AuthClientContextType|null>(null);

export class AuthClientProvider extends React.Component<any, AuthClientProviderState>
{
  clientService: ApiClientService|ApiAdminClientService;

  constructor(props: any) {
    super(props);

    this.state = {
      clients: [],
      authClient: null,
      isClientFetch: false
    };

    // Services
    if (LocalStorageService.getUser()) {
      this.clientService = (LocalStorageService.getUser().userRole === UserRoleEnumOld.ROLE_ADMIN)
        ? new ApiAdminClientService()
        : new ApiClientService()
      ;
    } else {
      this.clientService = new ApiClientService();
    }

    // Bind
    this.fetchClients = this.fetchClients.bind(this);
    this.refreshClient = this.refreshClient.bind(this);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  async loadInitialData(): Promise<any>
  {
    const storedClient = LocalStorageService.getAuthClient();
    if (storedClient && !this.state.isClientFetch) {
      try {
        const getAuthClient = await this.clientService.show(storedClient);
        this.setState({ authClient: getAuthClient, isClientFetch: true });
        await this.fetchClients();
      } catch (error) {
        console.error('[AuthClientProvider] - Error on load initial data:', error);
      }
    }
  }

  async fetchClients(): Promise<any>
  {
    try {
      const response = await this.clientService.list();
      this.setState({ clients: response });
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async refreshClient(clientId: number): Promise<any>
  {
    try {
      const getAuthClient = await this.clientService.show(clientId);
      this.setState({ authClient: getAuthClient });
      // Set local storage auth client id
      localStorage.setItem('authClientId', clientId.toString());
    } catch (error) {
      console.error('Error selecting client:', error);
    }
  }

  render(): ReactElement
  {
    return (
      <AuthClientContext.Provider value={{
        authClient: this.state.authClient,
        refreshClient: this.refreshClient,
        clients: this.state.clients,
        storedClient: this.loadInitialData,
        fetchClients: this.fetchClients
      }}>
        { this.props.children }
      </AuthClientContext.Provider>
    );
  }
}

