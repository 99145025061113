import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminLegalNoticeService extends BaseService
{
	legalNoticeUrl: string = `${ BaseService.API_URL }/v1/admin/legal-notices`;

	async list(params?: string): Promise<any>
	{
		try {
			const url = params ? this.legalNoticeUrl + '?' + params : this.legalNoticeUrl;
			const response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async specificList(term: string, params?: string): Promise<any>
	{
		try {
			const validateUrl: string = `${ this.legalNoticeUrl }/specificList/${ term }`;
			const url: string = params ? validateUrl + '?' + params : validateUrl;
			const response: Response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(legalNoticeId: number): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async create(legalNoticeData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				this.legalNoticeUrl,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async edit(legalNoticeId: number, legalNoticeData: any): Promise<any>
	{
		try {
			const response = await fetch(
				this.legalNoticeUrl + '/' + legalNoticeId,
				{
					method: HttpVerbEnum.PATCH,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async delete(legalNoticeId: number, nbCharacters: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildHeaders(),
					body: JSON.stringify({ nbCharacters: nbCharacters }),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async duplicate(legalNoticeId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async respondQuote(legalNoticeId: number, answerData: {
		respond: string,
		legalNotice: { nbCharacters: number }
	}): Promise<void>
	{
		try {
			const response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }/quote/respond`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(answerData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async sendBackEmail(legalNoticeId: number, sendBackEmailData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }/send-back-email`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(sendBackEmailData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}